import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'Không hiển thị người sáng tạo',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(Dịch vụ)#1 #2(Marketing cho)#2
  #3(nhà sáng tạo)#3 #4(toàn cầu)#4
  #5(nhấp vào đây để đặt hàng)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'Try "Marketing thời trang"',
  [InfluKeys.search.option2]: 'Try "Review trò chơi điện tử"',
  [InfluKeys.search.option3]: 'Try "Review Sản phẩm"',
  [InfluKeys.search.option4]: 'Try "Review thức ăn"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'Đăng ký làm người sáng tạo',

  // Mobile
  [InfluKeys.M.title]: 'Nền tảng tiếp thị<br/>người ảnh hưởng toàn cầu',
  [InfluKeys.M.search]: 'Tìm kiếm nhà sáng tạo nội dung',
  [InfluKeys.M.content_box.request_creators.title]: 'Yêu cầu người sáng tạo',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'Nhận danh sách người sáng tạo toàn cầu phù hợp cho chiến dịch của bạn.',

  // Card
  [InfluKeys.Card.my_card]: 'Thẻ của tôi',
  [InfluKeys.Card.view_package]: 'Xem gói dịch vụ',
  [InfluKeys.Card.creator_profile]: 'Hồ sơ của nhà sáng tạo nội dung',
  [InfluKeys.Card.view_packages]: 'Xem gói dịch vụ',
  [InfluKeys.Card.view_analytics]: 'Xem thống kê',
  [InfluKeys.Card.send_a_message]: 'Gửi Tin nhắn',
  [InfluKeys.Card.view_profile]: 'Xem Hồ sơ',

  [InfluKeys.Banner_Cards.tech1_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo công nghệ Mỹ</blue> có hơn 300K người theo dõi.',
  [InfluKeys.Banner_Cards.tech2_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo công nghệ Nhật Bản</blue> có hơn 200K người theo dõi.',
  [InfluKeys.Banner_Cards.tech3_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo công nghệ Thái Lan</blue> có hơn 1M người theo dõi.',
  [InfluKeys.Banner_Cards.tech4_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo công nghệ Tây Ban Nha</blue> có hơn 1M người theo dõi.',
  [InfluKeys.Banner_Cards.tech5_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo công nghệ Mỹ</blue> có hơn 500K người theo dõi.',
  [InfluKeys.Banner_Cards.gaming1_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo game Hàn Quốc</blue> có hơn 1M người theo dõi.',
  [InfluKeys.Banner_Cards.gaming2_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo game Mỹ</blue> có hơn 600K người theo dõi.',
  [InfluKeys.Banner_Cards.gaming3_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo game Việt Nam</blue> có hơn 1M người theo dõi.',
  [InfluKeys.Banner_Cards.gaming4_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo game Tây Ban Nha</blue> có hơn 2M người theo dõi.',
  [InfluKeys.Banner_Cards.gaming5_description]:
    'Hợp tác ngay với một <blue>nhà sáng tạo game Nhật Bản</blue> có hơn 800K người theo dõi.',
  [InfluKeys.Banner_Cards.beauty1_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo sắc đẹp Vương Quốc Anh</pink> có hơn 500K người theo dõi.',
  [InfluKeys.Banner_Cards.beauty2_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo sắc đẹp Nhật Bản</pink> có hơn 300K người theo dõi.',
  [InfluKeys.Banner_Cards.beauty3_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo sắc đẹp Mỹ</pink> có hơn 300K người theo dõi.',
  [InfluKeys.Banner_Cards.beauty4_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo sắc đẹp Hàn Quốc</pink> có hơn 400K người theo dõi.',
  [InfluKeys.Banner_Cards.food1_description]:
    'Hợp tác ngay với một <red>nhà sáng tạo ẩm thực Mỹ</red> có hơn 1M người theo dõi.',
  [InfluKeys.Banner_Cards.food2_description]:
    'Hợp tác ngay với một <red>nhà sáng tạo ẩm thực EU</red> có hơn 1M người theo dõi.',
  [InfluKeys.Banner_Cards.food3_description]:
    'Hợp tác ngay với một <red>nhà sáng tạo ẩm thực Nhật Bản</red> có hơn 500K người theo dõi.',
  [InfluKeys.Banner_Cards.food4_description]:
    'Hợp tác ngay với một <red>nhà sáng tạo ẩm thực Hàn Quốc</red> có hơn 200K người theo dõi.',
  [InfluKeys.Banner_Cards.food5_description]:
    'Hợp tác ngay với một <red>nhà sáng tạo ẩm thực Mỹ</red> có hơn 800K người theo dõi.',
  [InfluKeys.Banner_Cards.fashion1_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo thời trang Mỹ</pink> có hơn 400K người theo dõi.',
  [InfluKeys.Banner_Cards.fashion2_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo thời trang EU</pink> có hơn 500K người theo dõi.',
  [InfluKeys.Banner_Cards.fashion3_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo thời trang Hàn Quốc</pink> có hơn 300K người theo dõi.',
  [InfluKeys.Banner_Cards.fashion4_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo thời trang Nhật Bản</pink> có hơn 300K người theo dõi.',
  [InfluKeys.Banner_Cards.fashion5_description]:
    'Hợp tác ngay với một <pink>nhà sáng tạo nhảy & Kpop</pink> có hơn 600K người theo dõi.',
};
