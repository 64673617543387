import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'ไม่มีครีเอเตอร์ที่จะแสดง',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(สั่งซื้อ)#1#2(บริการทางการตลาด)#2
  #3(ครีเอเตอร์)#3#4(ทั่วโลก)#4
  #5(แค่คลิกเดียว)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'ลองค้นหา "การตลาดแฟชั่น"',
  [InfluKeys.search.option2]: 'ลองค้นหา "รีวิววิดีโอเกม"',
  [InfluKeys.search.option3]: 'ลองค้นหา "รีวิวสินค้า"',
  [InfluKeys.search.option4]: 'ลองค้นหา "รีวิวอาหาร"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'เข้าร่วมเป็นครีเอเตอร์',

  // Mobile
  [InfluKeys.M.title]: 'แพลตฟอร์มการตลาด<br/>ด้วยอินฟลูเอนเซอร์ทั่วโลก',
  [InfluKeys.M.search]: 'ค้นหาครีเอเตอร์',
  [InfluKeys.M.content_box.request_creators.title]: 'ขอผู้สร้างเนื้อหา',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'รับรายชื่อผู้สร้างเนื้อหาจากทั่วโลกที่ปรับแต่งให้เหมาะกับแคมเปญของคุณ.',

  // Card
  [InfluKeys.Card.my_card]: 'บัตรของฉัน',
  [InfluKeys.Card.view_package]: 'ดูแพ็กเกจ',
  [InfluKeys.Card.creator_profile]: 'โปรไฟล์ครีเอเตอร์',
  [InfluKeys.Card.view_packages]: 'ดูแพ็กเกจ',
  [InfluKeys.Card.view_analytics]: 'มุมมองการวิเคราะห์',
  [InfluKeys.Card.send_a_message]: 'ส่งข้อความ',
  [InfluKeys.Card.view_profile]: 'ดูโปรไฟล์',

  [InfluKeys.Banner_Cards.tech1_description]:
    'ร่วมมือกับ “300K+ <blue>ผู้สร้างเทคโนโลยีจากสหรัฐฯ</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.tech2_description]:
    'ร่วมมือกับ “200K+ <blue>ผู้สร้างเทคโนโลยีจากญี่ปุ่น</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.tech3_description]:
    'ร่วมมือกับ “1M+ <blue>ผู้สร้างเทคโนโลยีจากไทย</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.tech4_description]:
    'ร่วมมือกับ “1M+ <blue>ผู้สร้างเทคโนโลยีจากสเปน</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.tech5_description]:
    'ร่วมมือกับ “500K+ <blue>ผู้สร้างเทคโนโลยีจากสหรัฐฯ</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.gaming1_description]:
    'ร่วมมือกับ “1M+ <blue>ผู้สร้างเกมจากเกาหลี</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.gaming2_description]:
    'ร่วมมือกับ “600K+ <blue>ผู้สร้างเกมจากสหรัฐฯ</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.gaming3_description]:
    'ร่วมมือกับ “1M+ <blue>ผู้สร้างเกมจากเวียดนาม</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.gaming4_description]:
    'ร่วมมือกับ “2M+ <blue>ผู้สร้างเกมจากสเปน</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.gaming5_description]:
    'ร่วมมือกับ “800K+ <blue>ผู้สร้างเกมจากญี่ปุ่น</blue>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.beauty1_description]:
    'ร่วมมือกับ “500K+ <pink>ผู้สร้างความงามจากสหราชอาณาจักร</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.beauty2_description]:
    'ร่วมมือกับ “300K+ <pink>ผู้สร้างความงามจากญี่ปุ่น</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.beauty3_description]:
    'ร่วมมือกับ “300K+ <pink>ผู้สร้างความงามจากสหรัฐฯ</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.beauty4_description]:
    'ร่วมมือกับ “400K+ <pink>ผู้สร้างความงามจากเกาหลี</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.food1_description]:
    'ร่วมมือกับ “1M+ <red>ผู้สร้างอาหารจากสหรัฐฯ</red>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.food2_description]:
    'ร่วมมือกับ “1M+ <red>ผู้สร้างอาหารจากอียู</red>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.food3_description]:
    'ร่วมมือกับ “500K+ <red>ผู้สร้างอาหารจากญี่ปุ่น</red>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.food4_description]:
    'ร่วมมือกับ “200K+ <red>ผู้สร้างอาหารจากเกาหลี</red>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.food5_description]:
    'ร่วมมือกับ “800K+ <red>ผู้สร้างอาหารจากสหรัฐฯ</red>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.fashion1_description]:
    'ร่วมมือกับ “400K+ <pink>ผู้สร้างแฟชั่นจากสหรัฐฯ</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.fashion2_description]:
    'ร่วมมือกับ “500K+ <pink>ผู้สร้างแฟชั่นจากอียู</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.fashion3_description]:
    'ร่วมมือกับ “300K+ <pink>ผู้สร้างแฟชั่นจากเกาหลี</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.fashion4_description]:
    'ร่วมมือกับ “300K+ <pink>ผู้สร้างแฟชั่นจากญี่ปุ่น</pink>” ตอนนี้เลย',
  [InfluKeys.Banner_Cards.fashion5_description]:
    'ร่วมมือกับ “600K+ <pink>ผู้สร้างการเต้นรำ & Kpop</pink>” ตอนนี้เลย',
};
