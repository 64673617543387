import FooterKeys from '../translationKeys/FooterKeys';

export const FooterTranslations = {
  [FooterKeys.publisher]: 'Global Publisher : Platform2Publish OÜ',
  [FooterKeys.registry_code]: 'Registry Code : 169015842',
  [FooterKeys.global_address]: 'Address : Sakala 7-2, 10141 Tallinn, Estonia',
  [FooterKeys.development_customer_support]: 'Development : Intaverse Inc.',
  [FooterKeys.business_number]: 'Business Number : 279-88-02709',
  [FooterKeys.tel]: 'Tel : +82-10-7649-5412',
  [FooterKeys.email]: 'Email : info@megaevolution.io',
  [FooterKeys.ceo]: '',
  [FooterKeys.korean_address]: '',
  [FooterKeys.company]: 'MegaEvolution Company Info',
  [FooterKeys.description]: '',
  [FooterKeys.buisiness_inquiry]: 'Business Inquiry',
};
