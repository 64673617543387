import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: '크리에이터가 없습니다',

  // Title
  [InfluKeys.title]: `#1(글로벌)#1 #2(크리에이터)#2
  #3(마케팅 서비스를)#3
  #4(원클릭)#4 #5(주문하세요!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: '"패션 마케팅"을 검색해보세요',
  [InfluKeys.search.option2]: '"비디오 게임 리뷰"를 검색해보세요',
  [InfluKeys.search.option3]: '"제품 리뷰"를 검색해보세요',
  [InfluKeys.search.option4]: '"음식 리뷰"를 검색해보세요',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: '크리에이터로 등록하기',

  // Mobile
  [InfluKeys.M.title]: `글로벌 인플루언서<br/>마케팅 플랫폼`,
  [InfluKeys.M.search]: '크리에이터 검색',
  [InfluKeys.M.content_box.request_creators.title]: '크리에이터 요청하기',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    '진행하시는 캠페인에 딱 맞는 전세계 크리에이터 리스트를 받아보세요.',

  // Card
  [InfluKeys.Card.my_card]: '나의 카드',
  [InfluKeys.Card.view_package]: '패키지 보기',
  [InfluKeys.Card.creator_profile]: '크리에이터 프로필',
  [InfluKeys.Card.view_packages]: '패키지 보기',
  [InfluKeys.Card.view_analytics]: '데이터 분석 보기',
  [InfluKeys.Card.send_a_message]: '메세지 보내기',
  [InfluKeys.Card.view_profile]: '프로필 보기',

  [InfluKeys.Banner_Cards.tech1_description]:
    '지금 300K+ <blue>미국의 테크 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.tech2_description]:
    '지금 200K+ <blue>일본의 테크 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.tech3_description]:
    '지금 1M+ <blue>태국의 테크 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.tech4_description]:
    '지금 1M+ <blue>스페인의 테크 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.tech5_description]:
    '지금 500K+ <blue>미국의 테크 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.gaming1_description]:
    '지금 1M+ <blue>한국의 게임 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.gaming2_description]:
    '지금 600K+ <blue>미국의 게임 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.gaming3_description]:
    '지금 1M+ <blue>베트남의 게임 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.gaming4_description]:
    '지금 2M+ <blue>스페인의 게임 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.gaming5_description]:
    '지금 800K+ <blue>일본의 게임 크리에이터</blue>와 협업하세요.',
  [InfluKeys.Banner_Cards.beauty1_description]:
    '지금 500K+ <pink>영국의 뷰티 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.beauty2_description]:
    '지금 300K+ <pink>일본의 뷰티 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.beauty3_description]:
    '지금 300K+ <pink>미국의 뷰티 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.beauty4_description]:
    '지금 400K+ <pink>한국의 뷰티 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.food1_description]:
    '지금 1M+ <red>미국의 음식 크리에이터</red>와 협업하세요.',
  [InfluKeys.Banner_Cards.food2_description]:
    '지금 1M+ <red>EU의 음식 크리에이터</red>와 협업하세요.',
  [InfluKeys.Banner_Cards.food3_description]:
    '지금 500K+ <red>일본의 음식 크리에이터</red>와 협업하세요.',
  [InfluKeys.Banner_Cards.food4_description]:
    '지금 200K+ <red>한국의 음식 크리에이터</red>와 협업하세요.',
  [InfluKeys.Banner_Cards.food5_description]:
    '지금 800K+ <red>미국의 음식 크리에이터</red>와 협업하세요.',
  [InfluKeys.Banner_Cards.fashion1_description]:
    '지금 400K+ <pink>미국의 패션 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.fashion2_description]:
    '지금 500K+ <pink>EU의 패션 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.fashion3_description]:
    '지금 300K+ <pink>한국의 패션 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.fashion4_description]:
    '지금 300K+ <pink>일본의 패션 크리에이터</pink>와 협업하세요.',
  [InfluKeys.Banner_Cards.fashion5_description]:
    '지금 600K+ <pink>댄스 & Kpop 크리에이터</pink>와 협업하세요.',
};
