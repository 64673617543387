import PrivacyPolicyKeys from '../translationKeys/PrivacyPolicyPageKeys';

export const PrivacyPolicyTranslations = {
  [PrivacyPolicyKeys.title]: '개인정보 처리방침',

  [PrivacyPolicyKeys.top_text]: `MegaEvolution에서는 개인정보 보호를 위해 최선을 다하며, 수집 및 사용되는 데이터에 대해 명확하고 투명하게 안내하고자 합니다. 본 개인정보 처리방침은 당사 웹사이트인 www.megaevolution.io를 방문하거나 제공하는 개인정보를 처리하는 방법을 설명합니다.`,

  // Disclosure regarding Google APIs
  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.title]:
    'Google API에 대한 공개',

  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description
    .l1]: `Google API로부터 수신된 정보의 MegaEvolution 사용 및 다른 앱으로의 이전은`,
  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description
    .l2]: `Google API 서비스 사용자 데이터 정책`,
  [PrivacyPolicyKeys.Disclosure_Regarding_Google_APIs.Description
    .l3]: `을 준수하며, 이에는 제한된 사용 요구사항도 포함됩니다.`,

  // Who we are and how to contact us
  [PrivacyPolicyKeys.Who_We_Are_And_How_To_Contact_Us.title]:
    '회사 소개 및 연락처',

  [PrivacyPolicyKeys.Who_We_Are_And_How_To_Contact_Us.description]:
    '우리는 Intaverse Inc.로서 한국 서울 강남구 테헤란로 82길 15, 우편번호 06178에 위치한 MegaEvolution으로 거래하고 있습니다. 당사의 플랫폼이나 서비스를 직접 이용하는 경우, 한국의 개인정보보호법("PIPA"), 캘리포니아 소비자 개인정보 보호법("CCPA") 및 캘리포니아 개인정보 권리 및 집행법("CPRA")의 후속 개정안에 따라 데이터 컨트롤러로서 역할을 수행합니다. 또한 EU의 일반 개인정보 보호 규정("GDPR")도 적용됩니다. 개인정보 처리에 관한 질문이나 개인정보 보호에 대한 일반적인 문의 사항이 있으시면 info@megaevolution.io로 연락하실 수 있습니다.',

  // How do we use your Personal Information?
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.title]:
    '개인정보를 어떻게 사용하나요?',

  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.d1]:
    '당사는 플랫폼을 통해 받은 모든 개인정보를 아래 자세히 설명된 목적에 따라 처리합니다. 이는 상기 언급된 각 법적 규정의 범위 내에서 또는 귀하의 동의를 받은 경우에만 이루어집니다. 특히, 다음의 경우에만 개인정보를 처리하고 수집합니다:',
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l1]:
    '귀하가 동의한 경우',
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l2]:
    '데이터가 계약 이행/계약 전 조치를 위해 필요한 경우',
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l3]:
    '데이터가 법적 의무 이행을 위해 필요한 경우',
  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.l4]:
    '데이터가 당사의 합법적 이익을 보호하기 위해 필요한 경우 (귀하의 이익이 우선되지 않는 한)',

  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.d2]:
    '이를 위해 당사는 플랫폼을 유지하고 사용하는 데 절대적으로 필요한 데이터만 수집하고 처리합니다. 개인정보는 직접적으로 제공하거나 (예: 자발적으로 제공하는 경우) 자동적으로 수집될 수 있습니다. (예: 플랫폼을 설치하고 사용할 때)',

  [PrivacyPolicyKeys.How_Do_We_Use_Your_Personal_Information.Description.d3]:
    '우리는 귀하의 개인정보를 해당 처리 목적을 달성하기 위해 필요한 기간 동안 또는 법적 보존 기간(특히 상업 및 세법)이 존재하는 동안만 처리하고 저장합니다. 목적을 달성하거나 보존 기간이 만료되면 해당 데이터는 정기적으로 삭제됩니다.',

  // Personal Information you provide to us directly
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.title]:
    '직접적으로 제공하는 개인정보',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.A.title]:
    'a) 저희에게 연락하고 서비스를 이용하는 경우',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.A
    .description]:
    '저희에게 연락하거나 일반 상담이나 기타 제공되는 서비스 중 하나를 예약하는 경우, 귀하로부터 받은 데이터를 처리하여 귀하의 요청 및 계약 처리를 목적으로 합니다. 이에는 성명, 이메일 주소 및 적용되는 경우 전화번호와 같은 다른 정보가 포함될 수 있습니다. 데이터 처리의 법적 근거는 계약 이행 및/또는 계약 이전 의무의 이행 및/또는 귀하의 요청 처리에 대한 우선적인 합법적 이익입니다. 제공하신 데이터 중 일부는 "민감한" 또는 특수 범주의 데이터로 간주될 수 있으며, 이러한 데이터를 제공함으로써 해당 데이터의 처리에 동의한 것으로 간주됩니다. 처음부터 해당 정보를 제공하지 않도록 요청드립니다. 해당 정보가 서비스와 관련이 있는 경우 다른 데이터와 함께 처리합니다.',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.B.title]:
    'b) 금융 데이터',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.B
    .description]:
    '구매 또는 서비스 판매를 진행하는 경우, 귀하의 결제는 제3자 결제 서비스 제공업체를 통해 처리되며, 결제 데이터는 해당 결제 시스템을 통해 처리됩니다. 결제 시스템 제공의 법적 근거는 서비스 이용을 위한 사용자 계약의 체결 및 이행입니다.',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.C.title]:
    'c) 계정 생성',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.C
    .description]:
    '관련 계정을 자발적으로 생성할 수 있으며, 이를 통해 향후 구매를 위해 귀하의 데이터를 저장할 수 있습니다. "내 계정"에서 계정을 생성할 때 제공하는 데이터는 철회 가능하게 저장됩니다. 계정 설정에서 귀하의 계정을 포함한 모든 추가 데이터를 언제든지 삭제할 수 있습니다. 데이터 처리의 법적 근거는 귀하의 동의입니다. 또는 편리한 로그인 및 가입을 통해 사용할 수 있는 지갑 제공업체 (MetaMask, Coinbase Wallet, WalletConnect 등) 또는 Google을 통해 가입할 수 있습니다. 지갑 제공업체 및 Google 로그인 및 가입을 사용하는 경우 계정에 연결된 기본 정보 (예: 이름, 이메일 주소 및 프로필 사진)를 제공해야 합니다. 허용된 경우, 귀하의 사용자 이름과 비밀번호가 자동으로 생성되어 필요한 사용자 데이터의 나머지 부분을 채우게 됩니다. 지갑 제공업체 및 Google의 연결 기능을 통해 등록하는 경우 해당 이용 약관에 동의하고 귀하의 해당 프로필에서 일부 데이터의 전송에 동의하는 것으로 간주됩니다.',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.D.title]:
    'd) 프로필',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.D
    .description]:
    '등록된 사용자로서 몇 번의 클릭과 세부 정보만으로 사용자 프로필을 생성할 수 있는 기회가 주어집니다. 이 옵션을 사용하는 경우 제공한 관련 프로필 데이터가 프로필로 전송됩니다. 물론 프로필의 설정을 통해 언제든지 정보를 변경할 수 있습니다. 프로필을 생성할 때 개인정보를 제출할 수 있습니다. 프로필에 대한 정보는 선택 사항입니다. 추가 정보를 프로필에 제공할 필요는 없지만, 프로필 정보는 서비스를 보다 효과적으로 활용하는 데 도움이 됩니다. 프로필에 민감한 정보를 포함하고 해당 민감한 정보를 공개할 지 여부는 귀하의 선택에 달려 있습니다. 공개되기를 원하지 않는 개인정보를 프로필에 게시하거나 추가하지 마십시오. 귀하의 개인정보 처리의 법적 근거는 서비스 이용을 위한 사용자 계약의 설정 및 이행입니다. 귀하의 사용자 계정을 삭제할 때까지 데이터를 저장합니다. 법적 보존 기간을 준수해야 하는 경우 사용자 계정 삭제 후에도 저장이 이루어집니다.',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.E.title]:
    'e) 리뷰',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.E
    .description]:
    '리뷰 작성 시 특정 정보를 표시하고 일부 세부 정보를 공유하며 다른 사람과 상호 작용하고 지식과 통찰력을 교환하며 관련 콘텐츠를 게시하고 보거나 이를 수정할 수 있습니다. 콘텐츠와 데이터는 공개적으로 볼 수 있습니다. 리뷰에 대한 정보에 대해 선택권이 있습니다. 리뷰에 추가 정보를 제공할 필요는 없습니다. 리뷰에 민감한 정보를 포함하고 해당 민감한 정보를 공개할 지 여부는 귀하의 선택에 달려 있습니다. 게시하거나 리뷰에 개인정보를 추가하지 마십시오. 해당 정보가 공개되기를 원하지 않는 경우 개인정보를 게시하거나 추가하지 마십시오.',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.F.title]:
    'f) MegaEvolution 소셜 네트워크',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Directly.F
    .description]:
    '저희는 현재 X, Discord, YouTube, Instagram, Telegram, kakao를 포함하여 합법적인 이익을 바탕으로 소셜 네트워크에 참여하고 있습니다. 소셜 네트워크 서비스 플랫폼을 통해 저희에게 연락하는 경우 채팅 기록은 저희나 귀하가 삭제할 수 없음을 알려드립니다. 또한, GDPR에 따라 해당 소셜 네트워크 서비스 플랫폼 및 저희는 데이터 처리에 대해 공동 책임을 가지며, 이를 위해 공동 책임 처리자 협약을 체결합니다. 공동 책임 처리자 협약은 매우 법적이고 길지만, 간단히 말하면 공동 책임을 가진 당사자가 적용되는 개인정보 보호 법률에서 발생하는 의무를 어떻게 이행할 것인지를 명확히하는 것입니다. 해당 소셜 네트워크 서비스 플랫폼을 사용하는 법적 근거는 저희의 합법적 이익, 귀하의 동의 또는 (전)계약 관계의 경우 계약 서비스의 개시일, 그렇다면 있는 경우입니다.',

  // Personal Information you provide to us automatically
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically
    .title]: '자동으로 제공하는 개인정보',

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .title]: 'a) 쿠키',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .Description
    .l1]: `서비스 향상을 위해 플랫폼에서는 사용자의 이용 동작에 관한 정보를 수집하는 쿠키를 사용합니다. 쿠키는 웹 브라우저가 웹사이트를 방문할 때 하드 드라이브에 저장하는 파일입니다. 쿠키는 특정 상황에서 직접적으로(예: 이메일 주소로) 또는 간접적으로(예: 쿠키의 고유 식별 코드, IP 주소 또는 장치의 식별 코드로) 개인을 식별할 수 있습니다. 우리는 인증된 사용자(메가에볼루션에 가입한 사용자)의 데이터만 저장합니다. 저장되는 데이터에는 방문한 페이지, 방문 일시 및 기타 추적 정보가 포함될 수 있습니다. 자세한 내용은 `,
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .Description.l2]: `쿠키 정책`,
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.A
    .Description.l3]: `을 참조해주시기 바랍니다.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.B
    .title]: 'b) 소셜 네트워크 연결',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.B
    .description]: `MegaEvolution은 사용자가 Google, YouTube, Facebook, Instagram, X, Tiktok, Discord 등과 같은 제3자 소셜 네트워크 계정을 우리 시스템에 연결하는 경우 해당 사용자에 대해 추가 통계 및 정보를 수집합니다. 우리가 수집하는 정보에는 소셜 네트워크 계정에 특정한 통계 데이터(채널 통계, 콘텐츠 통계, 구독자 수, 관객 및 구독자 데이터, 채널 정보 등)와 사용자 이름, 이메일 주소, 프로필 사진, 배너 사진 등의 프로필 데이터가 포함될 수 있습니다. 연결된 소셜 네트워크 계정의 수집된 데이터는 우리의 웹사이트를 이용하는 다른 사용자들에게 당신의 MegaEvolution 프로필과 서비스를 소개하는 데 사용됩니다.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.C
    .title]: 'c) 접근 데이터 및 로그 파일',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.C
    .description]: `우리는 플랫폼에 대한 모든 접근에 대한 데이터도 수집합니다. 접근 데이터에는 접근한 웹사이트의 이름, 파일, 접근한 날짜와 시간, 전송된 데이터 양, 성공적인 접근 알림, 브라우저 유형 및 버전, 사용자의 운영 체제, 참조 URL(이전에 방문한 페이지), IP 주소 및 요청한 제공자가 포함됩니다. 로그 파일 정보는 보안 목적(예: 오용 또는 사기 조사)으로 최대 7일 동안 보관되고 그 후 삭제됩니다. 증거 보존을 위해 추가로 저장되어야 하는 데이터는 해당 사건이 최종적으로 해결될 때까지 삭제에서 제외됩니다. 데이터 처리의 법적 근거는 매력적인 플랫폼을 제공하기 위한 우리의 정당한 이익입니다.`,

  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.D
    .title]: 'd) 호스팅',
  [PrivacyPolicyKeys.Personal_Information_You_Provide_To_Us_Automatically.D
    .description]: `플랫폼을 제공하기 위해 우리는 Amazon Web Services (AWS)의 서비스를 사용합니다. AWS는 아래에 언급된 데이터와 우리 플랫폼 운영과 관련하여 처리되어야 하는 모든 데이터를 우리를 대신하여 처리합니다. 데이터 처리의 법적 근거는 우리 플랫폼을 제공하기 위한 우리의 정당한 이익입니다.`,

  // 3rd Party Services & Links
  [PrivacyPolicyKeys._3rd_Party_Services_Links.title]: '제 3자 서비스 및 링크',

  [PrivacyPolicyKeys._3rd_Party_Services_Links.C
    .description]: `우리는 아래에 나열된 제 3자의 서비스 및 개인정보 처리 방침을 통합합니다.`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.A.title]: 'a) 구글 애널리틱스',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.A
    .description]: `저희는 구글 애널리틱스를 사용하여 플랫폼 사용에 대한 평가를 수행하고, 활동에 대한 보고서를 작성하며, 사용자 경험을 향상시키기 위한 기타 서비스를 제공하기 위해 사용합니다. 구글 애널리틱스를 사용할 때, 웹사이트 방문자의 상호작용이 주로 기록되고 쿠키의 도움으로 체계적으로 평가됩니다. 이는 정당한 이익을 나타내지만, 데이터의 미국 전송 및 분석 기능으로 인해 귀하의 동의가 필요합니다.`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.B.title]: 'b) 구글 태그 관리자',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.B
    .description]: `우리는 또한 구글 태그 관리자를 사용합니다. 이 서비스는 인터페이스를 통해 웹사이트 태그를 관리할 수 있게 합니다. 구글 태그 관리자는 태그만 구현합니다. 쿠키는 설정되지 않으며 개인정보도 수집되지 않습니다. 구글 태그 관리자는 데이터를 수집하는 다른 태그를 트리거합니다. 구글 태그 관리자는 이러한 데이터에 액세스하지 않습니다. 도메인 또는 쿠키 수준에서 비활성화가 수행된 경우, 구글 태그 관리자로 구현된 모든 추적 태그에 대해 해당 비활성화가 유지됩니다.`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.title]:
    'c) 통합된 제 3자의 개인정보 처리 방침',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d1]: `다음은 모든 사용자에게 적용됩니다.`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d2]: `구글 개인정보 처리 방침`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d3]: `YouTube API 서비스 약관`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d4]: `YouTube 서비스 약관`,
  [PrivacyPolicyKeys._3rd_Party_Services_Links.C.Description
    .d5]: `구글 API 서비스 사용자 데이터 정책`,

  [PrivacyPolicyKeys._3rd_Party_Services_Links.D.title]:
    'd) 다른 웹사이트 링크',
  [PrivacyPolicyKeys._3rd_Party_Services_Links.D
    .description]: `우리는 비즈니스 파트너 웹사이트를 포함한 다른 제 3자 웹사이트로의 링크를 제공합니다. 모든 제 3자 웹사이트와 링크에 대해서, MegaEvolution은 개인정보 처리 방침이나 관행 또는 포함된 콘텐츠에 대해 책임지지 않습니다. 제 3자 링크는 편의를 위해 제공되며, MegaEvolution은 이를 접속하거나 사용함으로써 발생하는 위험에 대해서 책임지지 않습니다.`,

  // 제3자에 대한 정보 공개
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.title]:
    '제3자에 대한 정보 공개',

  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l1]: `당사는 다음과 같은 경우를 제외하고는 개인정보를 제3자에게 공개하거나 배포하지 않습니다.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l2]: `· 당사의 서비스 제공을 위해 필요한 경우,`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l3]: `· 정보 공개에 동의한 경우,`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l4]: `· 관련 법령에서 정보 공개가 허용된 경우,`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l5]: `그러나 위에서 언급한 법적 규정의 범위 내에서 개인정보의 처리를 외부 서비스 제공자에게 전체적으로 또는 일부분으로 외주할 수 있습니다. 외부 서비스 제공자는 기술적 운영 및 플랫폼 지원(위에서 언급한 대로), 데이터 관리, 서비스 제공 및 수행, 마케팅, 보고 의무의 이행과 구현 등의 업무를 지원합니다.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l6]: `우리가 의뢰한 서비스 제공자는 우리의 지시에 따라 데이터를 처리하며, 위에서 언급한 관련 법규에 따라 데이터 보호에 대한 책임은 여전히 우리에게 있습니다. 우리는 의뢰한 서비스 제공자가 신중하게 선정되었고, 엄격한 계약 규정, 기술적 및 조직적 조치, 그리고 우리의 추가적인 검토를 준수하도록 항상 주의를 기울입니다.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l7]: `또한, 비즈니스 관련 개인정보를 비즈니스 제휴사와 함께 제3자에게 제공, 전송, 전자적으로 송부할 수 있습니다. 이는 금전적 보상이 아닌 다른 가치로 이루어집니다.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l8]: `법원 명령에 따라 또는 국내외 범죄 수사나 법적 절차를 지원하거나 이를 충족하기 위해 필요한 경우에도 개인정보를 제3자에게 공개할 수 있습니다.`,
  [PrivacyPolicyKeys.Disclosure_To_Third_Parties.Description
    .l9]: `제3국에 수취자에게 데이터를 이전하는 것은 의도되지 않았으며, 이러한 경우 데이터 보호 수준을 보장하기 위해 표준 계약 조항이 포함된 처리 계약에 의해 규제됩니다.`,

  // Marketing
  [PrivacyPolicyKeys.Marketing.title]: '마케팅',
  [PrivacyPolicyKeys.Marketing
    .description]: `마케팅 및 광고 목적으로 개인정보를 처리하기 위해 동의를 제공한 경우, 동의한 의사소통 채널을 통해 이와 관련하여 당사는 연락을 취할 권한이 있습니다. 동의를 얻기 위해 여러 가지 방법으로 동의를 제공할 수 있으며, 동의를 표시하는 상자를 선택하거나 때로는 상호 작용이나 계약 관계에서 추정할 수도 있습니다. 동의가 추정된 경우, 상호 작용이나 계약 관계에 기초하여 마케팅 커뮤니케이션을 받을 합리적인 기대가 있다는 것을 기반으로 합니다. 저희의 마케팅은 일반적으로 이메일 형식을 취하며, 다른 전통적이지 않거나 신흥적인 채널을 포함할 수도 있습니다. 이러한 연락 방식은 당사나 계약된 서비스 제공자에 의해 관리됩니다. 당사나 당사를 대신하여 직접 발송되는 모든 마케팅은 구독 해지 또는 거부할 수 있는 수단이 포함됩니다.`,

  // Direct Marketing Disclosures
  [PrivacyPolicyKeys.Direct_Marketing_Disclosures.title]: '직접 마케팅 공개',
  [PrivacyPolicyKeys.Direct_Marketing_Disclosures
    .description]: `캘리포니아의 "Shine the Light" 법률(민법 제1798.83조)에 따르면, 기업은 캘리포니아 고객이 제3자에게 개인정보를 제공하는 것과 관련된 사업의 관행에 대한 문의에 응해야 합니다. 당사의 개인정보 수집 및 공개에 관한 문의를 위해 제공된 연락처를 사용하여 요청할 수 있습니다.`,

  // Automated decision-making
  [PrivacyPolicyKeys.Automated_Decision_Making.title]: '자동화된 의사 결정',
  [PrivacyPolicyKeys.Automated_Decision_Making.description]:
    '메가에볼루션에서는 자동 결정 또는 프로파일링을 통한 자동 결정은 이루어지지 않습니다.',

  // Data of Children
  [PrivacyPolicyKeys.Data_Of_Children.title]: '어린이의 데이터',
  [PrivacyPolicyKeys.Data_Of_Children.description]:
    '우리의 서비스는 14세 이상을 대상으로 합니다. 만 14세 미만의 미성년자로부터 의도적으로 개인 정보를 수집, 사용 또는 공개하지 않습니다. 법적 보호자의 동의를 받기 위해 직접 오프라인 연락을 통해 얻지 않는 한입니다.',

  // Do Not Sell
  [PrivacyPolicyKeys.Do_Not_Sell.title]: '판매하지 않음',
  [PrivacyPolicyKeys.Do_Not_Sell.description]:
    '개인 정보를 제3자에게 판매하지 않습니다.',

  // Data Security
  [PrivacyPolicyKeys.Data_Security.title]: '데이터 보안',
  [PrivacyPolicyKeys.Data_Security.description]:
    '개인 정보의 보호, 보안 및 무결성을 보장하기 위해 우리는 기술적 및 조직적인 조치를 시행하고 있습니다. 개인 정보에 대한 접근은 필요한 사람들에게만 제한되며 기밀 유지 요건을 준수하기 위해 교육을 받은 사람들만 해당 정보에 접근할 수 있습니다. 적절한 기구는 데이터 수집 및 전송 중에 SSL 암호화를 포함하고 있으므로 다른 사람이 데이터를 읽을 수 없습니다. 해당 데이터는 수집된 목적에만 사용되며 개인 정보가 변경되거나 훼손되지 않고 무단으로 액세스되지 않도록 최선을 다하겠습니다. 그러나 개인 정보를 포함하는 데이터베이스나 데이터 세트가 우발적으로 또는 불법적인 침입을 통해 침해될 수 있습니다. 데이터 침해 사실을 인지한 경우, 개인 정보가 침해당할 수 있는 모든 영향을 받은 개인에게 통지하고 해당 데이터 침해의 결과로 인한 피해를 해결하기 위해 취해지는 조치에 대한 설명이 동반될 것입니다. 이러한 통지는 침해가 발견된 후 최대한 신속하게 제공될 것입니다.',

  // Data Protection Legislation에 따른 권리
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.title]:
    '데이터 보호 법에 따른 권리',

  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l1]: `PIPA에 따라 다음과 같은 권리를 가지고 있습니다.`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l2]: `· 알림 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l3]: `· 액세스 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l4]: `· 수정 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l5]: `· 삭제 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l6]: `· 이의 제기/수신 거부 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l7]: `· 동의 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l8]: `· 구제 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l9]: `CCPA 및 CPRA 개정안에 따라 다음 권리를 행사할 수 있습니다.`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l10]: `· 알림/액세스 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l11]: `· 삭제 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l12]: `· 판매 거부 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l13]: `· 비차별 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l14]: `· 수정 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l15]: `· 민감한 개인 정보의 사용 및 공개 제한 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l16]: `GDPR에 따라 다음과 같은 권리를 행사할 수 있습니다.`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l17]: `· 정보 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l18]: `· 수정 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l19]: `· 처리에 이의 제기하는 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l20]: `· 삭제 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l21]: `· 정보 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l22]: `· 데이터 이동성 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l23]: `· 이의 제기 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l24]: `· 동의 철회 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l25]: `· 감독 기관에 불만 제기 권리`,
  [PrivacyPolicyKeys.Your_Rights_Under_Data_Protection_Legislation.Description
    .l26]: `· 자동 처리에 의한 결정에 완전히 종속되지 않는 권리.`,

  // Updating your information
  [PrivacyPolicyKeys.Updating_Your_Information.title]: '정보 업데이트',
  [PrivacyPolicyKeys.Updating_Your_Information
    .description]: `당사가 보유한 귀하의 정보가 부정확하거나 더 이상 사용할 수 없으며 교정, 삭제 또는 처리에 이의를 제기하고자 하는 경우 info@megaevolution.io로 연락하여 요청하십시오.`,

  // Access Request and updating your Personal Information
  [PrivacyPolicyKeys.Access_Request_And_Updating_Your_Personal_Information
    .title]: '정보 열람 요청 및 개인정보 업데이트',
  [PrivacyPolicyKeys.Access_Request_And_Updating_Your_Personal_Information
    .description]: `귀하가 데이터 주체 액세스 요청을 하고자 하는 경우, info@megaevolution.io로 서면으로 알려주십시오. 액세스 및 수정에 관한 요청에 대해 가능한 빨리 응답할 것입니다. 요청을 받은 후 30일 이내에 응답할 수 없는 경우, 30일 이내에 요청에 응답할 수 있는 시간을 알려드릴 것입니다. 귀하의 요청에 대해 개인 정보를 제공하거나 귀하의 요청에 대한 수정을 제공할 수 없는 경우, 일반적으로 그 이유를 알려드릴 것입니다(해당 법률 규정에 따라 그러지 않아야 할 경우를 제외하고).`,

  // Controls For Do-Not-Track Features
  [PrivacyPolicyKeys.Controls_For_Do_Not_Track_Features.title]:
    'Do-Not-Track 기능을 위한 제어',
  [PrivacyPolicyKeys.Controls_For_Do_Not_Track_Features
    .description]: `대부분의 웹 브라우저와 일부 모바일 운영 체제 및 모바일 애플리케이션에는 온라인 브라우징 활동에 대한 데이터 수집 및 모니터링을 원치 않는다는 개인 정보 설정인 Do-Not-Track (DNT) 기능이 포함되어 있습니다. 현재 DNT 신호를 인식하고 구현하는 통합 기술 표준이 확정되지 않았습니다. 따라서, 저희는 현재 DNT 브라우저 신호나 온라인 추적을 거부하는 다른 자동화된 메커니즘에 응답하지 않습니다. 앞으로 따라야 할 온라인 추적 표준이 채택되면, 본 문서의 개정판에서 해당 관행에 대해 알려드리겠습니다.`,

  // Does this policy change?
  [PrivacyPolicyKeys.Does_This_Policy_Change.title]:
    '이 정책은 변경될 수 있나요?',
  [PrivacyPolicyKeys.Does_This_Policy_Change
    .description]: `저희는 필요에 따라 개인 정보 보호 정책을 업데이트할 수 있습니다. 이는 법률의 변경이나 비즈니스 관행 및 쿠키 사용 방식의 변경 등 여러 가지 이유로 이루어질 수 있습니다. 저희 개인 정보 보호 정책의 변경 사항을 확인하기 위해 정기적으로 여기를 확인하시기를 권장합니다.`,

  // Who should I contact for more information?
  [PrivacyPolicyKeys.Who_Should_I_Contact_For_More_Information.title]:
    '추가 정보를 얻으려면 누구에게 연락해야 하나요?',
  [PrivacyPolicyKeys.Who_Should_I_Contact_For_More_Information
    .description]: `개인 정보 보호 정책에 대한 질문이나 의견이 있거나 적용 가능한 법률에 따라 권리를 행사하고자 하는 경우, 저희에게 연락해주시기 바랍니다.`,
};
