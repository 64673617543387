import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: 'Continue',
  [GeneralKeys.submit]: 'Submit',
  [GeneralKeys.cancel]: 'Cancel',
  [GeneralKeys.close]: 'Close',
  [GeneralKeys.return]: 'Return',
  [GeneralKeys.account]: 'Account',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: 'Password',
  [GeneralKeys.update]: 'Update',
  [GeneralKeys.coming_soon]: 'Preparing',
  [GeneralKeys.all]: 'All',
  [GeneralKeys.filter]: 'Filters',
  [GeneralKeys.contents]: 'Contents',
  [GeneralKeys.myspace]: 'My Space',
  [GeneralKeys.myproposal]: 'My Proposals',
  [GeneralKeys.proposals]: 'Proposals',
  [GeneralKeys.billing]: 'Billing',
  [GeneralKeys.settings]: 'Settings',
  [GeneralKeys.profile]: 'Profile',
  [GeneralKeys.my_orders]: 'Orders',
  [GeneralKeys.orders]: 'Orders',
  [GeneralKeys.message]: 'Message',
  [GeneralKeys.support]: 'Support',
  [GeneralKeys.help_center]: 'Help Center',
  [GeneralKeys.creator_faq]: 'Creator FAQ',
  [GeneralKeys.company_faq]: 'Company · Brand FAQ',
  [GeneralKeys.myCart]: 'My Cart',
  [GeneralKeys.platform]: 'Platform',
  [GeneralKeys.sort_by]: 'Sort By',
  [GeneralKeys.country]: 'Country',
  [GeneralKeys.categories]: 'Categories',
  [GeneralKeys.price]: 'Price',
  [GeneralKeys.subscribers]: 'Subscribers',
  [GeneralKeys.avg_views]: 'Avg. Views',
  [GeneralKeys.audience_gender]: 'Audience Gender',
  [GeneralKeys.audience_age]: 'Audience Age',
  [GeneralKeys.youtube]: 'YouTube',
  [GeneralKeys.twitter]: 'X (Twitter)',
  [GeneralKeys.instagram]: 'Instagram',
  [GeneralKeys.tiktok]: 'TikTok',
  [GeneralKeys.delivery]: 'Delivery',
  [GeneralKeys.type_length]: 'Type/Length',
  [GeneralKeys.day_singular]: `{{number}} Day`,
  [GeneralKeys.day_plural]: `{{number}} Days`,
  [GeneralKeys.loading]: 'Loading',
  [GeneralKeys.company]: 'Company',
  [GeneralKeys.bio]: 'Bio',
  [GeneralKeys.subs]: 'Subs.',
  [GeneralKeys.followers]: 'Followers',
  [GeneralKeys.videos]: 'Videos',
  [GeneralKeys.x_posts]: 'X Posts',
  [GeneralKeys.posts_reels]: 'Posts & Reels',
  [GeneralKeys.tiktok_videos]: 'TikTok Videos',
  [GeneralKeys.since]: 'Since {{date}}',
  [GeneralKeys.countries]: 'Countries',
  [GeneralKeys.reviews]: 'Reviews',
  [GeneralKeys.demographics]: 'Demographics',
  [GeneralKeys.others]: 'Others',
  [GeneralKeys.check_plan]: 'Check plan',
  [GeneralKeys.login]: 'Login',
  [GeneralKeys.login_upper_case]: 'LOGIN',
  [GeneralKeys.total]: 'Total',
  [GeneralKeys.likes]: 'Likes',
  [GeneralKeys.comments]: 'Comments',
  [GeneralKeys.reposts]: 'X Reposts',
  [GeneralKeys.search]: 'Search',
  [GeneralKeys.contact]: 'Contact',
  [GeneralKeys.price_sign]: '$ --',
  [GeneralKeys.unlocking]: 'Unlocking',
  [GeneralKeys.click_to_unlock]: 'Click to unlock',
  [GeneralKeys.add_to_cart]: 'Add to cart',
  [GeneralKeys.show_less]: 'Show Less',
  [GeneralKeys.show_more]: 'Show More',
  [GeneralKeys.go_back]: 'Go back',
  [GeneralKeys.load_more]: 'Load more',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Coinbase Wallet',
  [GeneralKeys.send]: 'Send',
  [GeneralKeys.email]: 'Email',
  [GeneralKeys.or_upper_case]: 'OR',
  [GeneralKeys.agree]: 'Agree',
  [GeneralKeys.client]: 'Brand',
  [GeneralKeys.influencer]: 'Creator',
  [GeneralKeys.marketing]: 'Marketing',
  [GeneralKeys.creator_marketing]: 'Creator Marketing',
  [GeneralKeys.partnerships]: 'Points · Partners',
  [GeneralKeys.evo_tokens]: 'EVO Points',
  [GeneralKeys.information]: 'Information',
  [GeneralKeys.website_credits]: 'Website Credits',
  [GeneralKeys.home]: 'Home',
  [GeneralKeys.about]: 'About',
  [GeneralKeys.pricing]: 'Pricing',
  [GeneralKeys.proposal]: 'Proposal',
  [GeneralKeys.logout]: 'Logout',
  [GeneralKeys.view_more]: 'View More',
  [GeneralKeys.view_less]: 'View Less',
  [GeneralKeys.title]: 'Title',
  [GeneralKeys.preview]: 'Preview',
  [GeneralKeys.upload_a_file]: 'Upload a file',
  [GeneralKeys.description]: 'Description',
  [GeneralKeys.type]: 'Type',
  [GeneralKeys.category]: 'Category',
  [GeneralKeys.select]: 'Select',
  [GeneralKeys.unlimited_revisions]: 'Unlimited Revisions',
  [GeneralKeys.unlimited]: 'Unlimited',
  [GeneralKeys.revision]: '{{number}} Revision',
  [GeneralKeys.revisions]: '{{number}} Revisions',
  [GeneralKeys.number_revision]: 'Revisions',
  [GeneralKeys.optional]: 'Optional',
  [GeneralKeys.username]: 'Username',
  [GeneralKeys.social_network]: 'Social Network',
  [GeneralKeys.unlink]: 'Unlink',
  [GeneralKeys.unlink_platform]: 'Unlink {{platform}}',
  [GeneralKeys.language]: 'Language',
  [GeneralKeys.languages]: 'Languages',
  [GeneralKeys.package]: 'Package',
  [GeneralKeys.save]: 'Save',
  [GeneralKeys.creator]: 'Creator',
  [GeneralKeys.basic]: 'Basic',
  [GeneralKeys.standard]: 'basic',
  [GeneralKeys.premium]: 'standard',
  [GeneralKeys.per_day]: '/day',
  [GeneralKeys.per_month]: '/mo',
  [GeneralKeys.summary]: 'Summary',
  [GeneralKeys.general]: 'General',
  [GeneralKeys.register]: 'Register',
  [GeneralKeys.click]: 'Click',
  [GeneralKeys.connect]: 'Connect',
  [GeneralKeys.reconnect]: 'Reconnect',
  [GeneralKeys.connecting]: 'Connecting',
  [GeneralKeys.notifications]: 'Notifications',
  [GeneralKeys.messages]: 'Messages',
  [GeneralKeys.reminders]: 'Reminders',
  [GeneralKeys.newsletters]: 'Newsletters',
  [GeneralKeys.packages]: 'Packages',
  [GeneralKeys.real_time]: 'Real-Time',
  [GeneralKeys.unsubscribe]: 'Unsubscribe',
  [GeneralKeys.referral_code]: 'Referral Code',
  [GeneralKeys.total_invites]: 'Total {{number}} invites',
  [GeneralKeys.submitted]: 'Submitted',
  [GeneralKeys.here]: 'here',
  [GeneralKeys.confirm]: 'Confirm',
  [GeneralKeys.deactivate]: 'Deactivate',
  [GeneralKeys.delete]: 'Delete',
  [GeneralKeys.wallet]: 'Wallet',
  [GeneralKeys.yes]: 'Yes',
  [GeneralKeys.privacy_policy]: 'Privacy Policy',
  [GeneralKeys.payment_policy]: 'Payment Policy',
  [GeneralKeys.reactivate]: 'Reactivate',
  [GeneralKeys.analytics]: 'Analytics',
  [GeneralKeys.user_info]: 'User Info',
  [GeneralKeys.enterprise]: 'premium',
  [GeneralKeys.details]: 'Details',
  [GeneralKeys.claim]: 'Claim',
  [GeneralKeys.in_progress]: 'In Progress',
  [GeneralKeys.free]: 'Free',
  [GeneralKeys.report]: 'Report',
  [GeneralKeys.link_copied]: 'Link copied',
  [GeneralKeys.contents_lowercase]: 'contents',
  [GeneralKeys.channel_lowercase]: 'channel',
  [GeneralKeys.audience_lowercase]: 'audience',
  [GeneralKeys.my_url]: 'My URL',
  [GeneralKeys.username_lowercase]: 'username',
  [GeneralKeys.shorts]: 'Shorts',
  [GeneralKeys.x_post]: 'X Post',
  [GeneralKeys.repost]: 'X Repost',
  [GeneralKeys.website]: 'My Website',
  [GeneralKeys.banner]: 'banner',
  [GeneralKeys.profile_lowercase]: 'profile',
  [GeneralKeys.congratulations]: 'Contratulations!',
  [GeneralKeys.skip]: 'skip',
  [GeneralKeys.back]: 'Back',
  [GeneralKeys.shares]: 'Shares',
  [GeneralKeys.posts]: 'Posts',
  [GeneralKeys.client_company]: 'Brand · Client',
  [GeneralKeys.contents_in]: '{{categories}} contents in {{countries}}.',
  [GeneralKeys.videos_lowercase]: 'videos',
  [GeneralKeys.tweets_lowercase]: 'X posts',
  [GeneralKeys.posts_lowercase]: 'posts',
  [GeneralKeys.rating]: 'Rating',
  [GeneralKeys.users]: 'Users',
  [GeneralKeys.browse_files]: 'Browse files',
  [GeneralKeys.upload_failed]: 'Upload failed',
  [GeneralKeys.downloading]: 'Downloading...',
  [GeneralKeys.package_summary]: 'Package Summary',
  [GeneralKeys.contents_plural]: '{{number}} Contents',
  [GeneralKeys.contents_singular]: '{{number}} Content',
  [GeneralKeys.creator_brand_help_center]: 'Help Center & FAQ',
  [GeneralKeys.main]: 'Main',
  [GeneralKeys.date]: 'Date',
  [GeneralKeys.service]: 'Service',
  [GeneralKeys.order_id]: 'Order ID',
  [GeneralKeys.transaction]: 'Transaction',
  [GeneralKeys.pdf]: 'PDF',
  [GeneralKeys.no_result_to_display]: 'No results to display',
  [GeneralKeys.oldest]: 'Oldest',
  [GeneralKeys.rows_10]: '10 rows',
  [GeneralKeys.rows_50]: '50 rows',
  [GeneralKeys.rows_100]: '100 rows',
  [GeneralKeys.rows_500]: '500 rows',
  [GeneralKeys.rows_1000]: '1000 rows',
  [GeneralKeys.refresh]: 'Refresh',

  // Category
  [GeneralKeys.Category.digital]: 'Digital',
  [GeneralKeys.Category.entertainment]: 'Entertainment',
  [GeneralKeys.Category.society]: 'Society',
  [GeneralKeys.Category.life_style]: 'Life Style',

  [GeneralKeys.Category.tech_science]: 'Tech · Science',
  [GeneralKeys.Category.video_games]: 'Video Games',
  [GeneralKeys.Category.metaverse]: 'Metaverse',
  [GeneralKeys.Category.crypto_nft]: 'App · Tutorials',
  [GeneralKeys.Category.finance]: 'Finance',
  [GeneralKeys.Category.movies]: 'Movies',
  [GeneralKeys.Category.anime_cartoon]: 'Anime · Cartoon',
  [GeneralKeys.Category.music]: 'Music',
  [GeneralKeys.Category.dance]: 'Dance',
  [GeneralKeys.Category.sports]: 'Sports',
  [GeneralKeys.Category.comedy]: 'Comedy',
  [GeneralKeys.Category.politics_society]: 'Politics · Society',
  [GeneralKeys.Category.people_blogs]: 'People · Blogs',
  [GeneralKeys.Category.fashion]: 'Fashion',
  [GeneralKeys.Category.beauty]: 'Beauty',
  [GeneralKeys.Category.food_cooking]: 'Food · Cooking',
  [GeneralKeys.Category.youth_infant]: 'Youth · Infant',
  [GeneralKeys.Category.animals_pets]: 'Animals · Pets',
  [GeneralKeys.Category.travel_event]: 'Travel · Culture',
  [GeneralKeys.Category.auto_vehicles]: 'Auto · Vehicles',
  [GeneralKeys.Category.unboxing]: 'Unboxing',
  [GeneralKeys.Category.education]: 'Education',
  [GeneralKeys.Category.art_crafts]: 'Art · Crafts',
  [GeneralKeys.Category.diy_home]: 'DIY · Home',
  [GeneralKeys.Category.virtual_vtuber]: 'Virtual · VTuber',
  [GeneralKeys.Category.ai_automation]: 'AI · Automation',
  [GeneralKeys.Category.gym_fitness]: 'Gym · Fitness',

  [GeneralKeys.Category.tech]: 'Tech',
  [GeneralKeys.Category.gaming]: 'Gaming',
  [GeneralKeys.Category.humor]: 'Humor',
  [GeneralKeys.Category.anime]: 'Anime',
  [GeneralKeys.Category.travel]: 'Travel',
  [GeneralKeys.Category.culture]: 'Culture',
  [GeneralKeys.Category.unboxing2]: 'Unboxing',
  [GeneralKeys.Category.beauty2]: 'Beauty',
  [GeneralKeys.Category.fashion2]: 'Fashion',
  [GeneralKeys.Category.sports2]: 'Sports',
  [GeneralKeys.Category.food2]: 'Food',
  [GeneralKeys.Category.cooking2]: 'Cooking',
  [GeneralKeys.Category.diy2]: 'DIY',
  [GeneralKeys.Category.home2]: 'Home',
  [GeneralKeys.Category.dance2]: 'Dance',
  [GeneralKeys.Category.music2]: 'Music',

  // Country
  [GeneralKeys.Country.are]: 'United Arab Emirates',
  [GeneralKeys.Country.arg]: 'Argentina',
  [GeneralKeys.Country.aus]: 'Australia',
  [GeneralKeys.Country.aut]: 'Austria',
  [GeneralKeys.Country.bel]: 'Belgium',
  [GeneralKeys.Country.bra]: 'Brazil',
  [GeneralKeys.Country.can]: 'Canada',
  [GeneralKeys.Country.che]: 'Switzerland',
  [GeneralKeys.Country.chn]: 'China',
  [GeneralKeys.Country.deu]: 'Germany',
  [GeneralKeys.Country.dnk]: 'Denmark',
  [GeneralKeys.Country.eeu]: 'Europe',
  [GeneralKeys.Country.esp]: 'Spain',
  [GeneralKeys.Country.fin]: 'Finland',
  [GeneralKeys.Country.fra]: 'France',
  [GeneralKeys.Country.gbr]: 'United Kingdom',
  [GeneralKeys.Country.glb]: 'Global',
  [GeneralKeys.Country.hkg]: 'Hong Kong',
  [GeneralKeys.Country.idn]: 'Indonesia',
  [GeneralKeys.Country.ind]: 'India',
  [GeneralKeys.Country.irn]: 'Iran',
  [GeneralKeys.Country.isr]: 'Israel',
  [GeneralKeys.Country.ita]: 'Italy',
  [GeneralKeys.Country.jpn]: 'Japan',
  [GeneralKeys.Country.kor]: 'South Korea',
  [GeneralKeys.Country.mex]: 'Mexico',
  [GeneralKeys.Country.mys]: 'Malaysia',
  [GeneralKeys.Country.nld]: 'Netherlands',
  [GeneralKeys.Country.nor]: 'Norway',
  [GeneralKeys.Country.nzl]: 'New Zealand',
  [GeneralKeys.Country.pak]: 'Pakistan',
  [GeneralKeys.Country.phl]: 'Philippines',
  [GeneralKeys.Country.pol]: 'Poland',
  [GeneralKeys.Country.prt]: 'Portugal',
  [GeneralKeys.Country.sau]: 'Saudi Arabia',
  [GeneralKeys.Country.sgp]: 'Singapore',
  [GeneralKeys.Country.swe]: 'Sweden',
  [GeneralKeys.Country.tha]: 'Thailand',
  [GeneralKeys.Country.tur]: 'Turkey',
  [GeneralKeys.Country.twn]: 'Taiwan',
  [GeneralKeys.Country.usa]: 'United States',
  [GeneralKeys.Country.vnm]: 'Vietnam',

  // Client
  [GeneralKeys.Country.abw]: 'Aruba',
  [GeneralKeys.Country.afg]: 'Afghanistan',
  [GeneralKeys.Country.ago]: 'Angola',
  [GeneralKeys.Country.aia]: 'Anguilla',
  [GeneralKeys.Country.ala]: 'Åland Islands',
  [GeneralKeys.Country.alb]: 'Albania',
  [GeneralKeys.Country.and]: 'Andorra',
  [GeneralKeys.Country.arm]: 'Armenia',
  [GeneralKeys.Country.asm]: 'American Samoa',
  [GeneralKeys.Country.ata]: 'Antarctica',
  [GeneralKeys.Country.atf]: 'French Southern Territories',
  [GeneralKeys.Country.atg]: 'Antigua and Barbuda',
  [GeneralKeys.Country.aze]: 'Azerbaijan',
  [GeneralKeys.Country.bdi]: 'Burundi',
  [GeneralKeys.Country.ben]: 'Benin',
  [GeneralKeys.Country.bes]: 'Bonaire, Sint Eustatius and Saba',
  [GeneralKeys.Country.bfa]: 'Burkina Faso',
  [GeneralKeys.Country.bgd]: 'Bangladesh',
  [GeneralKeys.Country.bgr]: 'Bulgaria',
  [GeneralKeys.Country.bhr]: 'Bahrain',
  [GeneralKeys.Country.bhs]: 'Bahamas',
  [GeneralKeys.Country.bih]: 'Bosnia and Herzegovina',
  [GeneralKeys.Country.blm]: 'Saint Barthélemy',
  [GeneralKeys.Country.blr]: 'Belarus',
  [GeneralKeys.Country.blz]: 'Belize',
  [GeneralKeys.Country.bmu]: 'Bermuda',
  [GeneralKeys.Country.bol]: 'Bolivia (Plurinational State of)',
  [GeneralKeys.Country.brb]: 'Barbados',
  [GeneralKeys.Country.brn]: 'Brunei Darussalam',
  [GeneralKeys.Country.btn]: 'Bhutan',
  [GeneralKeys.Country.bvt]: 'Bouvet Island',
  [GeneralKeys.Country.bwa]: 'Botswana',
  [GeneralKeys.Country.caf]: 'Central African Republic',
  [GeneralKeys.Country.cck]: 'Cocos (Keeling) Islands',
  [GeneralKeys.Country.chl]: 'Chile',
  [GeneralKeys.Country.civ]: "Côte d'Ivoire",
  [GeneralKeys.Country.cmr]: 'Cameroon',
  [GeneralKeys.Country.cod]: 'Congo (the Democratic Republic of the)',
  [GeneralKeys.Country.cog]: 'Congo',
  [GeneralKeys.Country.cok]: 'Cook Islands',
  [GeneralKeys.Country.col]: 'Colombia',
  [GeneralKeys.Country.com]: 'Comoros',
  [GeneralKeys.Country.cpv]: 'Cabo Verde',
  [GeneralKeys.Country.cri]: 'Costa Rica',
  [GeneralKeys.Country.cub]: 'Cuba',
  [GeneralKeys.Country.cuw]: 'Curaçao',
  [GeneralKeys.Country.cxr]: 'Christmas Island',
  [GeneralKeys.Country.cym]: 'Cayman Islands',
  [GeneralKeys.Country.cyp]: 'Cyprus',
  [GeneralKeys.Country.cze]: 'Czechia',
  [GeneralKeys.Country.dji]: 'Djibouti',
  [GeneralKeys.Country.dma]: 'Dominica',
  [GeneralKeys.Country.dom]: 'Dominican Republic',
  [GeneralKeys.Country.dza]: 'Algeria',
  [GeneralKeys.Country.ecu]: 'Ecuador',
  [GeneralKeys.Country.egy]: 'Egypt',
  [GeneralKeys.Country.eri]: 'Eritrea',
  [GeneralKeys.Country.esh]: 'Western Sahara',
  [GeneralKeys.Country.est]: 'Estonia',
  [GeneralKeys.Country.eth]: 'Ethiopia',
  [GeneralKeys.Country.fji]: 'Fiji',
  [GeneralKeys.Country.flk]: 'Falkland Islands (the) [Malvinas]',
  [GeneralKeys.Country.fro]: 'Faroe Islands',
  [GeneralKeys.Country.fsm]: 'Micronesia (Federated States of)',
  [GeneralKeys.Country.gab]: 'Gabon',
  [GeneralKeys.Country.geo]: 'Georgia',
  [GeneralKeys.Country.ggy]: 'Guernsey',
  [GeneralKeys.Country.gha]: 'Ghana',
  [GeneralKeys.Country.gib]: 'Gibraltar',
  [GeneralKeys.Country.gin]: 'Guinea',
  [GeneralKeys.Country.glp]: 'Guadeloupe',
  [GeneralKeys.Country.gmb]: 'Gambia',
  [GeneralKeys.Country.gnb]: 'Guinea-Bissau',
  [GeneralKeys.Country.gnq]: 'Equatorial Guinea',
  [GeneralKeys.Country.grc]: 'Greece',
  [GeneralKeys.Country.grd]: 'Grenada',
  [GeneralKeys.Country.grl]: 'Greenland',
  [GeneralKeys.Country.gtm]: 'Guatemala',
  [GeneralKeys.Country.gum]: 'Guam',
  [GeneralKeys.Country.guy]: 'Guyana',
  [GeneralKeys.Country.hmd]: 'Heard Island and McDonald Islands',
  [GeneralKeys.Country.hnd]: 'Honduras',
  [GeneralKeys.Country.hrv]: 'Croatia',
  [GeneralKeys.Country.hti]: 'Haiti',
  [GeneralKeys.Country.hun]: 'Hungary',
  [GeneralKeys.Country.imn]: 'Isle of Man',
  [GeneralKeys.Country.iot]: 'British Indian Ocean Territory',
  [GeneralKeys.Country.irl]: 'Ireland',
  [GeneralKeys.Country.irq]: 'Iraq',
  [GeneralKeys.Country.isl]: 'Iceland',
  [GeneralKeys.Country.jam]: 'Jamaica',
  [GeneralKeys.Country.jey]: 'Jersey',
  [GeneralKeys.Country.jor]: 'Jordan',
  [GeneralKeys.Country.kaz]: 'Kazakhstan',
  [GeneralKeys.Country.ken]: 'Kenya',
  [GeneralKeys.Country.kgz]: 'Kyrgyzstan',
  [GeneralKeys.Country.khm]: 'Cambodia',
  [GeneralKeys.Country.kir]: 'Kiribati',
  [GeneralKeys.Country.kna]: 'Saint Kitts and Nevis',
  [GeneralKeys.Country.kwt]: 'Kuwait',
  [GeneralKeys.Country.lao]: "Lao People's Democratic Republic",
  [GeneralKeys.Country.lbn]: 'Lebanon',
  [GeneralKeys.Country.lbr]: 'Liberia',
  [GeneralKeys.Country.lby]: 'Libya',
  [GeneralKeys.Country.lca]: 'Saint Lucia',
  [GeneralKeys.Country.lie]: 'Liechtenstein',
  [GeneralKeys.Country.lka]: 'Sri Lanka',
  [GeneralKeys.Country.lso]: 'Lesotho',
  [GeneralKeys.Country.ltu]: 'Lithuania',
  [GeneralKeys.Country.lux]: 'Luxembourg',
  [GeneralKeys.Country.lva]: 'Latvia',
  [GeneralKeys.Country.mac]: 'Macao',
  [GeneralKeys.Country.maf]: 'Saint Martin (French part)',
  [GeneralKeys.Country.mar]: 'Morocco',
  [GeneralKeys.Country.mco]: 'Monaco',
  [GeneralKeys.Country.mda]: 'Moldova (the Republic of)',
  [GeneralKeys.Country.mdg]: 'Madagascar',
  [GeneralKeys.Country.mdv]: 'Maldives',
  [GeneralKeys.Country.mhl]: 'Marshall Islands',
  [GeneralKeys.Country.mkd]: 'Republic of North Macedonia',
  [GeneralKeys.Country.mli]: 'Mali',
  [GeneralKeys.Country.mlt]: 'Malta',
  [GeneralKeys.Country.mmr]: 'Myanmar',
  [GeneralKeys.Country.mne]: 'Montenegro',
  [GeneralKeys.Country.mng]: 'Mongolia',
  [GeneralKeys.Country.mnp]: 'Northern Mariana Islands',
  [GeneralKeys.Country.moz]: 'Mozambique',
  [GeneralKeys.Country.mrt]: 'Mauritania',
  [GeneralKeys.Country.msr]: 'Montserrat',
  [GeneralKeys.Country.mtq]: 'Martinique',
  [GeneralKeys.Country.mus]: 'Mauritius',
  [GeneralKeys.Country.mwi]: 'Malawi',
  [GeneralKeys.Country.myt]: 'Mayotte',
  [GeneralKeys.Country.nam]: 'Namibia',
  [GeneralKeys.Country.ncl]: 'New Caledonia',
  [GeneralKeys.Country.ner]: 'Niger',
  [GeneralKeys.Country.nfk]: 'Norfolk Island',
  [GeneralKeys.Country.nga]: 'Nigeria',
  [GeneralKeys.Country.nic]: 'Nicaragua',
  [GeneralKeys.Country.niu]: 'Niue',
  [GeneralKeys.Country.npl]: 'Nepal',
  [GeneralKeys.Country.nru]: 'Nauru',
  [GeneralKeys.Country.omn]: 'Oman',
  [GeneralKeys.Country.pan]: 'Panama',
  [GeneralKeys.Country.pcn]: 'Pitcairn',
  [GeneralKeys.Country.per]: 'Peru',
  [GeneralKeys.Country.plw]: 'Palau',
  [GeneralKeys.Country.png]: 'Papua New Guinea',
  [GeneralKeys.Country.pri]: 'Puerto Rico',
  [GeneralKeys.Country.prk]: "Korea (the Democratic People's Republic of)",
  [GeneralKeys.Country.pry]: 'Paraguay',
  [GeneralKeys.Country.pse]: 'Palestine, State of',
  [GeneralKeys.Country.qat]: 'Qatar',
  [GeneralKeys.Country.reu]: 'Réunion',
  [GeneralKeys.Country.rou]: 'Romania',
  [GeneralKeys.Country.rus]: 'Russia',
  [GeneralKeys.Country.rwa]: 'Rwanda',
  [GeneralKeys.Country.sdn]: 'Sudan',
  [GeneralKeys.Country.sen]: 'Senegal',
  [GeneralKeys.Country.sgs]: 'South Georgia and the South Sandwich Islands',
  [GeneralKeys.Country.shn]: 'Saint Helena, Ascension and Tristan da Cunha',
  [GeneralKeys.Country.sjm]: 'Svalbard and Jan Mayen',
  [GeneralKeys.Country.slb]: 'Solomon Islands',
  [GeneralKeys.Country.sle]: 'Sierra Leone',
  [GeneralKeys.Country.slv]: 'El Salvador',
  [GeneralKeys.Country.smr]: 'San Marino',
  [GeneralKeys.Country.som]: 'Somalia',
  [GeneralKeys.Country.spm]: 'Saint Pierre and Miquelon',
  [GeneralKeys.Country.srb]: 'Serbia',
  [GeneralKeys.Country.ssd]: 'South Sudan',
  [GeneralKeys.Country.stp]: 'Sao Tome and Principe',
  [GeneralKeys.Country.sur]: 'Suriname',
  [GeneralKeys.Country.svk]: 'Slovakia',
  [GeneralKeys.Country.svn]: 'Slovenia',
  [GeneralKeys.Country.swz]: 'Eswatini',
  [GeneralKeys.Country.sxm]: 'Sint Maarten',
  [GeneralKeys.Country.syc]: 'Seychelles',
  [GeneralKeys.Country.syr]: 'Syrian Arab Republic',
  [GeneralKeys.Country.tca]: 'Turks and Caicos Islands',
  [GeneralKeys.Country.tcd]: 'Chad',
  [GeneralKeys.Country.tgo]: 'Togo',
  [GeneralKeys.Country.tjk]: 'Tajikistan',
  [GeneralKeys.Country.tkl]: 'Tokelau',
  [GeneralKeys.Country.tkm]: 'Turkmenistan',
  [GeneralKeys.Country.tls]: 'Timor-Leste',
  [GeneralKeys.Country.ton]: 'Tonga',
  [GeneralKeys.Country.tto]: 'Trinidad and Tobago',
  [GeneralKeys.Country.tun]: 'Tunisia',
  [GeneralKeys.Country.tuv]: 'Tuvalu',
  [GeneralKeys.Country.tza]: 'Tanzania, United Republic of',
  [GeneralKeys.Country.uga]: 'Uganda',
  [GeneralKeys.Country.ukr]: 'Ukraine',
  [GeneralKeys.Country.umi]: 'United States Minor Outlying Islands',
  [GeneralKeys.Country.ury]: 'Uruguay',
  [GeneralKeys.Country.uzb]: 'Uzbekistan',
  [GeneralKeys.Country.vat]: 'Holy See',
  [GeneralKeys.Country.vct]: 'Saint Vincent and the Grenadines',
  [GeneralKeys.Country.ven]: 'Venezuela (Bolivarian Republic of)',
  [GeneralKeys.Country.vgb]: 'Virgin Islands (British)',
  [GeneralKeys.Country.vir]: 'Virgin Islands (U.S.)',
  [GeneralKeys.Country.vut]: 'Vanuatu',
  [GeneralKeys.Country.wlf]: 'Wallis and Futuna',
  [GeneralKeys.Country.wsm]: 'Samoa',
  [GeneralKeys.Country.yem]: 'Yemen',
  [GeneralKeys.Country.zaf]: 'South Africa',
  [GeneralKeys.Country.zmb]: 'Zambia',
  [GeneralKeys.Country.zwe]: 'Zimbabwe',

  // 2-Char
  [GeneralKeys.Country.ae]: 'United Arab Emirates',
  [GeneralKeys.Country.ar]: 'Argentina',
  [GeneralKeys.Country.au]: 'Australia',
  [GeneralKeys.Country.at]: 'Austria',
  [GeneralKeys.Country.be]: 'Belgium',
  [GeneralKeys.Country.br]: 'Brazil',
  [GeneralKeys.Country.ca]: 'Canada',
  [GeneralKeys.Country.ch]: 'Switzerland',
  [GeneralKeys.Country.cn]: 'China',
  [GeneralKeys.Country.de]: 'Germany',
  [GeneralKeys.Country.dk]: 'Denmark',
  [GeneralKeys.Country.eeu]: 'Europe',
  [GeneralKeys.Country.es]: 'Spain',
  [GeneralKeys.Country.fi]: 'Finland',
  [GeneralKeys.Country.fr]: 'France',
  [GeneralKeys.Country.gb]: 'United Kingdom',
  [GeneralKeys.Country.glb]: 'Global',
  [GeneralKeys.Country.hk]: 'Hong Kong',
  [GeneralKeys.Country.id]: 'Indonesia',
  [GeneralKeys.Country.in]: 'India',
  [GeneralKeys.Country.ir]: 'Iran',
  [GeneralKeys.Country.il]: 'Israel',
  [GeneralKeys.Country.it]: 'Italy',
  [GeneralKeys.Country.jp]: 'Japan',
  [GeneralKeys.Country.kr]: 'South Korea',
  [GeneralKeys.Country.mx]: 'Mexico',
  [GeneralKeys.Country.my]: 'Malaysia',
  [GeneralKeys.Country.nl]: 'Netherlands',
  [GeneralKeys.Country.no]: 'Norway',
  [GeneralKeys.Country.nz]: 'New Zealand',
  [GeneralKeys.Country.pk]: 'Pakistan',
  [GeneralKeys.Country.ph]: 'Philippines',
  [GeneralKeys.Country.pl]: 'Poland',
  [GeneralKeys.Country.pt]: 'Portugal',
  [GeneralKeys.Country.sa]: 'Saudi Arabia',
  [GeneralKeys.Country.sg]: 'Singapore',
  [GeneralKeys.Country.se]: 'Sweden',
  [GeneralKeys.Country.th]: 'Thailand',
  [GeneralKeys.Country.tr]: 'Turkey',
  [GeneralKeys.Country.tw]: 'Taiwan',
  [GeneralKeys.Country.us]: 'United States',
  [GeneralKeys.Country.vn]: 'Vietnam',

  // Client
  [GeneralKeys.Country.aw]: 'Aruba',
  [GeneralKeys.Country.af]: 'Afghanistan',
  [GeneralKeys.Country.ao]: 'Angola',
  [GeneralKeys.Country.ai]: 'Anguilla',
  [GeneralKeys.Country.ax]: 'Åland Islands',
  [GeneralKeys.Country.al]: 'Albania',
  [GeneralKeys.Country.ad]: 'Andorra',
  [GeneralKeys.Country.am]: 'Armenia',
  [GeneralKeys.Country.as]: 'American Samoa',
  [GeneralKeys.Country.aq]: 'Antarctica',
  [GeneralKeys.Country.tf]: 'French Southern Territories',
  [GeneralKeys.Country.ag]: 'Antigua and Barbuda',
  [GeneralKeys.Country.az]: 'Azerbaijan',
  [GeneralKeys.Country.bi]: 'Burundi',
  [GeneralKeys.Country.bj]: 'Benin',
  [GeneralKeys.Country.bq]: 'Bonaire, Sint Eustatius and Saba',
  [GeneralKeys.Country.bf]: 'Burkina Faso',
  [GeneralKeys.Country.bd]: 'Bangladesh',
  [GeneralKeys.Country.bg]: 'Bulgaria',
  [GeneralKeys.Country.bh]: 'Bahrain',
  [GeneralKeys.Country.bs]: 'Bahamas',
  [GeneralKeys.Country.ba]: 'Bosnia and Herzegovina',
  [GeneralKeys.Country.bl]: 'Saint Barthélemy',
  [GeneralKeys.Country.by]: 'Belarus',
  [GeneralKeys.Country.bz]: 'Belize',
  [GeneralKeys.Country.bm]: 'Bermuda',
  [GeneralKeys.Country.bo]: 'Bolivia (Plurinational State of)',
  [GeneralKeys.Country.bb]: 'Barbados',
  [GeneralKeys.Country.bn]: 'Brunei Darussalam',
  [GeneralKeys.Country.bt]: 'Bhutan',
  [GeneralKeys.Country.bv]: 'Bouvet Island',
  [GeneralKeys.Country.bw]: 'Botswana',
  [GeneralKeys.Country.cf]: 'Central African Republic',
  [GeneralKeys.Country.cc]: 'Cocos (Keeling) Islands',
  [GeneralKeys.Country.cl]: 'Chile',
  [GeneralKeys.Country.ci]: "Côte d'Ivoire",
  [GeneralKeys.Country.cm]: 'Cameroon',
  [GeneralKeys.Country.cd]: 'Congo (the Democratic Republic of the)',
  [GeneralKeys.Country.cg]: 'Congo',
  [GeneralKeys.Country.ck]: 'Cook Islands',
  [GeneralKeys.Country.co]: 'Colombia',
  [GeneralKeys.Country.km]: 'Comoros',
  [GeneralKeys.Country.cv]: 'Cabo Verde',
  [GeneralKeys.Country.cr]: 'Costa Rica',
  [GeneralKeys.Country.cu]: 'Cuba',
  [GeneralKeys.Country.cw]: 'Curaçao',
  [GeneralKeys.Country.cx]: 'Christmas Island',
  [GeneralKeys.Country.ky]: 'Cayman Islands',
  [GeneralKeys.Country.cy]: 'Cyprus',
  [GeneralKeys.Country.cz]: 'Czechia',
  [GeneralKeys.Country.dj]: 'Djibouti',
  [GeneralKeys.Country.dm]: 'Dominica',
  [GeneralKeys.Country.do]: 'Dominican Republic',
  [GeneralKeys.Country.dz]: 'Algeria',
  [GeneralKeys.Country.ec]: 'Ecuador',
  [GeneralKeys.Country.eg]: 'Egypt',
  [GeneralKeys.Country.er]: 'Eritrea',
  [GeneralKeys.Country.eh]: 'Western Sahara  ',
  [GeneralKeys.Country.ee]: 'Estonia',
  [GeneralKeys.Country.et]: 'Ethiopia',
  [GeneralKeys.Country.fj]: 'Fiji',
  [GeneralKeys.Country.fk]: 'Falkland Islands (the) [Malvinas]',
  [GeneralKeys.Country.fo]: 'Faroe Islands',
  [GeneralKeys.Country.fm]: 'Micronesia (Federated States of)',
  [GeneralKeys.Country.ga]: 'Gabon',
  [GeneralKeys.Country.ge]: 'Georgia',
  [GeneralKeys.Country.gg]: 'Guernsey',
  [GeneralKeys.Country.gh]: 'Ghana',
  [GeneralKeys.Country.gi]: 'Gibraltar',
  [GeneralKeys.Country.gn]: 'Guinea',
  [GeneralKeys.Country.gp]: 'Guadeloupe',
  [GeneralKeys.Country.gm]: 'Gambia',
  [GeneralKeys.Country.gw]: 'Guinea-Bissau',
  [GeneralKeys.Country.gq]: 'Equatorial Guinea',
  [GeneralKeys.Country.gr]: 'Greece',
  [GeneralKeys.Country.gd]: 'Grenada',
  [GeneralKeys.Country.gl]: 'Greenland',
  [GeneralKeys.Country.gt]: 'Guatemala',
  [GeneralKeys.Country.gu]: 'Guam',
  [GeneralKeys.Country.gy]: 'Guyana',
  [GeneralKeys.Country.hm]: 'Heard Island and McDonald Islands',
  [GeneralKeys.Country.hn]: 'Honduras',
  [GeneralKeys.Country.hr]: 'Croatia',
  [GeneralKeys.Country.ht]: 'Haiti',
  [GeneralKeys.Country.hu]: 'Hungary',
  [GeneralKeys.Country.im]: 'Isle of Man',
  [GeneralKeys.Country.io]: 'British Indian Ocean Territory',
  [GeneralKeys.Country.ie]: 'Ireland',
  [GeneralKeys.Country.iq]: 'Iraq',
  [GeneralKeys.Country.is]: 'Iceland',
  [GeneralKeys.Country.jm]: 'Jamaica',
  [GeneralKeys.Country.je]: 'Jersey',
  [GeneralKeys.Country.jo]: 'Jordan',
  [GeneralKeys.Country.kz]: 'Kazakhstan',
  [GeneralKeys.Country.ke]: 'Kenya',
  [GeneralKeys.Country.kg]: 'Kyrgyzstan',
  [GeneralKeys.Country.kh]: 'Cambodia',
  [GeneralKeys.Country.ki]: 'Kiribati',
  [GeneralKeys.Country.kn]: 'Saint Kitts and Nevis',
  [GeneralKeys.Country.kw]: 'Kuwait',
  [GeneralKeys.Country.la]: "Lao People's Democratic Republic",
  [GeneralKeys.Country.lb]: 'Lebanon',
  [GeneralKeys.Country.lr]: 'Liberia',
  [GeneralKeys.Country.ly]: 'Libya',
  [GeneralKeys.Country.lc]: 'Saint Lucia',
  [GeneralKeys.Country.li]: 'Liechtenstein',
  [GeneralKeys.Country.lk]: 'Sri Lanka',
  [GeneralKeys.Country.ls]: 'Lesotho',
  [GeneralKeys.Country.lt]: 'Lithuania',
  [GeneralKeys.Country.lu]: 'Luxembourg',
  [GeneralKeys.Country.lv]: 'Latvia',
  [GeneralKeys.Country.mo]: 'Macao',
  [GeneralKeys.Country.mf]: 'Saint Martin (French part)',
  [GeneralKeys.Country.ma]: 'Morocco',
  [GeneralKeys.Country.mc]: 'Monaco',
  [GeneralKeys.Country.md]: 'Moldova (the Republic of)',
  [GeneralKeys.Country.mg]: 'Madagascar',
  [GeneralKeys.Country.mv]: 'Maldives',
  [GeneralKeys.Country.mh]: 'Marshall Islands',
  [GeneralKeys.Country.mk]: 'Republic of North Macedonia',
  [GeneralKeys.Country.ml]: 'Mali',
  [GeneralKeys.Country.mt]: 'Malta',
  [GeneralKeys.Country.mm]: 'Myanmar',
  [GeneralKeys.Country.me]: 'Montenegro',
  [GeneralKeys.Country.mn]: 'Mongolia',
  [GeneralKeys.Country.mp]: 'Northern Mariana Islands',
  [GeneralKeys.Country.mz]: 'Mozambique',
  [GeneralKeys.Country.mr]: 'Mauritania',
  [GeneralKeys.Country.ms]: 'Montserrat',
  [GeneralKeys.Country.mq]: 'Martinique',
  [GeneralKeys.Country.mu]: 'Mauritius',
  [GeneralKeys.Country.mw]: 'Malawi',
  [GeneralKeys.Country.yt]: 'Mayotte',
  [GeneralKeys.Country.na]: 'Namibia',
  [GeneralKeys.Country.nc]: 'New Caledonia',
  [GeneralKeys.Country.ne]: 'Niger',
  [GeneralKeys.Country.nf]: 'Norfolk Island',
  [GeneralKeys.Country.ng]: 'Nigeria',
  [GeneralKeys.Country.ni]: 'Nicaragua',
  [GeneralKeys.Country.nu]: 'Niue',
  [GeneralKeys.Country.np]: 'Nepal',
  [GeneralKeys.Country.nr]: 'Nauru',
  [GeneralKeys.Country.om]: 'Oman',
  [GeneralKeys.Country.pa]: 'Panama',
  [GeneralKeys.Country.pn]: 'Pitcairn',
  [GeneralKeys.Country.pe]: 'Peru',
  [GeneralKeys.Country.pw]: 'Palau',
  [GeneralKeys.Country.pg]: 'Papua New Guinea',
  [GeneralKeys.Country.pr]: 'Puerto Rico',
  [GeneralKeys.Country.kp]: "Korea (the Democratic People's Republic of)",
  [GeneralKeys.Country.py]: 'Paraguay',
  [GeneralKeys.Country.ps]: 'Palestine, State of',
  [GeneralKeys.Country.qa]: 'Qatar',
  [GeneralKeys.Country.re]: 'Réunion',
  [GeneralKeys.Country.ro]: 'Romania',
  [GeneralKeys.Country.ru]: 'Russia',
  [GeneralKeys.Country.rw]: 'Rwanda',
  [GeneralKeys.Country.sd]: 'Sudan',
  [GeneralKeys.Country.sn]: 'Senegal',
  [GeneralKeys.Country.gs]: 'South Georgia and the South Sandwich Islands',
  [GeneralKeys.Country.sh]: 'Saint Helena, Ascension and Tristan da Cunha',
  [GeneralKeys.Country.sj]: 'Svalbard and Jan Mayen',
  [GeneralKeys.Country.sb]: 'Solomon Islands',
  [GeneralKeys.Country.sl]: 'Sierra Leone',
  [GeneralKeys.Country.sv]: 'El Salvador',
  [GeneralKeys.Country.sm]: 'San Marino',
  [GeneralKeys.Country.so]: 'Somalia',
  [GeneralKeys.Country.pm]: 'Saint Pierre and Miquelon',
  [GeneralKeys.Country.rs]: 'Serbia',
  [GeneralKeys.Country.ss]: 'South Sudan',
  [GeneralKeys.Country.st]: 'Sao Tome and Principe',
  [GeneralKeys.Country.sr]: 'Suriname',
  [GeneralKeys.Country.sk]: 'Slovakia',
  [GeneralKeys.Country.si]: 'Slovenia',
  [GeneralKeys.Country.sz]: 'Eswatini',
  [GeneralKeys.Country.sx]: 'Sint Maarten',
  [GeneralKeys.Country.sc]: 'Seychelles',
  [GeneralKeys.Country.sy]: 'Syrian Arab Republic',
  [GeneralKeys.Country.tc]: 'Turks and Caicos Islands',
  [GeneralKeys.Country.td]: 'Chad',
  [GeneralKeys.Country.tg]: 'Togo',
  [GeneralKeys.Country.tj]: 'Tajikistan',
  [GeneralKeys.Country.tk]: 'Tokelau',
  [GeneralKeys.Country.tm]: 'Turkmenistan',
  [GeneralKeys.Country.tl]: 'Timor-Leste',
  [GeneralKeys.Country.to]: 'Tonga',
  [GeneralKeys.Country.tt]: 'Trinidad and Tobago',
  [GeneralKeys.Country.tn]: 'Tunisia',
  [GeneralKeys.Country.tv]: 'Tuvalu',
  [GeneralKeys.Country.tz]: 'Tanzania, United Republic of',
  [GeneralKeys.Country.ug]: 'Uganda',
  [GeneralKeys.Country.ua]: 'Ukraine',
  [GeneralKeys.Country.um]: 'United States Minor Outlying Islands',
  [GeneralKeys.Country.uy]: 'Uruguay',
  [GeneralKeys.Country.uz]: 'Uzbekistan',
  [GeneralKeys.Country.va]: 'Holy See',
  [GeneralKeys.Country.vc]: 'Saint Vincent and the Grenadines',
  [GeneralKeys.Country.ve]: 'Venezuela (Bolivarian Republic of)',
  [GeneralKeys.Country.vg]: 'Virgin Islands (British)',
  [GeneralKeys.Country.vi]: 'Virgin Islands (U.S.)',
  [GeneralKeys.Country.vu]: 'Vanuatu',
  [GeneralKeys.Country.wf]: 'Wallis and Futuna',
  [GeneralKeys.Country.ws]: 'Samoa',
  [GeneralKeys.Country.ye]: 'Yemen',
  [GeneralKeys.Country.za]: 'South Africa',
  [GeneralKeys.Country.zm]: 'Zambia',
  [GeneralKeys.Country.zw]: 'Zimbabwe',
};
