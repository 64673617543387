import styled from 'styled-components';
import Heading from '../../Typography/Heading';
import Socials from './Socials';
import Logo from '../Logo';
import { useToggle } from 'react-use';
import device from 'styles/device';
import Stack from '../Stack';
import Menus from './Menus';
import IconButton from 'components/Basics/Buttons/IconButton';
import { useNavigate } from 'react-router-dom';
import { ChevronDownIcon, EnvelopeIcon } from 'assets/icons';
import useFooterStyles from './useFooterStyles';
import { useTranslation } from 'react-i18next';
import FooterKeys from 'translations/translationKeys/FooterKeys';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import useResponsiveScreen from 'hooks/useResponsiveScreen';
import LoginKeys from 'translations/translationKeys/LoginPageKeys';
import TermsConditionsKeys from 'translations/translationKeys/TermsAndConditionsPageKeys';

const Footer = ({ className, defaultBackground, defaultIsVisible }) => {
  const { t, i18n } = useTranslation();
  const { isMobile } = useResponsiveScreen();
  const navigate = useNavigate();
  const { background, isWhite, isVisible } = useFooterStyles({
    defaultBackground,
    defaultIsVisible,
  });
  const [openDetails, setOpenDetails] = useToggle(false);

  if (!isVisible) return null;

  return (
    <Wrapper background={background} className={className} id="footer">
      <Container>
        <Main>
          <Stack gap="1.875rem" align="center">
            <IconButton
              onClick={() => {
                navigate('/');
              }}
            >
              <Logo white={isWhite} />
            </IconButton>

            {isWhite ? (
              <Stack
                direction="row"
                align="center"
                justify="center"
                className="hover-underline hidden md-flex"
                role="button"
              >
                <EnvelopeIcon />
                <Heading
                  variant="H6"
                  onClick={() => window.open('mailto:info@megaevolution.io')}
                  size="sm"
                  className="md-block hidden m-0 text-white"
                >
                  {t(FooterKeys.buisiness_inquiry)}
                </Heading>
              </Stack>
            ) : (
              <Stack
                direction="row"
                align="center"
                justify="center"
                className="hover-underline hidden md-flex"
                role="button"
              >
                <EnvelopeIcon />
                <Heading
                  variant="H6"
                  onClick={() => window.open('mailto:info@megaevolution.io')}
                  size="sm"
                  className="md-block hidden m-0"
                >
                  {t(FooterKeys.buisiness_inquiry)}
                </Heading>
              </Stack>
            )}
          </Stack>

          <Menus className="hidden md-flex" />

          <Socials className="md-hidden" white={isWhite} />
        </Main>
        <BottomWrapper>
          <Bottom>
            {isMobile ? (
              <div className="h10 text-indigo-200">
                <Stack direction="row" align="center" justify="space-between">
                  <Stack
                    direction="row"
                    gap="0.25rem"
                    align="center"
                    className="cursor-pointer"
                    onClick={() => {
                      setOpenDetails();
                      window.scrollTo({
                        bottom: document.body.scrollHeight,
                        behavior: 'smooth',
                      });
                    }}
                  >
                    {t(FooterKeys.company)}
                    <motion.span
                      animate={{ rotate: openDetails ? 180 : 360 }}
                      initial={false}
                      transition={{
                        duration: 0.45,
                        ease: 'easeInOut',
                        type: 'spring',
                        bounce: 0.3,
                      }}
                    >
                      <ChevronDownIcon size={0.48} />
                    </motion.span>
                  </Stack>
                  <Stack
                    className="sbh9 text-indigo-100"
                    gap="4px"
                    direction="row"
                    align="center"
                  >
                    <span
                      onClick={() => {
                        navigate('/terms-and-conditions');
                      }}
                    >
                      {t(
                        LoginKeys.Sign_Up_Form.Terms_of_Service.terms_conditions
                      )}
                    </span>
                    <StraingLine />
                    <span
                      onClick={() => {
                        navigate('/privacy-policy');
                      }}
                    >
                      {t(TermsConditionsKeys.Privacy._19_1_l2)}
                    </span>
                  </Stack>
                </Stack>

                <AnimatePresence>
                  {openDetails && (
                    <>
                      <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className="text-indigo-200"
                      >
                        <div
                          style={{
                            height: '10px',
                          }}
                        />
                        {t(FooterKeys.publisher)}
                        <br></br>
                        {t(FooterKeys.registry_code)}
                        <StraingLine />
                        {t(FooterKeys.global_address)}
                        <br></br>
                        <br></br>

                        {i18n.language.includes('ko') ? (
                          <>
                            {t(FooterKeys.development_customer_support)}
                            <StraingLine />
                            {t(FooterKeys.business_number)}
                            <br></br>
                            {t(FooterKeys.korean_address)}
                            <br></br>
                            {/* 대표이사: 이승찬 | Tel: +82-10-7649-5412 | 메일:
                            info@megaevolution.io */}
                            {t(FooterKeys.ceo)}
                            <StraingLine />
                            {t(FooterKeys.tel)}
                            <StraingLine />
                            {t(FooterKeys.email)}
                          </>
                        ) : (
                          <>
                            {t(FooterKeys.development_customer_support)}
                            <br></br>
                            {t(FooterKeys.business_number)}
                            <StraingLine />
                            {t(FooterKeys.tel)}
                            <br></br>
                            {t(FooterKeys.email)}
                          </>
                        )}
                      </motion.div>
                    </>
                  )}
                </AnimatePresence>
                {t(FooterKeys.description) && (
                  <Description className="md-hidden">
                    {t(FooterKeys.description)}
                  </Description>
                )}
              </div>
            ) : (
              <Heading variant="H7.5" color="var(--color-indigo-200)">
                {t(FooterKeys.publisher)}
                <StraingLine />
                {t(FooterKeys.registry_code)}
                <StraingLine />
                {t(FooterKeys.global_address)}
                <br></br>
                {t(FooterKeys.development_customer_support)}
                <StraingLine />
                {t(FooterKeys.business_number)}
                <StraingLine />

                {i18n.language.includes('ko') ? (
                  <>
                    {t(FooterKeys.korean_address)}
                    <br />
                    {t(FooterKeys.ceo)}
                    <StraingLine />
                    {t(FooterKeys.tel)}
                    <StraingLine />
                    {t(FooterKeys.email)}
                  </>
                ) : (
                  <>
                    {t(FooterKeys.tel)}
                    <StraingLine />
                    {t(FooterKeys.email)}
                  </>
                )}

                {/* {t(FooterKeys.korean_address)} */}
              </Heading>
            )}
            <Socials className="hidden md-flex" white={isWhite} />
          </Bottom>

          {i18n.language.includes('ko') && (
            <Description className="hidden md-inline">
              {t(FooterKeys.description)}
            </Description>
          )}
        </BottomWrapper>
      </Container>
    </Wrapper>
  );
};

export default Footer;

const Wrapper = styled.div`
  width: 100%;
  background-color: ${({ background }) => background};
`;

const Container = styled.footer.attrs({
  className: 'container',
})`
  padding-block: 0;
`;

const Main = styled.div.attrs({
  className: 'footer-main',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;

  @media ${device.md} {
    flex-direction: row;
    justify-content: space-between;
    padding: 3.125rem 0 2rem;
  }
`;

const BottomWrapper = styled.div`
  padding: 0.75rem;
  border-top: 1px solid var(--color-indigo-25);
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0;
  width: 328px;

  @media ${device.md} {
    padding: 1.25rem 0 1.5rem;
    width: 100%;
    align-items: start;
  }
`;

const Bottom = styled.div`
  @media ${device.md} {
    display: flex;
    justify-content: space-between;
    align-items: start;
    width: 100%;
  }
`;

const StraingLine = styled.span.attrs({
  children: ' | ',
})`
  color: var(--color-indigo-50);
`;

const Description = styled.p`
  border-top: 1px solid var(--color-indigo-25);
  padding-top: 0.75rem;
  font-size: 12px;
  margin-top: 0.625rem;
  color: var(--color-indigo-200);

  @media ${device.sm} {
    border: none;
    padding-top: 0;
    font-size: 8px;
  }
`;
