import { useTranslation } from 'react-i18next';
import DynamicDropdown from './components/DynamicDropdown';
import { clientJobPositions, companyTypes } from './constants';
import { useMemo } from 'react';
import countries from 'constants/countries';
import Heading from 'components/Basics/Typography/Heading';
import Stack from 'components/Basics/Layout/Stack';
import IndigoRoundButton from 'components/Basics/Buttons/IndigoRoundButton';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import Content from './components/Content';
import PricingKeys from 'translations/translationKeys/PricingPageKeys';
import device from 'styles/device';
import useResponsiveScreen from 'hooks/useResponsiveScreen';

const InitialStep = ({ setFinalStep }) => {
  const { t } = useTranslation();
  const { trigger } = useFormContext();
  const { isMobile } = useResponsiveScreen();

  const countriesList = useMemo(() => {
    return countries.map((country) => ({
      name: t(country.value),
      value: country.value,
    }));
  }, [t]);

  return (
    <>
      <Grid>
        <Content
          name="firstName"
          placeholder={t(PricingKeys.Modal.Placeholder.firstname)}
        />
        <Content
          name="lastName"
          placeholder={t(PricingKeys.Modal.Placeholder.lastname)}
        />
      </Grid>
      <Grid>
        <Content
          name="companyName"
          placeholder={t(PricingKeys.Modal.Placeholder.companyname)}
        />
        <Content
          name="companyType"
          customComponent={
            <DynamicDropdown
              name="companyType"
              list={companyTypes}
              triggerText={PricingKeys.Modal.CompanyType.title}
            />
          }
        />
      </Grid>
      <Content
        name="companyWebsite"
        placeholder={t(
          isMobile
            ? PricingKeys.Modal.Placeholder.companyUrl_sm
            : PricingKeys.Modal.Placeholder.companyUrl
        )}
      />
      <Grid>
        <Content
          name="jobPosition"
          customComponent={
            <DynamicDropdown
              name="jobPosition"
              list={clientJobPositions}
              triggerText={t(PricingKeys.Modal.JobPosition.title)}
              dropDownHeight="261px"
            />
          }
        />
        <Content
          name="country"
          customComponent={
            <DynamicDropdown
              name="country"
              list={countriesList}
              haveSearchBox
            />
          }
        />
      </Grid>
      <Heading variant="H7" color="var(--color-indigo-100)">
        {t(PricingKeys.Modal.caution)}
      </Heading>
      <Stack
        padding={isMobile ? '0' : '156px 0 0 0'}
        width="max-content w-100"
        className={`${
          isMobile ? 'justify-self-end mt-auto w-100' : ''
        } align-self-center`}
      >
        <IndigoRoundButton
          onClick={async () => {
            const isValid = await trigger([
              'firstName',
              'lastName',
              'companyName',
              'companyType',
              'companyWebsite',
              'jobPosition',
              'country',
            ]);
            if (isValid) setFinalStep();
          }}
          className={isMobile ? 'rounded-2 w-100' : ''}
        >
          {t(PricingKeys.Modal.Btn.proceed_final)}
        </IndigoRoundButton>
      </Stack>
    </>
  );
};

export default InitialStep;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2.25rem;
  width: 100%;

  @media ${device.sm} {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
`;
