import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  [CreatorRegistrationKeys.complete_registration]: 'Complete Registration',
  [CreatorRegistrationKeys.success_registration]: 'Registration Successful!',
  [CreatorRegistrationKeys.evo_collected]: 'EVO collected!',
  [CreatorRegistrationKeys.click_me]: 'Click Me!',
  [CreatorRegistrationKeys.touch_me]: 'Touch me',
  [CreatorRegistrationKeys._10_times]: '10 times!',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: 'Register as a Creator',
  [CreatorRegistrationKeys.Topbar.time]: 'Est. 1 minute',
  [CreatorRegistrationKeys.Topbar.explain]:
    'Please choose one or more options for each section',

  // Social Network
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
    Please connect account(s) with <br>
    more than 500 subscribers to <br>
    join as a creator.`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    'Please allow popups in your browser first to connect a platform',
  [CreatorRegistrationKeys.Social_Network.server_error_tooltip]:
    "Due to a server error on Facebook's side,<br/>the connection is temporarily unavailable.<br/>Please try again later.",

  // Error
  [CreatorRegistrationKeys.Error.social_network]:
    'Please connect a social account',

  [CreatorRegistrationKeys.Error.handle_char_limit]: 'Enter 3~30 characters',
  [CreatorRegistrationKeys.Error.handle_invalid]:
    'Invalid symbol or space detected',
  [CreatorRegistrationKeys.Error.handle_taken]: 'This URL is already taken',
  [CreatorRegistrationKeys.Error.handle_required]: 'Please enter a URL',

  [CreatorRegistrationKeys.Error.category]: 'Please select a category',
  [CreatorRegistrationKeys.Error.invalid_category]: 'Invalid category',

  [CreatorRegistrationKeys.Error.target_country]: 'Please select a country',
  [CreatorRegistrationKeys.Error.invalid_country]: 'Invalid country',

  [CreatorRegistrationKeys.Error.language]: 'Please select a language',
  [CreatorRegistrationKeys.Error.invalid_language]: 'Invalid language',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]:
    'You are already registered as a creator',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
    You can register only once as a creator. <br>
    Please contact support for more information.`,

  [CreatorRegistrationKeys.Modal.already_company.title]:
    'You are already registered as a brand · company',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    'You can only verify as either a creator or a brand. If you wish to register as a creator, please use a new account.',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]:
    'Registration Failed',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    'The following account(s) do not have at least 500 followers or subscribers.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    'Your connected accounts should have at least 500 followers or subscribers to continue.',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]:
    'The following account(s) is already verified and in use by another creator.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]: 'Please unlink the account and try again.',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]:
    'Verification Failed',
  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta]: `
    Please update your Instagram account to a Creator or Business account and try again. <br>
    <u>Click here</u> to view more information.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
    The account needs at least 5 public posts to be verified. Please try again after uploading more posts.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    There is no connected YouTube channel for this account. <br>
    Please connect or create a YouTube channel and try again.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    "To use our service, you must accept all Facebook access options(scopes). These options are essential to our platform's core services, and failure to accept them will result in limited functionality and restricted access to our service. Please reconnect your account by accepting all options.",

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]:
    "To use our service, you must accept all YouTube/Instagram/TikTok/X scopes. These scopes are essential to our platform's core services, and failure to accept them will result in limited functionality and restricted access to our service. Please reconnect your account by accepting all scopes.",

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    'You have selected a different Instagram account than the one currently connected. Please try again by selecting the same account. If you wish to connect a new account, please unlink your current account and attempt the connection again.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    You can verify social media accounts up to ten times a day. <br>
    Please try again later.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    This account is already verified and in use by another creator. <br>
    Please try again with another account.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    'The account needs at least 500 followers or subscribers to be verified.',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    'Update Connection',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    'Upgrade Connection',

  // Instagram Facebook
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    'Update Connection with a Facebook Page',
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    Add a Facebook page to update your Instagram connection. <br>
    This update is <b>required for accepting any Instagram-related orders</b> on the platform.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    An Instagram <b>Creator or Business account</b> is required for this update.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    <b><u>Click here</u></b> to learn more information.`,
  [CreatorRegistrationKeys.Modal.loading_timeout.title]:
    'Please refresh and try again - Security challenge',
  [CreatorRegistrationKeys.Modal.loading_timeout.description]:
    'Please close any other MegaEvolution website tabs and try again. Due to security policies of Google, YouTube, and Instagram, account registration will be unsuccessful if you are using the MegaEvolution website in another tab.',
};
