import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: '没有要显示的创作者',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `订购#3(全球)#3
  #4(#1(影响者)#1 #2(营销)#2)#4
  服务#5(仅需一次点击!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: '试试“时尚营销”',
  [InfluKeys.search.option2]: '试试“视频游戏评测”',
  [InfluKeys.search.option3]: '试试“产品评测”',
  [InfluKeys.search.option4]: '尝试"食品评论"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: '注册成为创作者',

  // Mobile
  [InfluKeys.M.title]: '全球影响者<br/>营销平台',
  [InfluKeys.M.search]: '搜索影响者',
  [InfluKeys.M.content_box.request_creators.title]: '请求创作者',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    '为您的活动获取量身定制的全球创作者列表。',

  // Card
  [InfluKeys.Card.my_card]: '我的卡片',
  [InfluKeys.Card.view_package]: '查看套餐',
  [InfluKeys.Card.creator_profile]: '创作者档案',
  [InfluKeys.Card.view_packages]: '查看套餐',
  [InfluKeys.Card.view_analytics]: '查看分析',
  [InfluKeys.Card.send_a_message]: '发送消息',
  [InfluKeys.Card.view_profile]: '查看个人资料',

  [InfluKeys.Banner_Cards.tech1_description]:
    '现在与300K+ <blue>美国科技创作者</blue>合作。',
  [InfluKeys.Banner_Cards.tech2_description]:
    '现在与200K+ <blue>日本科技创作者</blue>合作。',
  [InfluKeys.Banner_Cards.tech3_description]:
    '现在与1M+ <blue>泰国科技创作者</blue>合作。',
  [InfluKeys.Banner_Cards.tech4_description]:
    '现在与1M+ <blue>西班牙科技创作者</blue>合作。',
  [InfluKeys.Banner_Cards.tech5_description]:
    '现在与500K+ <blue>美国科技创作者</blue>合作。',
  [InfluKeys.Banner_Cards.gaming1_description]:
    '现在与1M+ <blue>韩国游戏创作者</blue>合作。',
  [InfluKeys.Banner_Cards.gaming2_description]:
    '现在与600K+ <blue>美国游戏创作者</blue>合作。',
  [InfluKeys.Banner_Cards.gaming3_description]:
    '现在与1M+ <blue>越南游戏创作者</blue>合作。',
  [InfluKeys.Banner_Cards.gaming4_description]:
    '现在与2M+ <blue>西班牙游戏创作者</blue>合作。',
  [InfluKeys.Banner_Cards.gaming5_description]:
    '现在与800K+ <blue>日本游戏创作者</blue>合作。',
  [InfluKeys.Banner_Cards.beauty1_description]:
    '现在与500K+ <pink>英国美妆创作者</pink>合作。',
  [InfluKeys.Banner_Cards.beauty2_description]:
    '现在与300K+ <pink>日本美妆创作者</pink>合作。',
  [InfluKeys.Banner_Cards.beauty3_description]:
    '现在与300K+ <pink>美国美妆创作者</pink>合作。',
  [InfluKeys.Banner_Cards.beauty4_description]:
    '现在与400K+ <pink>韩国美妆创作者</pink>合作。',
  [InfluKeys.Banner_Cards.food1_description]:
    '现在与1M+ <red>美国美食创作者</red>合作。',
  [InfluKeys.Banner_Cards.food2_description]:
    '现在与1M+ <red>欧盟美食创作者</red>合作。',
  [InfluKeys.Banner_Cards.food3_description]:
    '现在与500K+ <red>日本美食创作者</red>合作。',
  [InfluKeys.Banner_Cards.food4_description]:
    '现在与200K+ <red>韩国美食创作者</red>合作。',
  [InfluKeys.Banner_Cards.food5_description]:
    '现在与800K+ <red>美国美食创作者</red>合作。',
  [InfluKeys.Banner_Cards.fashion1_description]:
    '现在与400K+ <pink>美国时尚创作者</pink>合作。',
  [InfluKeys.Banner_Cards.fashion2_description]:
    '现在与500K+ <pink>欧盟时尚创作者</pink>合作。',
  [InfluKeys.Banner_Cards.fashion3_description]:
    '现在与300K+ <pink>韩国时尚创作者</pink>合作。',
  [InfluKeys.Banner_Cards.fashion4_description]:
    '现在与300K+ <pink>日本时尚创作者</pink>合作。',
  [InfluKeys.Banner_Cards.fashion5_description]:
    '现在与600K+ <pink>舞蹈与Kpop创作者</pink>合作。',
};
