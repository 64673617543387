import FooterKeys from '../translationKeys/FooterKeys';

export const FooterTranslations = {
  [FooterKeys.buisiness_inquiry]: '비즈니스 문의',
  [FooterKeys.publisher]: '글로벌 배급사 : Platform2Publish OÜ',
  [FooterKeys.registry_code]: '등록 코드 : 169015842',
  [FooterKeys.global_address]:
    '주소 (유럽) : Sakala 7-2, 10141 Tallinn, Estonia',
  [FooterKeys.development_customer_support]: '개발사 : 주식회사 인타버스',
  [FooterKeys.business_number]: '사업자 등록 번호 : 279-88-02709',
  [FooterKeys.korean_address]:
    '주소 : 서울특별시 강남구 테헤란로82길 15(대치동, 디아이타워) 1178호',
  [FooterKeys.ceo]: '대표이사 : 이승찬',
  [FooterKeys.tel]: 'Tel : +82-10-7649-5412',
  [FooterKeys.email]: '메일 : info@megaevolution.io',
  [FooterKeys.description]:
    '주식회사 인타버스는 메가에볼루션 오픈마켓의 개발 부문을 담당하고 있으며, 결제 및 통신판매의 당사자가 아닙니다. 상품, 상품정보, 거래에 관한 의무와 책임은 판매회원에게 있습니다.',
  [FooterKeys.company]: '메가에볼루션 배급사 · 개발사 정보',
};
