import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: '検索結果が見つかりませんでした。',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(世界中の)#1#2(クリエイター)#2
  #3(マーケティング)#3 #4(サービスを)#4
  #5(ワンクリックで注文!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: '「ファッションマーケティング」を試してみる',
  [InfluKeys.search.option2]: '「ビデオゲームレビュー」を試してみる',
  [InfluKeys.search.option3]: '「製品レビュー」を試してみる',
  [InfluKeys.search.option4]: '「食べ物レビュー」を試してみる',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'クリエイターとして登録',

  // Mobile
  [InfluKeys.M.title]:
    'グローバルインフルエンサー<br/>マーケティングプラットフォーム',
  [InfluKeys.M.search]: 'クリエーターを検索',
  [InfluKeys.M.content_box.request_creators.title]: 'クリエイターをリクエスト',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'キャンペーンのためにカスタマイズされたグローバルクリエイターリストを取得してください。',

  // Card
  [InfluKeys.Card.my_card]: 'マイカード',
  [InfluKeys.Card.view_package]: 'パッケージを見る',
  [InfluKeys.Card.creator_profile]: 'クリエータープロフィール',
  [InfluKeys.Card.view_packages]: 'パッケージを表示',
  [InfluKeys.Card.view_analytics]: 'データ分析を見る',
  [InfluKeys.Card.send_a_message]: 'メッセージを送信',
  [InfluKeys.Card.view_profile]: 'プロフィールを見る',

  [InfluKeys.Banner_Cards.tech1_description]:
    '今すぐ300K+ <blue>米国のテククリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.tech2_description]:
    '今すぐ200K+ <blue>日本のテククリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.tech3_description]:
    '今すぐ1M+ <blue>タイのテククリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.tech4_description]:
    '今すぐ1M+ <blue>スペインのテククリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.tech5_description]:
    '今すぐ500K+ <blue>米国のテククリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.gaming1_description]:
    '今すぐ1M+ <blue>韓国のゲームクリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.gaming2_description]:
    '今すぐ600K+ <blue>米国のゲームクリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.gaming3_description]:
    '今すぐ1M+ <blue>ベトナムのゲームクリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.gaming4_description]:
    '今すぐ2M+ <blue>スペインのゲームクリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.gaming5_description]:
    '今すぐ800K+ <blue>日本のゲームクリエイター</blue>とコラボしよう。',
  [InfluKeys.Banner_Cards.beauty1_description]:
    '今すぐ500K+ <pink>イギリスのビューティークリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.beauty2_description]:
    '今すぐ300K+ <pink>日本のビューティークリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.beauty3_description]:
    '今すぐ300K+ <pink>米国のビューティークリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.beauty4_description]:
    '今すぐ400K+ <pink>韓国のビューティークリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.food1_description]:
    '今すぐ1M+ <red>米国のフードクリエイター</red>とコラボしよう。',
  [InfluKeys.Banner_Cards.food2_description]:
    '今すぐ1M+ <red>EUのフードクリエイター</red>とコラボしよう。',
  [InfluKeys.Banner_Cards.food3_description]:
    '今すぐ500K+ <red>日本のフードクリエイター</red>とコラボしよう。',
  [InfluKeys.Banner_Cards.food4_description]:
    '今すぐ200K+ <red>韓国のフードクリエイター</red>とコラボしよう。',
  [InfluKeys.Banner_Cards.food5_description]:
    '今すぐ800K+ <red>米国のフードクリエイター</red>とコラボしよう。',
  [InfluKeys.Banner_Cards.fashion1_description]:
    '今すぐ400K+ <pink>米国のファッションクリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.fashion2_description]:
    '今すぐ500K+ <pink>EUのファッションクリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.fashion3_description]:
    '今すぐ300K+ <pink>韓国のファッションクリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.fashion4_description]:
    '今すぐ300K+ <pink>日本のファッションクリエイター</pink>とコラボしよう。',
  [InfluKeys.Banner_Cards.fashion5_description]:
    '今すぐ600K+ <pink>ダンス & Kpopクリエイター</pink>とコラボしよう。',
};
