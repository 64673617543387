const InfluKeys = {
  no_influencer: 'InfluKeys.no_influencer',

  title: 'InfluKeys.title',

  search: {
    option1: 'InfluKeys.search.option1',
    option2: 'InfluKeys.search.option2',
    option3: 'InfluKeys.search.option3',
    option4: 'InfluKeys.search.option4',
  },

  side_popup: {
    C: {
      join_creator: 'InfluKeys.side_popup.C.join_creator',
    },
  },

  M: {
    title: 'InfluKeys.M.title',

    search: 'InfluKeys.M.search',

    content_box: {
      request_creators: {
        title: 'InfluKeys.M.content_box.request_creators.title',
        subTitle: 'InfluKeys.M.content_box.request_creators.subTitle',
      },
    },
  },

  Card: {
    my_card: 'InfluKeys.Card.my_card',
    creator_profile: 'InfluKeys.Card.creator_profile',
    view_package: 'InfluKeys.Card.view_package',
    view_packages: 'InfluKeys.Card.view_packages',
    view_analytics: 'InfluKeys.Card.view_analytics',
    send_a_message: 'InfluKeys.Card.send_a_message',
    view_profile: 'InfluKeys.Card.view_profile',
  },

  Banner_Cards: {
    tech1_description: 'InfluKeys.Banner_Cards.tech1_description',
    tech2_description: 'InfluKeys.Banner_Cards.tech2_description',
    tech3_description: 'InfluKeys.Banner_Cards.tech3_description',
    tech4_description: 'InfluKeys.Banner_Cards.tech4_description',
    tech5_description: 'InfluKeys.Banner_Cards.tech5_description',
    gaming1_description: 'InfluKeys.Banner_Cards.gaming1_description',
    gaming2_description: 'InfluKeys.Banner_Cards.gaming2_description',
    gaming3_description: 'InfluKeys.Banner_Cards.gaming3_description',
    gaming4_description: 'InfluKeys.Banner_Cards.gaming4_description',
    gaming5_description: 'InfluKeys.Banner_Cards.gaming5_description',
    beauty1_description: 'InfluKeys.Banner_Cards.beauty1_description',
    beauty2_description: 'InfluKeys.Banner_Cards.beauty2_description',
    beauty3_description: 'InfluKeys.Banner_Cards.beauty3_description',
    beauty4_description: 'InfluKeys.Banner_Cards.beauty4_description',
    food1_description: 'InfluKeys.Banner_Cards.food1_description',
    food2_description: 'InfluKeys.Banner_Cards.food2_description',
    food3_description: 'InfluKeys.Banner_Cards.food3_description',
    food4_description: 'InfluKeys.Banner_Cards.food4_description',
    food5_description: 'InfluKeys.Banner_Cards.food5_description',
    fashion1_description: 'InfluKeys.Banner_Cards.fashion1_description',
    fashion2_description: 'InfluKeys.Banner_Cards.fashion2_description',
    fashion3_description: 'InfluKeys.Banner_Cards.fashion3_description',
    fashion4_description: 'InfluKeys.Banner_Cards.fashion4_description',
    fashion5_description: 'InfluKeys.Banner_Cards.fashion5_description',
  },
};

export default InfluKeys;
