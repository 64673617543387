import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  [CreatorRegistrationKeys.complete_registration]: '등록 완료',
  [CreatorRegistrationKeys.success_registration]: '등록 성공!',
  [CreatorRegistrationKeys.evo_collected]: 'EVO를 수집했습니다!',
  [CreatorRegistrationKeys.click_me]: '여기를 클릭하세요!',
  [CreatorRegistrationKeys.touch_me]: '여기를 터치하세요',
  [CreatorRegistrationKeys._10_times]: '10번!',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: '크리에이터로 등록하기',
  [CreatorRegistrationKeys.Topbar.time]: '예상 1분',
  [CreatorRegistrationKeys.Topbar.explain]:
    '각 섹션에 대해 하나 이상의 옵션을 선택하세요',

  // Social Network
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
    크리에이터로 가입하려면 <br>
    500명 이상의 구독자가 <br>
    있는 계정을 연결하세요`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    '플랫폼을 연결하려면 브라우저에서 팝업을 먼저 허용해주세요',
  [CreatorRegistrationKeys.Social_Network.server_error_tooltip]:
    'Facebook 서버 오류로 인해<br/> 연결이 일시적으로 불가능합니다.<br/> 나중에 다시 시도해 주세요.',

  // Error
  [CreatorRegistrationKeys.Error.social_network]: '소셜 계정을 연결해주세요',

  [CreatorRegistrationKeys.Error.handle_char_limit]: '3~30자를 입력하세요',
  [CreatorRegistrationKeys.Error.handle_invalid]:
    '유효하지 않은 기호 또는 공백이 있습니다',
  [CreatorRegistrationKeys.Error.handle_taken]: '해당 URL은 이미 사용 중입니다',
  [CreatorRegistrationKeys.Error.handle_required]: 'URL을 입력해주세요',

  [CreatorRegistrationKeys.Error.category]: '카테고리를 선택해주세요',
  [CreatorRegistrationKeys.Error.invalid_category]:
    '유효하지 않은 카테고리입니다',

  [CreatorRegistrationKeys.Error.target_country]: '국가를 선택해주세요',
  [CreatorRegistrationKeys.Error.invalid_country]: '유효하지 않은 국가입니다',

  [CreatorRegistrationKeys.Error.language]: '언어를 선택해주세요',
  [CreatorRegistrationKeys.Error.invalid_language]: '유효하지 않은 언어입니다',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]:
    '이미 크리에이터로 등록되었습니다',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
    크리에이터로는 한 번만 등록할 수 있습니다. <br>
    자세한 정보를 얻으려면 지원팀에 문의해주세요.`,

  [CreatorRegistrationKeys.Modal.already_company.title]:
    '계정이 이미 브랜드 · 기업으로 인증되었습니다',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    '크리에이터 또는 브랜드 둘중 하나로 한번만 인증하실 수 있습니다. 크리에이터로 등록을 원하실 경우 새로운 계정을 이용해주시길 바랍니다.',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]: '등록 실패',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    '다음 계정의 팔로워 또는 구독자가 500명 미만입니다.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    '계속하려면 최소 500명의 팔로워 또는 구독자가 있어야 합니다.',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]:
    '이미 다른 크리에이터에게 연결되어 사용중인 계정입니다.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]: '연결을 해제하고 다시 시도해주세요.',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]: '인증 실패',
  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta]: `
    인스타그램 계정을 크리에이터 또는 비즈니스 계정으로 업데이트하고 다시 시도해주세요. <br>
    <u>여기</u>를 클릭하면 더 많은 정보를 볼 수 있습니다.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
      인증하려면 최소 5개의 공개 게시물이 필요합니다. 게시물을 더 올린 후 인증을 다시 해주세요.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    계정에 연결된 YouTube 채널이 없습니다. <br>
    YouTube 채널을 연결 후 다시 시도해주세요.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    '저희 서비스를 이용하려면 모든 Facebook 액세스 옵션(스코프)을 수락해야 합니다. 저희 플랫폼의 핵심 서비스를 이용하기 위한 필수적이며, 이를 수락하지 않을 경우 기능이 제한되고 서비스 접근이 제한될 수 있습니다. 모든 옵션을 수락하여 계정을 다시 연결해 주세요',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]:
    '저희 서비스를 이용하려면 유튜브/인스타그램/틱톡/X 범위를 모두 수락해야 합니다. 이 범위는 저희 플랫폼의 핵심 서비스에 필수적이며, 수락하지 않을 경우 기능이 제한되고 서비스에 제한된 액세스가 될 수 있습니다. 모든 범위를 수락하여 계정을 다시 연결해주세요.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    '현재 연결된 인스타그램 계정과 다른 계정을 선택하셨습니다. 동일한 계정으로 연결을 다시 시도해주세요. 새로운 계정을 연결하려면 현재 계정을 해제한 후 다시 연결을 시도해주세요.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    하루에 최대 열 번까지 소셜 미디어 계정을 확인할 수 있습니다. <br>
    나중에 다시 시도해주세요.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    이 계정은 이미 다른 크리에이터가 사용중입니다. 다른 계정으로 다시 시도해주세요.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    '최소 500명의 팔로워 또는 구독자가 필요합니다.',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    '연결 업데이트',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    '연결 업그레이드',

  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    '페이스북 페이지를 통해 연결 업데이트하기',
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    인스타그램 연결을 업데이트하려면 페이스북 페이지를 추가하세요. <br>
    이 업데이트는 <b>플랫폼에서 인스타그램 관련 주문</b>을 받기 위해 필요합니다.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    본 업데이트를 위해서 인스타그램 <b>크리에이터 또는 비즈니스 계정</b>이 필요합니다.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    <b><u>여기</u></b>를 클릭해서 더 많은 정보를 확인하세요.`,
  [CreatorRegistrationKeys.Modal.loading_timeout.title]:
    '새로고침 후 다시 시도해주세요 - 보안 문제',
  [CreatorRegistrationKeys.Modal.loading_timeout.description]:
    '다른 탭에서 이용중이신 메가에볼루션 웹사이트를 모두 닫고 다시 시도해 주세요. 구글, 유튜브, 인스타그램의 보안 정책에 따라 다른 탭에서 메가에볼루션 웹사이트를 사용 중일 경우 계정 등록이 완료되지 않을 수 있습니다.',
};
