import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  // Creator Registration
  [CreatorRegistrationKeys.complete_registration]: 'Completar Registro',
  [CreatorRegistrationKeys.success_registration]: '¡Registro Exitoso!',
  [CreatorRegistrationKeys.evo_collected]: '¡EVO recolectado!',
  [CreatorRegistrationKeys.click_me]: '¡Haz clic en mí!',
  [CreatorRegistrationKeys.touch_me]: 'Tócame',
  [CreatorRegistrationKeys._10_times]: '¡10 veces!',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: 'Registrarse como Creador',
  [CreatorRegistrationKeys.Topbar.time]: 'Aprox. 1 minuto',
  [CreatorRegistrationKeys.Topbar.explain]:
    'Por favor, elige una o más opciones para cada sección',

  // Social Network
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
  Por favor, conecta una cuenta(s) con <br>
  más de 500 suscriptores para <br>
  para unirte como creador.`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    'Por favor, permita ventanas emergentes en su navegador primero para conectarse a una plataforma',
  [CreatorRegistrationKeys.Social_Network.server_error_tooltip]:
    'Debido a un error del servidor en Facebook,<br/> la conexión no está disponible temporalmente.<br/> Por favor, inténtelo de nuevo más tarde.',

  // Error
  [CreatorRegistrationKeys.Error.social_network]:
    'Por favor, conecta una cuenta social',

  [CreatorRegistrationKeys.Error.handle_char_limit]:
    'Ingresa entre 3 y 30 caracteres',
  [CreatorRegistrationKeys.Error.handle_invalid]:
    'Símbolo o espacio inválido detectado',
  [CreatorRegistrationKeys.Error.handle_taken]: 'Esta URL ya está en uso',
  [CreatorRegistrationKeys.Error.handle_required]: 'Por favor ingresa una URL',

  [CreatorRegistrationKeys.Error.category]:
    'Por favor, selecciona una categoría',
  [CreatorRegistrationKeys.Error.invalid_category]: 'Categoría inválida',

  [CreatorRegistrationKeys.Error.target_country]:
    'Por favor seleccione un país',
  [CreatorRegistrationKeys.Error.invalid_country]: 'País inválido',

  [CreatorRegistrationKeys.Error.language]: 'Por favor, seleccione un idioma',
  [CreatorRegistrationKeys.Error.invalid_language]: 'Idioma inválido',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]:
    'Ya estás registrado como creador',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
    Solo puedes registrarte una vez como creador. <br>
    Por favor, ponte en contacto con el soporte para obtener más información.`,

  [CreatorRegistrationKeys.Modal.already_company.title]:
    'Ya estás registrado como marca · empresa',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    'Solo puedes verificar como creador o marca. Si deseas registrarte como creador, por favor utiliza una cuenta nueva.',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]: 'Registro Fallido',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    'La(s) siguiente(s) cuenta(s) no tienen al menos 500 seguidores o suscriptores.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    'Tus cuentas conectadas deben tener al menos 500 seguidores o suscriptores para continuar.',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]:
    'La(s) siguiente(s) cuenta(s) ya está verificada y en uso por otro creador.',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]:
    'Por favor, desvincula la cuenta e inténtalo de nuevo.',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]:
    'Verificación Fallida',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta]: `
    Actualice su cuenta de Instagram a una cuenta de Creador o Negocio y vuelva a intentarlo. <br>
    <u>Haga clic aquí</u> para ver más información.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
      La cuenta necesita al menos 5 publicaciones públicas para ser verificada. Por favor, inténtalo nuevamente después de subir más publicaciones.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    No hay un canal de YouTube conectado para esta cuenta. <br>
    Por favor, conecta o crea un canal de YouTube y vuelve a intentarlo.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    'Para usar nuestro servicio, debes aceptar todas las opciones de acceso de Facebook (ámbitos). Estas opciones son esenciales para los servicios principales de nuestra plataforma, y no aceptarlas resultará en funcionalidad limitada y acceso restringido a nuestro servicio. Por favor, vuelva a conectar tu cuenta aceptando todas las opciones.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]:
    'Para usar nuestro servicio, debes aceptar todos los permisos de YouTube/Instagram/TikTok/X. Estos permisos son esenciales para los servicios principales de nuestra plataforma, y no aceptarlos resultará en una funcionalidad limitada y acceso restringido a nuestro servicio. Por favor, vuelva a conectar tu cuenta aceptando todos los permisos.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    'Ha seleccionado una cuenta de Instagram diferente a la que está actualmente conectada. Por favor, intente nuevamente seleccionar la misma cuenta. Si desea conectar una cuenta nueva, por favor desconecte su cuenta actual e intente la conexión nuevamente.',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    Puedes verificar cuentas de redes sociales hasta diez veces al día. <br>
    Por favor, inténtalo de nuevo luego.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    Esta cuenta ya está verificada y en uso por otro creador. <br>
    Por favor, intenta nuevamente con otra cuenta.`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    'La cuenta necesita al menos 500 seguidores o suscriptores para ser verificada.',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    'Actualizar Conexión',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    'Actualizar Conexión',

  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    'Actualizar Conexión a través de la Página de Facebook',
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    Agregue una página de Facebook para actualizar su conexión de Instagram. <br>
    Esta actualización es <b>requerida para aceptar cualquier pedido relacionado con Instagram</b> en la plataforma.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    Se requiere una cuenta de Instagram <b>de Creador o Negocios</b> para esta actualización.`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    <b><u>Haga clic aquí</u></b> para obtener más información.`,
  [CreatorRegistrationKeys.Modal.loading_timeout.title]:
    'Por favor, actualiza e intenta de nuevo - Desafío de seguridad',
  [CreatorRegistrationKeys.Modal.loading_timeout.description]:
    'Por favor, cierre cualquier otra pestaña del sitio web de MegaEvolution e intente de nuevo. Debido a las políticas de seguridad de Google, YouTube e Instagram, el registro de la cuenta no será exitoso si está utilizando el sitio web de MegaEvolution en otra pestaña.',
};
