import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'No hay creadores para mostrar',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `#1(¡Ordena)#1 #2(global servicios)#2
  de #3(creador marketing)#3
  en un #4(solo)#4 #5(clic!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'Intenta "Marketing de Moda"',
  [InfluKeys.search.option2]: 'Intenta "Reseñas de Videojuegos"',
  [InfluKeys.search.option3]: 'Intenta "Reseñas de Productos"',
  [InfluKeys.search.option4]: 'Intenta "Reseñas de comida"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'Registrarse como Creador',

  // Mobile
  [InfluKeys.M.title]: 'Plataforma de Marketing<br/>de Influencers Global',
  [InfluKeys.M.search]: 'Buscar creadores',
  [InfluKeys.M.content_box.request_creators.title]: 'Solicitar Creadores',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'Obtén una lista personalizada de creadores globales para tu campaña.',

  // Card
  [InfluKeys.Card.my_card]: 'Mi Tarjeta',
  [InfluKeys.Card.view_package]: 'Ver paquete',
  [InfluKeys.Card.creator_profile]: 'Perfil del Creador',
  [InfluKeys.Card.view_packages]: 'Ver Paquetes',
  [InfluKeys.Card.view_analytics]: 'Ver Análisis',
  [InfluKeys.Card.send_a_message]: 'Enviar un Mensaje',
  [InfluKeys.Card.view_profile]: 'Ver perfil',

  [InfluKeys.Banner_Cards.tech1_description]:
    'Colabora ahora con un creador de tecnología de EE.UU. con más de 300K <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.tech2_description]:
    'Colabora ahora con un creador de tecnología de Japón con más de 200K <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.tech3_description]:
    'Colabora ahora con un creador de tecnología de Tailandia con más de 1M <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.tech4_description]:
    'Colabora ahora con un creador de tecnología de España con más de 1M <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.tech5_description]:
    'Colabora ahora con un creador de tecnología de EE.UU. con más de 500K <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.gaming1_description]:
    'Colabora ahora con un creador de juegos de Corea con más de 1M <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.gaming2_description]:
    'Colabora ahora con un creador de juegos de EE.UU. con más de 600K <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.gaming3_description]:
    'Colabora ahora con un creador de juegos de Vietnam con más de 1M <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.gaming4_description]:
    'Colabora ahora con un creador de juegos de España con más de 2M <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.gaming5_description]:
    'Colabora ahora con un creador de juegos de Japón con más de 800K <blue>seguidores</blue>.',
  [InfluKeys.Banner_Cards.beauty1_description]:
    'Colabora ahora con un creador de belleza del Reino Unido con más de 500K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.beauty2_description]:
    'Colabora ahora con un creador de belleza de Japón con más de 300K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.beauty3_description]:
    'Colabora ahora con un creador de belleza de EE.UU. con más de 300K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.beauty4_description]:
    'Colabora ahora con un creador de belleza de Corea con más de 400K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.food1_description]:
    'Colabora ahora con un creador de comida de EE.UU. con más de 1M <red>seguidores</red>.',
  [InfluKeys.Banner_Cards.food2_description]:
    'Colabora ahora con un creador de comida de la UE con más de 1M <red>seguidores</red>.',
  [InfluKeys.Banner_Cards.food3_description]:
    'Colabora ahora con un creador de comida de Japón con más de 500K <red>seguidores</red>.',
  [InfluKeys.Banner_Cards.food4_description]:
    'Colabora ahora con un creador de comida de Corea con más de 200K <red>seguidores</red>.',
  [InfluKeys.Banner_Cards.food5_description]:
    'Colabora ahora con un creador de comida de EE.UU. con más de 800K <red>seguidores</red>.',
  [InfluKeys.Banner_Cards.fashion1_description]:
    'Colabora ahora con un creador de moda de EE.UU. con más de 400K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.fashion2_description]:
    'Colabora ahora con un creador de moda de la UE con más de 500K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.fashion3_description]:
    'Colabora ahora con un creador de moda de Corea con más de 300K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.fashion4_description]:
    'Colabora ahora con un creador de moda de Japón con más de 300K <pink>seguidores</pink>.',
  [InfluKeys.Banner_Cards.fashion5_description]:
    'Colabora ahora con un creador de Danza y Kpop con más de 600K <pink>seguidores</pink>.',
};
