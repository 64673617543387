import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  [CreatorRegistrationKeys.complete_registration]: '登録完了',
  [CreatorRegistrationKeys.success_registration]: '登録が成功しました！',
  [CreatorRegistrationKeys.evo_collected]: 'EVOを収集しました！',
  [CreatorRegistrationKeys.click_me]: 'クリックしてください！',
  [CreatorRegistrationKeys.touch_me]: 'タッチしてください',
  [CreatorRegistrationKeys._10_times]: '10回！',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: 'クリエイターとして登録',
  [CreatorRegistrationKeys.Topbar.time]: '約1分',
  [CreatorRegistrationKeys.Topbar.explain]:
    '各セクションに1つ以上のオプションを選択してください',

  // Social Network
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
  クリエイターとして参加するには、 <br>
  登録者が500人を超えるアカウントを <br>
  選択してください。`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    'まずブラウザでポップアップを許可してプラットフォームに接続してください',
  [CreatorRegistrationKeys.Social_Network.server_error_tooltip]:
    'Facebook側のサーバーエラーにより、<br/>接続が一時的に利用できません。<br/>後でもう一度お試しください。',

  // Error
  [CreatorRegistrationKeys.Error.social_network]:
    'ソーシャルアカウントを接続してください',

  [CreatorRegistrationKeys.Error.handle_char_limit]:
    '3〜30文字を入力してください',
  [CreatorRegistrationKeys.Error.handle_invalid]:
    '無効な記号またはスペースが検出されました',
  [CreatorRegistrationKeys.Error.handle_taken]:
    'この URL はすでに使用されています',
  [CreatorRegistrationKeys.Error.handle_required]: 'URL を入力してください',

  [CreatorRegistrationKeys.Error.category]: 'カテゴリーを選択してください',
  [CreatorRegistrationKeys.Error.invalid_category]: '無効なカテゴリーです',

  [CreatorRegistrationKeys.Error.target_country]: '国を選択してください',
  [CreatorRegistrationKeys.Error.invalid_country]: '無効な国です',

  [CreatorRegistrationKeys.Error.language]: '言語を選択してください',
  [CreatorRegistrationKeys.Error.invalid_language]: '無効な言語です',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]:
    'すでにクリエイターとして登録されています',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
    クリエイターとしては1回だけ登録できます。 <br>
    詳細についてはサポートにお問い合わせください。`,

  [CreatorRegistrationKeys.Modal.already_company.title]:
    'アカウントが既にブランド・企業として認証されています',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    'クリエイターまたはブランドのいずれか一方としてのみ認証が可能です。クリエイターとして登録したい場合は、新しいアカウントを使用してください。',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]: '登録に失敗しました',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    '続行するには、アカウントに500人以上のフォロワーまたは登録者が必要です。',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    '次のアカウントはすでに他のクリエイターが使用しています。',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]:
    '次のアカウントはすでに確認済みで他のクリエイターが使用しています。',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]: 'アカウントをリンク解除して再試行してください。',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]: '確認に失敗しました',
  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.update_insta]: `
    Instagramアカウントをクリエイターまたはビジネスアカウントにアップデートしてから、もう一度お試しください。<br>
    詳細情報を表示するには<u>こちらをクリック</u>してください。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
    アカウントの検証には、少なくとも5件の公開投稿が必要です。投稿をもっとアップロードしてから、再試行してください。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    このアカウントには接続されたYouTubeチャンネルがありません。 <br>
    YouTubeチャンネルを接続または作成して、もう一度試してください。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    '当社のサービスをご利用いただくには、すべてのFacebookアクセスオプション（スコープ）に同意する必要があります。これらのオプションは、当社のプラットフォームの主要なサービスに不可欠であり、これらを受け入れないと機能およびサービスへのアクセスが制限されます。すべてのオプションを受け入れてアカウントを再接続してください。',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]:
    '当社のサービスを利用するには、すべてのYouTube/Instagram/TikTok/Xスコープを受け入れる必要があります。これらのスコープは当社のプラットフォームの主要サービスに必要不可欠であり、これらを受け入れない場合は機能およびサービスへのアクセスが制限されます。すべてのスコープを受け入れることでアカウントを再接続してください。',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    '現在接続されているInstagramアカウントとは異なるアカウントを選択しました。同じアカウントを選択して再試行してください。新しいアカウントを接続したい場合は、現在のアカウントを切断して接続を再試行してください。',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    1日に最大10回の回数までソーシャルメディアアカウントを認証できます。 <br>
    後でもう一度試してください。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    このアカウントはすでに他のクリエーターに使用されています。 <br>
    別のアカウントで再試行してください。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    'アカウントに500人以上のフォロワーまたは購読者が必要です。',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    '接続を更新',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    '接続のアップグレード',

  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    'Facebook ページを介して接続を更新する',
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    Instagramの接続を更新するためにFacebookページを追加してください。 <br>
    この更新は、プラットフォーム上でのInstagramに関連する注文を<b>受け入れるために必要です</b>。`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    この更新にはInstagram <b>クリエイターまたはビジネスアカウント</b> が必要です。`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    詳細情報は<b><u>こちらをクリック</u></b>してください。`,
  [CreatorRegistrationKeys.Modal.loading_timeout.title]:
    'ページを更新して、再試行してください - セキュリティチャレンジ',
  [CreatorRegistrationKeys.Modal.loading_timeout.description]:
    '他のMegaEvolutionウェブサイトのタブを閉じて、再試行してください。Google、YouTube、およびInstagramのセキュリティポリシーにより、別のタブでMegaEvolutionウェブサイトを使用している場合、アカウント登録は成功しません。',
};
