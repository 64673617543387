import InfluKeys from '../translationKeys/InfluencerMarketingPageKeys';

export const InfluencerMarketingPageTranslations = {
  [InfluKeys.no_influencer]: 'No creators to display',

  // Title - The words that are needed to be highlighted are wrapped in #1()#1 and #2()#2 and so on
  //         The timing for these can be set in components\MarketingHomePage\BannerSection\ContentSection\MainText\index.js
  [InfluKeys.title]: `Order #3(global)#3
  #4(#1(creator)#1 #2(marketing)#2)#4
  services #5(in just a click!)#5`,

  // Search Bar
  [InfluKeys.search.option1]: 'Try "Fashion Marketing"',
  [InfluKeys.search.option2]: 'Try "Video Game Reviews"',
  [InfluKeys.search.option3]: 'Try "Product Reviews"',
  [InfluKeys.search.option4]: 'Try "Food Reviews"',

  // Side Popup

  // Client
  [InfluKeys.side_popup.C.join_creator]: 'Join as a Creator',

  // Mobile
  [InfluKeys.M.title]: 'Global Influencer<br/>Marketing Platform',
  [InfluKeys.M.search]: 'Search creators',
  [InfluKeys.M.content_box.request_creators.title]: 'Request Creators',
  [InfluKeys.M.content_box.request_creators.subTitle]:
    'Get a tailored list of global creators for your campaign.',

  // Card
  [InfluKeys.Card.my_card]: 'My Card',
  [InfluKeys.Card.view_package]: 'View Package',
  [InfluKeys.Card.creator_profile]: 'Creator Profile',
  [InfluKeys.Card.view_packages]: 'View Packages',
  [InfluKeys.Card.view_analytics]: 'View Analytics',
  [InfluKeys.Card.send_a_message]: 'Send a Message',
  [InfluKeys.Card.view_profile]: 'View Profile',

  [InfluKeys.Banner_Cards.tech1_description]:
    'Collaborate now with a 300K + <blue>U.S. tech creator</blue>.',
  [InfluKeys.Banner_Cards.tech2_description]:
    'Collaborate now with a 200K+ <blue>Japanese tech creator</blue>.',
  [InfluKeys.Banner_Cards.tech3_description]:
    'Collaborate now with a 1M+ <blue>Thai tech creator</blue>.',
  [InfluKeys.Banner_Cards.tech4_description]:
    'Collaborate now with a 1M+ <blue>Spanish tech creator</blue>.',
  [InfluKeys.Banner_Cards.tech5_description]:
    'Collaborate now with a 500K+ <blue>U.S. tech creator</blue>.',
  [InfluKeys.Banner_Cards.gaming1_description]:
    'Collaborate now with a 1M+ <blue>Korean gaming creator</blue>.',
  [InfluKeys.Banner_Cards.gaming2_description]:
    'Collaborate now with a 600K+ <blue>U.S. gaming creator</blue>.',
  [InfluKeys.Banner_Cards.gaming3_description]:
    'Collaborate now with a 1M+ <blue>Vietnamese gaming creator</blue>.',
  [InfluKeys.Banner_Cards.gaming4_description]:
    'Collaborate now with a 2M+ <blue>Spanish gaming creator</blue>.',
  [InfluKeys.Banner_Cards.gaming5_description]:
    'Collaborate now with a 800K+ <blue>Japanese gaming creator</blue>',
  [InfluKeys.Banner_Cards.beauty1_description]:
    'Collaborate now with a 500K+ <pink>UK beauty creator</pink>.',
  [InfluKeys.Banner_Cards.beauty2_description]:
    'Collaborate now with a 300K+ <pink>Japanese beauty creator</pink>.',
  [InfluKeys.Banner_Cards.beauty3_description]:
    'Collaborate now with a 300K+ <pink>U.S. beauty creator</pink>.',
  [InfluKeys.Banner_Cards.beauty4_description]:
    'Collaborate now with a 400K+ <pink>Korean beauty creator</pink>.',
  [InfluKeys.Banner_Cards.food1_description]:
    'Collaborate now with a 1M+ <red>U.S. food creator</red>.',
  [InfluKeys.Banner_Cards.food2_description]:
    'Collaborate now with a 1M+ <red>EU food creator</red>.',
  [InfluKeys.Banner_Cards.food3_description]:
    'Collaborate now with a 500K+ <red>Japanese food creator</red>.',
  [InfluKeys.Banner_Cards.food4_description]:
    'Collaborate now with a 200K+ <red>Korean food creator</red>.',
  [InfluKeys.Banner_Cards.food5_description]:
    'Collaborate now with a 800K+ <red>U.S. food creator</red>',
  [InfluKeys.Banner_Cards.fashion1_description]:
    'Collaborate now with a 400K+ <pink>U.S. fashion creator</pink>.',
  [InfluKeys.Banner_Cards.fashion2_description]:
    'Collaborate now with a 500K+ <pink>EU fashion creator</pink>.',
  [InfluKeys.Banner_Cards.fashion3_description]:
    'Collaborate now with a 300K+ <pink>Korean fashion creator</pink>.',
  [InfluKeys.Banner_Cards.fashion4_description]:
    'Collaborate now with a 300K+ <pink>Japanese fashion creator</pink>.',
  [InfluKeys.Banner_Cards.fashion5_description]:
    'Collaborate now with a 600K+ <pink>Dancing & Kpop creator</pink>.',
};
