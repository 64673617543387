const FooterKeys = {
  buisiness_inquiry: 'FooterKeys.bottom_text.buisiness_inquiry',
  publisher: 'FooterKeys.bottom_text.publisher',
  registry_code: 'FooterKeys.bottom_text.registry_code',
  company: 'FooterKeys.bottom_text.company',
  development_customer_support: 'FooterKeys.development_customer_support.',
  business_number: 'FooterKeys.bottom_text.business_number',
  tel: 'FooterKeys.bottom_text.contact',
  global_address: 'FooterKeys.bottom_text.global_address',
  korean_address: 'FooterKeys.bottom_text.korean_address',
  email: 'FooterKeys.bottom_text.email',
  ceo: 'FooterKeys.bottom_text.ceo',
  description: 'FooterKeys.bottom_text.description',
};

export default FooterKeys;
