import GeneralKeys from '../translationKeys/GeneralKeys';

export const GeneralTranslations = {
  [GeneralKeys.continue]: 'Tiếp tục',
  [GeneralKeys.submit]: 'Gửi',
  [GeneralKeys.cancel]: 'Hủy',
  [GeneralKeys.close]: 'Đóng',
  [GeneralKeys.return]: 'Quay lại',
  [GeneralKeys.account]: 'Tài khoản',
  [GeneralKeys.coinbase]: 'CoinBase',
  [GeneralKeys.password]: 'Mật khẩu',
  [GeneralKeys.update]: 'Cập nhật',
  [GeneralKeys.coming_soon]: 'Đang chuẩn bị',
  [GeneralKeys.all]: 'Tất cả',
  [GeneralKeys.filter]: 'Bộ lọc',
  [GeneralKeys.contents]: 'Số lượng nội dung',
  [GeneralKeys.myspace]: 'Không gian của tôi',
  [GeneralKeys.myproposal]: 'Đề xuất của tôi',
  [GeneralKeys.proposals]: 'Đề xuất',
  [GeneralKeys.billing]: 'Thanh toán',
  [GeneralKeys.settings]: 'Cài đặt',
  [GeneralKeys.profile]: 'Hồ sơ',
  [GeneralKeys.my_orders]: 'Đơn hàng của tôi',
  [GeneralKeys.orders]: 'Đơn hàng',
  [GeneralKeys.message]: 'Tin nhắn',
  [GeneralKeys.support]: 'Hỗ trợ',
  [GeneralKeys.help_center]: 'Trung tâm trợ giúp',
  [GeneralKeys.creator_faq]: 'Câu hỏi thường gặp của nhà sáng tạo',
  [GeneralKeys.company_faq]:
    'Câu hỏi thường gặp của doanh nghiệp và thương hiệu',
  [GeneralKeys.myCart]: 'Giỏ hàng của tôi',
  [GeneralKeys.platform]: 'Nền tảng',
  [GeneralKeys.sort_by]: 'Sắp xếp theo',
  [GeneralKeys.country]: 'Quốc gia',
  [GeneralKeys.categories]: 'Danh mục',
  [GeneralKeys.price]: 'Bảng giá',
  [GeneralKeys.subscribers]: 'Người đăng ký',
  [GeneralKeys.avg_views]: 'Lượt xem trung bình',
  [GeneralKeys.audience_gender]: 'Giới tính người xem',
  [GeneralKeys.audience_age]: 'Độ tuổi người xem',
  [GeneralKeys.youtube]: 'YouTube',
  [GeneralKeys.twitter]: 'X (Twitter)',
  [GeneralKeys.instagram]: 'Instagram',
  [GeneralKeys.tiktok]: 'TikTok',
  [GeneralKeys.delivery]: 'Thời gian hoàn thành sản phẩm',
  [GeneralKeys.type_length]: 'Độ dài thời lượng',
  [GeneralKeys.day_singular]: `{{number}} Ngày`,
  [GeneralKeys.day_plural]: `{{number}} Ngày`,
  [GeneralKeys.loading]: 'Đang tải',
  [GeneralKeys.company]: 'Công ty',
  [GeneralKeys.bio]: 'Tiểu sử',
  [GeneralKeys.subs]: 'Người đăng ký',
  [GeneralKeys.followers]: 'Người theo dõi',
  [GeneralKeys.videos]: 'Video',
  [GeneralKeys.x_posts]: 'X Bài đăng',
  [GeneralKeys.posts_reels]: 'Bài đăng & Reels',
  [GeneralKeys.tiktok_videos]: 'Video TikTok',
  [GeneralKeys.since]: 'Từ {{date}}',
  [GeneralKeys.countries]: 'Quốc gia',
  [GeneralKeys.reviews]: 'Đánh giá',
  [GeneralKeys.demographics]: 'Thống kê giới tính · tuổi',
  [GeneralKeys.others]: 'Khác',
  [GeneralKeys.check_plan]: 'Kiểm tra kế hoạch',
  [GeneralKeys.login]: 'Đăng nhập',
  [GeneralKeys.login_upper_case]: 'ĐĂNG NHẬP',
  [GeneralKeys.total]: 'Tổng cộng',
  [GeneralKeys.likes]: 'Thích',
  [GeneralKeys.comments]: 'Bình luận',
  [GeneralKeys.reposts]: 'X Chia sẻ lại',
  [GeneralKeys.search]: 'Tìm kiếm',
  [GeneralKeys.contact]: 'Liên hệ',
  [GeneralKeys.price_sign]: '$ --',
  [GeneralKeys.unlocking]: 'Mở khóa',
  [GeneralKeys.click_to_unlock]: 'Nhấp để mở khóa',
  [GeneralKeys.add_to_cart]: 'Thêm vào giỏ hàng',
  [GeneralKeys.show_less]: 'Hiển thị ít hơn',
  [GeneralKeys.show_more]: 'Hiển thị thêm',
  [GeneralKeys.go_back]: 'Quay lại',
  [GeneralKeys.load_more]: 'Tải thêm',
  [GeneralKeys.metamask]: 'MetaMask',
  [GeneralKeys.coinbase_wallet]: 'Ví Coinbase',
  [GeneralKeys.send]: 'Gửi',
  [GeneralKeys.email]: 'Email',
  [GeneralKeys.or_upper_case]: 'HOẶC',
  [GeneralKeys.agree]: 'Đồng ý',
  [GeneralKeys.client]: 'Thương hiệu',
  [GeneralKeys.influencer]: 'Người sáng tạo',
  [GeneralKeys.marketing]: 'Marketing',
  [GeneralKeys.creator_marketing]: 'Creator Marketing',
  [GeneralKeys.partnerships]: 'Điểm · Đối tác',
  [GeneralKeys.evo_tokens]: 'EVO Points',
  [GeneralKeys.information]: 'Thông tin',
  [GeneralKeys.website_credits]: 'Website Credits',
  [GeneralKeys.home]: 'Trang chủ',
  [GeneralKeys.about]: 'Giới thiệu',
  [GeneralKeys.pricing]: 'Gói dịch vụ',
  [GeneralKeys.proposal]: 'Đề xuất',
  [GeneralKeys.logout]: 'Đăng xuất',
  [GeneralKeys.view_more]: 'Xem thêm',
  [GeneralKeys.view_less]: 'Xem ít hơn',
  [GeneralKeys.title]: 'Tiêu đề',
  [GeneralKeys.preview]: 'Xem trước',
  [GeneralKeys.upload_a_file]: 'Tải tệp lên',
  [GeneralKeys.description]: 'Mô tả gói',
  [GeneralKeys.type]: 'Loại',
  [GeneralKeys.category]: 'Danh mục',
  [GeneralKeys.select]: 'Chọn',
  [GeneralKeys.unlimited_revisions]: 'Sửa đổi không giới hạn',
  [GeneralKeys.unlimited]: 'Không giới hạn',
  [GeneralKeys.revision]: '{{number}} Sửa đổi',
  [GeneralKeys.revisions]: '{{number}} Sửa đổi',
  [GeneralKeys.number_revision]: 'Xét duyệt lại',
  [GeneralKeys.optional]: 'Tùy chọn',
  [GeneralKeys.username]: 'Tên người dùng',
  [GeneralKeys.social_network]: 'Mạng xã hội',
  [GeneralKeys.unlink]: 'Hủy liên kết',
  [GeneralKeys.unlink_platform]: 'Hủy kết nối {{platform}}',
  [GeneralKeys.language]: 'Ngôn ngữ',
  [GeneralKeys.languages]: 'Ngôn ngữ',
  [GeneralKeys.package]: 'Gói',
  [GeneralKeys.save]: 'Lưu',
  [GeneralKeys.creator]: 'Người tạo',
  [GeneralKeys.basic]: 'Cơ bản',
  [GeneralKeys.standard]: 'cơ Bản',
  [GeneralKeys.premium]: 'tiêu Chuẩn',
  [GeneralKeys.per_day]: '/ngày',
  [GeneralKeys.per_month]: '/tháng',
  [GeneralKeys.summary]: 'Tóm tắt',
  [GeneralKeys.general]: 'Chung',
  [GeneralKeys.register]: 'Đăng ký',
  [GeneralKeys.click]: 'Nhấp',
  [GeneralKeys.connect]: 'Kết nối',
  [GeneralKeys.reconnect]: 'Kết nối lại',
  [GeneralKeys.connecting]: 'Đang kết nối',
  [GeneralKeys.notifications]: 'Thông báo',
  [GeneralKeys.messages]: 'Tin nhắn',
  [GeneralKeys.reminders]: 'Nhắc nhở',
  [GeneralKeys.newsletters]: 'Bản tin',
  [GeneralKeys.packages]: 'Gói',
  [GeneralKeys.real_time]: 'Thời gian thực',
  [GeneralKeys.unsubscribe]: 'Hủy đăng ký',
  [GeneralKeys.referral_code]: 'Mã giới thiệu',
  [GeneralKeys.total_invites]: 'Tổng số lời mời: {{number}}',
  [GeneralKeys.submitted]: 'Đã gửi',
  [GeneralKeys.here]: 'tại đây',
  [GeneralKeys.confirm]: 'Xác nhận',
  [GeneralKeys.deactivate]: 'Vô hiệu hóa',
  [GeneralKeys.delete]: 'Xóa',
  [GeneralKeys.wallet]: 'Ví',
  [GeneralKeys.yes]: 'Có',
  [GeneralKeys.privacy_policy]: 'Chính sách bảo mật',
  [GeneralKeys.payment_policy]: 'Chính sách thanh toán',
  [GeneralKeys.reactivate]: 'Kích hoạt lại',
  [GeneralKeys.analytics]: 'Phân tích',
  [GeneralKeys.user_info]: 'Thông tin người dùng',
  [GeneralKeys.enterprise]: 'premium',
  [GeneralKeys.details]: 'Chi tiết',
  [GeneralKeys.claim]: 'Nội dung',
  [GeneralKeys.in_progress]: 'Đang tiến hành',
  [GeneralKeys.free]: 'Miễn phí',
  [GeneralKeys.report]: 'Báo cáo',
  [GeneralKeys.link_copied]: 'Liên kết đã được sao chép',
  [GeneralKeys.contents_lowercase]: 'nội dung',
  [GeneralKeys.channel_lowercase]: 'kênh',
  [GeneralKeys.audience_lowercase]: 'khán giả',
  [GeneralKeys.my_url]: 'URL của tôi',
  [GeneralKeys.username_lowercase]: 'tên người dùng',
  [GeneralKeys.shorts]: 'Bản rút gọn',
  [GeneralKeys.x_post]: 'X Bài đăng',
  [GeneralKeys.repost]: 'X Bài đăng lại',
  [GeneralKeys.website]: 'Trang web của tôi',
  [GeneralKeys.banner]: 'biểu ngữ',
  [GeneralKeys.profile_lowercase]: 'hồ sơ',
  [GeneralKeys.congratulations]: 'Chúc mừng!',
  [GeneralKeys.skip]: 'bỏ qua',
  [GeneralKeys.back]: 'Quay lại',
  [GeneralKeys.shares]: 'Chia sẻ',
  [GeneralKeys.posts]: 'Bài đăng',
  [GeneralKeys.client_company]: 'Thương hiệu · doanh nghiệp',
  [GeneralKeys.contents_in]: '{{categories}} nội dung tại {{countries}}.',
  [GeneralKeys.videos_lowercase]: 'video',
  [GeneralKeys.tweets_lowercase]: 'X bài đăng',
  [GeneralKeys.posts_lowercase]: 'bài đăng',
  [GeneralKeys.rating]: 'Xếp hạng',
  [GeneralKeys.users]: 'Người dùng',
  [GeneralKeys.browse_files]: 'Duyệt tệp',
  [GeneralKeys.upload_failed]: 'Tải lên thất bại',
  [GeneralKeys.downloading]: 'Đang tải...',
  [GeneralKeys.package_summary]: 'Tóm tắt gói',
  [GeneralKeys.contents_plural]: 'nội dung của số {{number}}',
  [GeneralKeys.contents_singular]: 'nội dung của số {{number}}',
  [GeneralKeys.creator_brand_help_center]: 'TT.CSKH & FAQ',
  [GeneralKeys.main]: 'Main',
  [GeneralKeys.date]: 'Ngày',
  [GeneralKeys.service]: 'Dịch vụ',
  [GeneralKeys.order_id]: 'ID Đơn hàng',
  [GeneralKeys.transaction]: 'Giao dịch',
  [GeneralKeys.pdf]: 'PDF',
  [GeneralKeys.no_result_to_display]: 'Không có kết quả để hiển thị',
  [GeneralKeys.oldest]: 'Cũ nhất',
  [GeneralKeys.rows_10]: '10 hàng',
  [GeneralKeys.rows_50]: '50 hàng',
  [GeneralKeys.rows_100]: '100 hàng',
  [GeneralKeys.rows_500]: '500 hàng',
  [GeneralKeys.rows_1000]: '1000 hàng',
  [GeneralKeys.refresh]: 'Làm mới',

  // Category
  [GeneralKeys.Category.digital]: 'Kỹ thuật số',
  [GeneralKeys.Category.entertainment]: 'Giải trí',
  [GeneralKeys.Category.society]: 'Xã hội',
  [GeneralKeys.Category.life_style]: 'Lối sống',
  [GeneralKeys.Category.tech_science]: 'Công nghệ · Khoa học',
  [GeneralKeys.Category.video_games]: 'Trò chơi video',
  [GeneralKeys.Category.metaverse]: 'Thế giới ảo',
  [GeneralKeys.Category.crypto_nft]: 'Ứng dụng · Hướng dẫn',
  [GeneralKeys.Category.finance]: 'Tài chính',
  [GeneralKeys.Category.movies]: 'Phim ảnh',
  [GeneralKeys.Category.anime_cartoon]: 'Anime · Hoạt hình',
  [GeneralKeys.Category.music]: 'Âm nhạc',
  [GeneralKeys.Category.dance]: 'Nhảy múa',
  [GeneralKeys.Category.sports]: 'Thể thao',
  [GeneralKeys.Category.comedy]: 'Hài kịch',
  [GeneralKeys.Category.politics_society]: 'Chính trị · Xã hội',
  [GeneralKeys.Category.people_blogs]: 'Người · Blog',
  [GeneralKeys.Category.fashion]: 'Thời trang',
  [GeneralKeys.Category.beauty]: 'Làm đẹp',
  [GeneralKeys.Category.food_cooking]: 'Ẩm thực · Nấu nướng',
  [GeneralKeys.Category.youth_infant]: 'Tuổi trẻ · Trẻ sơ sinh',
  [GeneralKeys.Category.animals_pets]: 'Động vật · Vật nuôi',
  [GeneralKeys.Category.travel_event]: 'Du lịch · Sự kiện',
  [GeneralKeys.Category.auto_vehicles]: 'Ô tô · Xe máy',
  [GeneralKeys.Category.unboxing]: 'Đập hộp',
  [GeneralKeys.Category.education]: 'Giáo dục',
  [GeneralKeys.Category.art_crafts]: 'Nghệ thuật · Thủ công mỹ nghệ',
  [GeneralKeys.Category.diy_home]: 'DIY · nội thất',
  [GeneralKeys.Category.virtual_vtuber]: 'Ảo · VTuber',
  [GeneralKeys.Category.ai_automation]: 'Trí tuệ nhân tạo · Tự động hóa',
  [GeneralKeys.Category.gym_fitness]: 'Gym · Thể dục',

  [GeneralKeys.Category.tech]: 'Công nghệ',
  [GeneralKeys.Category.gaming]: 'Trò chơi',
  [GeneralKeys.Category.humor]: 'Hài hước',
  [GeneralKeys.Category.anime]: 'Anime',
  [GeneralKeys.Category.travel]: 'Du lịch',
  [GeneralKeys.Category.culture]: 'Văn hóa',
  [GeneralKeys.Category.unboxing2]: 'Đập hộp',
  [GeneralKeys.Category.beauty2]: 'Làm đẹp',
  [GeneralKeys.Category.fashion2]: 'Thời trang',
  [GeneralKeys.Category.sports2]: 'Thể thao',
  [GeneralKeys.Category.food2]: 'Ẩm thực',
  [GeneralKeys.Category.cooking2]: 'Nấu nướng',
  [GeneralKeys.Category.diy2]: 'DIY',
  [GeneralKeys.Category.home2]: 'Nhà',
  [GeneralKeys.Category.dance2]: 'Nhảy múa',
  [GeneralKeys.Category.music2]: 'Âm nhạc',

  // Country
  [GeneralKeys.Country.are]: 'Các Tiểu vương quốc Ả Rập Thống nhất',
  [GeneralKeys.Country.arg]: 'Argentina',
  [GeneralKeys.Country.aus]: 'Úc',
  [GeneralKeys.Country.aut]: 'Áo',
  [GeneralKeys.Country.bel]: 'Bỉ',
  [GeneralKeys.Country.bra]: 'Brasil',
  [GeneralKeys.Country.can]: 'Canada',
  [GeneralKeys.Country.che]: 'Thụy Sĩ',
  [GeneralKeys.Country.chn]: 'Trung Quốc',
  [GeneralKeys.Country.deu]: 'Đức',
  [GeneralKeys.Country.dnk]: 'Đan Mạch',
  [GeneralKeys.Country.eeu]: 'Châu Âu',
  [GeneralKeys.Country.esp]: 'Tây Ban Nha',
  [GeneralKeys.Country.fin]: 'Phần Lan',
  [GeneralKeys.Country.fra]: 'Pháp',
  [GeneralKeys.Country.gbr]: 'Vương quốc Anh',
  [GeneralKeys.Country.glb]: 'Toàn cầu',
  [GeneralKeys.Country.hkg]: 'Hồng Kông',
  [GeneralKeys.Country.idn]: 'Indonesia',
  [GeneralKeys.Country.ind]: 'Ấn Độ',
  [GeneralKeys.Country.irn]: 'Iran',
  [GeneralKeys.Country.isr]: 'Israel',
  [GeneralKeys.Country.ita]: 'người Ý',
  [GeneralKeys.Country.jpn]: 'Nhật Bản',
  [GeneralKeys.Country.kor]: 'Hàn Quốc',
  [GeneralKeys.Country.mex]: 'Mexico',
  [GeneralKeys.Country.mys]: 'Malaysia',
  [GeneralKeys.Country.nld]: 'Hà Lan',
  [GeneralKeys.Country.nor]: 'Na Uy',
  [GeneralKeys.Country.nzl]: 'New Zealand',
  [GeneralKeys.Country.pak]: 'Pakistan',
  [GeneralKeys.Country.phl]: 'Philippines',
  [GeneralKeys.Country.pol]: 'Ba Lan',
  [GeneralKeys.Country.prt]: 'Bồ Đào Nha',
  [GeneralKeys.Country.sau]: 'Ả Rập Xê Út',
  [GeneralKeys.Country.sgp]: 'Singapore',
  [GeneralKeys.Country.swe]: 'Thụy Điển',
  [GeneralKeys.Country.tha]: 'Thái Lan',
  [GeneralKeys.Country.tur]: 'Thổ Nhĩ Kỳ',
  [GeneralKeys.Country.twn]: 'Đài Loan',
  [GeneralKeys.Country.usa]: 'Hoa Kỳ',
  [GeneralKeys.Country.vnm]: 'Việt Nam',

  // Client
  [GeneralKeys.Country.abw]: 'Aruba',
  [GeneralKeys.Country.afg]: 'Afghanistan',
  [GeneralKeys.Country.ago]: 'Angola',
  [GeneralKeys.Country.aia]: 'Anguilla',
  [GeneralKeys.Country.ala]: 'Quần đảo Åland',
  [GeneralKeys.Country.alb]: 'Albania',
  [GeneralKeys.Country.and]: 'Andorra',
  [GeneralKeys.Country.arm]: 'Armenia',
  [GeneralKeys.Country.asm]: 'Samoa thuộc Mỹ',
  [GeneralKeys.Country.ata]: 'Nam Cực',
  [GeneralKeys.Country.atf]: 'Thuộc địa Pháp Nam Cực',
  [GeneralKeys.Country.atg]: 'Antigua và Barbuda',
  [GeneralKeys.Country.aze]: 'Azerbaijan',
  [GeneralKeys.Country.bdi]: 'Burundi',
  [GeneralKeys.Country.ben]: 'Benin',
  [GeneralKeys.Country.bes]: 'Bonaire, Sint Eustatius và Saba',
  [GeneralKeys.Country.bfa]: 'Burkina Faso',
  [GeneralKeys.Country.bgd]: 'Bangladesh',
  [GeneralKeys.Country.bgr]: 'Bulgaria',
  [GeneralKeys.Country.bhr]: 'Bahrain',
  [GeneralKeys.Country.bhs]: 'Bahamas',
  [GeneralKeys.Country.bih]: 'Bosnia và Herzegovina',
  [GeneralKeys.Country.blm]: 'Saint Barthélemy',
  [GeneralKeys.Country.blr]: 'Belarus',
  [GeneralKeys.Country.blz]: 'Belize',
  [GeneralKeys.Country.bmu]: 'Bermuda',
  [GeneralKeys.Country.bol]: 'Bolivia (Quốc gia Đa dạng Văn hóa)',
  [GeneralKeys.Country.brb]: 'Barbados',
  [GeneralKeys.Country.brn]: 'Brunei Darussalam',
  [GeneralKeys.Country.btn]: 'Bhutan',
  [GeneralKeys.Country.bvt]: 'Đảo Bouvet',
  [GeneralKeys.Country.bwa]: 'Botswana',
  [GeneralKeys.Country.caf]: 'Cộng hòa Trung Phi',
  [GeneralKeys.Country.cck]: 'Quần đảo Cocos (Keeling)',
  [GeneralKeys.Country.chl]: 'Chile',
  [GeneralKeys.Country.civ]: 'Bờ Biển Ngà',
  [GeneralKeys.Country.cmr]: 'Cameroon',
  [GeneralKeys.Country.cod]: 'Congo (Cộng hòa Dân chủ Congo)',
  [GeneralKeys.Country.cog]: 'Congo',
  [GeneralKeys.Country.cok]: 'Quần đảo Cook',
  [GeneralKeys.Country.col]: 'Colombia',
  [GeneralKeys.Country.com]: 'Comoros',
  [GeneralKeys.Country.cpv]: 'Cape Verde',
  [GeneralKeys.Country.cri]: 'Costa Rica',
  [GeneralKeys.Country.cub]: 'Cuba',
  [GeneralKeys.Country.cuw]: 'Curaçao',
  [GeneralKeys.Country.cxr]: 'Đảo Christmas',
  [GeneralKeys.Country.cym]: 'Quần đảo Cayman',
  [GeneralKeys.Country.cyp]: 'Cộng hòa Síp',
  [GeneralKeys.Country.cze]: 'Cộng hòa Séc',
  [GeneralKeys.Country.dji]: 'Djibouti',
  [GeneralKeys.Country.dma]: 'Dominica',
  [GeneralKeys.Country.dom]: 'Cộng hòa Dominica',
  [GeneralKeys.Country.dza]: 'Algeria',
  [GeneralKeys.Country.ecu]: 'Ecuador',
  [GeneralKeys.Country.egy]: 'Ai Cập',
  [GeneralKeys.Country.eri]: 'Eritrea',
  [GeneralKeys.Country.esh]: 'Tây Sahara',
  [GeneralKeys.Country.est]: 'Estonia',
  [GeneralKeys.Country.eth]: 'Ethiopia',
  [GeneralKeys.Country.fji]: 'Fiji',
  [GeneralKeys.Country.flk]: 'Quần đảo Falkland (Malvinas)',
  [GeneralKeys.Country.fro]: 'Quần đảo Faroe',
  [GeneralKeys.Country.fsm]: 'Micronesia (Các Tiểu bang Liên bang)',
  [GeneralKeys.Country.gab]: 'Gabon',
  [GeneralKeys.Country.geo]: 'Georgia',
  [GeneralKeys.Country.ggy]: 'Guernsey',
  [GeneralKeys.Country.gha]: 'Ghana',
  [GeneralKeys.Country.gib]: 'Gibraltar',
  [GeneralKeys.Country.gin]: 'Guinea',
  [GeneralKeys.Country.glp]: 'Guadeloupe',
  [GeneralKeys.Country.gmb]: 'Gambia',
  [GeneralKeys.Country.gnb]: 'Guinea-Bissau',
  [GeneralKeys.Country.gnq]: 'Guinea Xích Đạo',
  [GeneralKeys.Country.grc]: 'Hy Lạp',
  [GeneralKeys.Country.grd]: 'Grenada',
  [GeneralKeys.Country.grl]: 'Greenland',
  [GeneralKeys.Country.gtm]: 'Guatemala',
  [GeneralKeys.Country.gum]: 'Guam',
  [GeneralKeys.Country.guy]: 'Guyana',
  [GeneralKeys.Country.hmd]: 'Đảo Heard và Quần đảo McDonald',
  [GeneralKeys.Country.hnd]: 'Honduras',
  [GeneralKeys.Country.hrv]: 'Croatia',
  [GeneralKeys.Country.hti]: 'Haiti',
  [GeneralKeys.Country.hun]: 'Hungary',
  [GeneralKeys.Country.imn]: 'Đảo Man',
  [GeneralKeys.Country.iot]: 'Lãnh thổ Ấn Độ Dương thuộc Anh',
  [GeneralKeys.Country.irl]: 'Ireland',
  [GeneralKeys.Country.irq]: 'Iraq',
  [GeneralKeys.Country.isl]: 'Iceland',
  [GeneralKeys.Country.jam]: 'Jamaica',
  [GeneralKeys.Country.jey]: 'Jersey',
  [GeneralKeys.Country.jor]: 'Jordan',
  [GeneralKeys.Country.kaz]: 'Kazakhstan',
  [GeneralKeys.Country.ken]: 'Kenya',
  [GeneralKeys.Country.kgz]: 'Kyrgyzstan',
  [GeneralKeys.Country.khm]: 'Campuchia',
  [GeneralKeys.Country.kir]: 'Kiribati',
  [GeneralKeys.Country.kna]: 'Saint Kitts và Nevis',
  [GeneralKeys.Country.kwt]: 'Kuwait',
  [GeneralKeys.Country.lao]: 'Cộng hòa Dân chủ Nhân dân Lào',
  [GeneralKeys.Country.lbn]: 'Lebanon',
  [GeneralKeys.Country.lbr]: 'Liberia',
  [GeneralKeys.Country.lby]: 'Libya',
  [GeneralKeys.Country.lca]: 'Saint Lucia',
  [GeneralKeys.Country.lie]: 'Liechtenstein',
  [GeneralKeys.Country.lka]: 'Sri Lanka',
  [GeneralKeys.Country.lso]: 'Lesotho',
  [GeneralKeys.Country.ltu]: 'Litva',
  [GeneralKeys.Country.lux]: 'Luxembourg',
  [GeneralKeys.Country.lva]: 'Latvia',
  [GeneralKeys.Country.mac]: 'Macao',
  [GeneralKeys.Country.maf]: 'Saint Martin (Phần Pháp)',
  [GeneralKeys.Country.mar]: 'Morocco',
  [GeneralKeys.Country.mco]: 'Monaco',
  [GeneralKeys.Country.mda]: 'Moldova (Cộng hòa)',
  [GeneralKeys.Country.mdg]: 'Madagascar',
  [GeneralKeys.Country.mdv]: 'Maldives',
  [GeneralKeys.Country.mhl]: 'Quần đảo Marshall',
  [GeneralKeys.Country.mkd]: 'Bắc Macedonia (Cộng hòa)',
  [GeneralKeys.Country.mli]: 'Mali',
  [GeneralKeys.Country.mlt]: 'Malta',
  [GeneralKeys.Country.mmr]: 'Myanmar',
  [GeneralKeys.Country.mne]: 'Montenegro',
  [GeneralKeys.Country.mng]: 'Mông Cổ',
  [GeneralKeys.Country.mnp]: 'Quần đảo Northern Mariana',
  [GeneralKeys.Country.moz]: 'Mozambique',
  [GeneralKeys.Country.mrt]: 'Mauritania',
  [GeneralKeys.Country.msr]: 'Montserrat',
  [GeneralKeys.Country.mtq]: 'Martinique',
  [GeneralKeys.Country.mus]: 'Mauritius',
  [GeneralKeys.Country.mwi]: 'Malawi',
  [GeneralKeys.Country.myt]: 'Mayotte',
  [GeneralKeys.Country.nam]: 'Namibia',
  [GeneralKeys.Country.ncl]: 'New Caledonia',
  [GeneralKeys.Country.ner]: 'Niger',
  [GeneralKeys.Country.nfk]: 'Đảo Norfolk',
  [GeneralKeys.Country.nga]: 'Nigeria',
  [GeneralKeys.Country.nic]: 'Nicaragua',
  [GeneralKeys.Country.niu]: 'Niue',
  [GeneralKeys.Country.npl]: 'Nepal',
  [GeneralKeys.Country.nru]: 'Nauru',
  [GeneralKeys.Country.omn]: 'Oman',
  [GeneralKeys.Country.pan]: 'Panama',
  [GeneralKeys.Country.pcn]: 'Pitcairn',
  [GeneralKeys.Country.per]: 'Peru',
  [GeneralKeys.Country.plw]: 'Palau',
  [GeneralKeys.Country.png]: 'Papua New Guinea',
  [GeneralKeys.Country.pri]: 'Puerto Rico',
  [GeneralKeys.Country.prk]: 'Triều Tiên (Cộng hòa Dân chủ Nhân dân)',
  [GeneralKeys.Country.pry]: 'Paraguay',
  [GeneralKeys.Country.pse]: 'Palestine, Quốc gia của',
  [GeneralKeys.Country.qat]: 'Qatar',
  [GeneralKeys.Country.reu]: 'Réunion',
  [GeneralKeys.Country.rou]: 'Romania',
  [GeneralKeys.Country.rus]: 'Nga',
  [GeneralKeys.Country.rwa]: 'Rwanda',
  [GeneralKeys.Country.sdn]: 'Sudan',
  [GeneralKeys.Country.sen]: 'Senegal',
  [GeneralKeys.Country.sgs]: 'Nam Georgia và Quần đảo Sandwich Nam',
  [GeneralKeys.Country.shn]: 'Saint Helena, Ascension và Tristan da Cunha',
  [GeneralKeys.Country.sjm]: 'Svalbard và Jan Mayen',
  [GeneralKeys.Country.slb]: 'Quần đảo Solomon',
  [GeneralKeys.Country.sle]: 'Sierra Leone',
  [GeneralKeys.Country.slv]: 'El Salvador',
  [GeneralKeys.Country.smr]: 'San Marino',
  [GeneralKeys.Country.som]: 'Somalia',
  [GeneralKeys.Country.spm]: 'Saint Pierre và Miquelon',
  [GeneralKeys.Country.srb]: 'Serbia',
  [GeneralKeys.Country.ssd]: 'Nam Sudan',
  [GeneralKeys.Country.stp]: 'Sao Tome và Principe',
  [GeneralKeys.Country.sur]: 'Suriname',
  [GeneralKeys.Country.svk]: 'Slovakia',
  [GeneralKeys.Country.svn]: 'Slovenia',
  [GeneralKeys.Country.swz]: 'Eswatini',
  [GeneralKeys.Country.sxm]: 'Sint Maarten',
  [GeneralKeys.Country.syc]: 'Seychelles',
  [GeneralKeys.Country.syr]: 'Cộng hòa Arab Syria',
  [GeneralKeys.Country.tca]: 'Quần đảo Turks và Caicos',
  [GeneralKeys.Country.tcd]: 'Chad',
  [GeneralKeys.Country.tgo]: 'Togo',
  [GeneralKeys.Country.tjk]: 'Tajikistan',
  [GeneralKeys.Country.tkl]: 'Tokelau',
  [GeneralKeys.Country.tkm]: 'Turkmenistan',
  [GeneralKeys.Country.tls]: 'Timor-Leste',
  [GeneralKeys.Country.ton]: 'Tonga',
  [GeneralKeys.Country.tto]: 'Trinidad và Tobago',
  [GeneralKeys.Country.tun]: 'Tunisia',
  [GeneralKeys.Country.tuv]: 'Tuvalu',
  [GeneralKeys.Country.tza]: 'Tanzania, Cộng hòa Thống nhất của',
  [GeneralKeys.Country.uga]: 'Uganda',
  [GeneralKeys.Country.ukr]: 'Ukraine',
  [GeneralKeys.Country.umi]: 'Các Đảo nhỏ xa xôi của Hoa Kỳ',
  [GeneralKeys.Country.ury]: 'Uruguay',
  [GeneralKeys.Country.uzb]: 'Uzbekistan',
  [GeneralKeys.Country.vat]: 'Vatican',
  [GeneralKeys.Country.vct]: 'Saint Vincent và Grenadines',
  [GeneralKeys.Country.ven]: 'Venezuela (Cộng hòa Bolivar)',
  [GeneralKeys.Country.vgb]: 'Quần đảo Virgin (Anh)',
  [GeneralKeys.Country.vir]: 'Quần đảo Virgin (Hoa Kỳ)',
  [GeneralKeys.Country.vut]: 'Vanuatu',
  [GeneralKeys.Country.wlf]: 'Wallis và Futuna',
  [GeneralKeys.Country.wsm]: 'Samoa',
  [GeneralKeys.Country.yem]: 'Yemen',
  [GeneralKeys.Country.zaf]: 'Nam Phi',
  [GeneralKeys.Country.zmb]: 'Zambia',
  [GeneralKeys.Country.zwe]: 'Zimbabwe',

  // 2-Char
  [GeneralKeys.Country.ae]: 'Các Tiểu vương quốc Ả Rập Thống nhất',
  [GeneralKeys.Country.ar]: 'Argentina',
  [GeneralKeys.Country.au]: 'Úc',
  [GeneralKeys.Country.at]: 'Áo',
  [GeneralKeys.Country.be]: 'Bỉ',
  [GeneralKeys.Country.br]: 'Brasil',
  [GeneralKeys.Country.ca]: 'Canada',
  [GeneralKeys.Country.ch]: 'Thụy Sĩ',
  [GeneralKeys.Country.cn]: 'Trung Quốc',
  [GeneralKeys.Country.de]: 'Đức',
  [GeneralKeys.Country.dk]: 'Đan Mạch',
  [GeneralKeys.Country.eeu]: 'Châu Âu',
  [GeneralKeys.Country.es]: 'Tây Ban Nha',
  [GeneralKeys.Country.fi]: 'Phần Lan',
  [GeneralKeys.Country.fr]: 'Pháp',
  [GeneralKeys.Country.gb]: 'Vương quốc Anh',
  [GeneralKeys.Country.glb]: 'Toàn cầu',
  [GeneralKeys.Country.hk]: 'Hồng Kông',
  [GeneralKeys.Country.id]: 'Indonesia',
  [GeneralKeys.Country.in]: 'Ấn Độ',
  [GeneralKeys.Country.ir]: 'Iran',
  [GeneralKeys.Country.il]: 'Israel',
  [GeneralKeys.Country.it]: 'người Ý',
  [GeneralKeys.Country.jp]: 'Nhật Bản',
  [GeneralKeys.Country.kr]: 'Hàn Quốc',
  [GeneralKeys.Country.mx]: 'Mexico',
  [GeneralKeys.Country.my]: 'Malaysia',
  [GeneralKeys.Country.nl]: 'Hà Lan',
  [GeneralKeys.Country.no]: 'Na Uy',
  [GeneralKeys.Country.nz]: 'New Zealand',
  [GeneralKeys.Country.pk]: 'Pakistan',
  [GeneralKeys.Country.ph]: 'Philippines',
  [GeneralKeys.Country.pl]: 'Ba Lan',
  [GeneralKeys.Country.pt]: 'Bồ Đào Nha',
  [GeneralKeys.Country.sa]: 'Ả Rập Xê Út',
  [GeneralKeys.Country.sg]: 'Singapore',
  [GeneralKeys.Country.se]: 'Thụy Điển',
  [GeneralKeys.Country.th]: 'Thái Lan',
  [GeneralKeys.Country.tr]: 'Thổ Nhĩ Kỳ',
  [GeneralKeys.Country.tw]: 'Đài Loan',
  [GeneralKeys.Country.us]: 'Hoa Kỳ',
  [GeneralKeys.Country.vn]: 'Việt Nam',

  // Client
  [GeneralKeys.Country.aw]: 'Aruba',
  [GeneralKeys.Country.af]: 'Afghanistan',
  [GeneralKeys.Country.ao]: 'Angola',
  [GeneralKeys.Country.ai]: 'Anguilla',
  [GeneralKeys.Country.ax]: 'Quần đảo Åland',
  [GeneralKeys.Country.al]: 'Albania',
  [GeneralKeys.Country.ad]: 'Andorra',
  [GeneralKeys.Country.am]: 'Armenia',
  [GeneralKeys.Country.as]: 'Samoa thuộc Mỹ',
  [GeneralKeys.Country.aq]: 'Nam Cực',
  [GeneralKeys.Country.tf]: 'Thuộc địa Nam Phần của Pháp',
  [GeneralKeys.Country.ag]: 'Antigua và Barbuda',
  [GeneralKeys.Country.az]: 'Azerbaijan',
  [GeneralKeys.Country.bi]: 'Burundi',
  [GeneralKeys.Country.bj]: 'Benin',
  [GeneralKeys.Country.bq]: 'Bonaire, Sint Eustatius và Saba',
  [GeneralKeys.Country.bf]: 'Burkina Faso',
  [GeneralKeys.Country.bd]: 'Bangladesh',
  [GeneralKeys.Country.bg]: 'Bulgaria',
  [GeneralKeys.Country.bh]: 'Bahrain',
  [GeneralKeys.Country.bs]: 'Bahamas',
  [GeneralKeys.Country.ba]: 'Bosnia và Herzegovina',
  [GeneralKeys.Country.bl]: 'Saint Barthélemy',
  [GeneralKeys.Country.by]: 'Belarus',
  [GeneralKeys.Country.bz]: 'Belize',
  [GeneralKeys.Country.bm]: 'Bermuda',
  [GeneralKeys.Country.bo]: 'Bolivia (Quốc gia đa dạng dân tộc)',
  [GeneralKeys.Country.bb]: 'Barbados',
  [GeneralKeys.Country.bn]: 'Brunei Darussalam',
  [GeneralKeys.Country.bt]: 'Bhutan',
  [GeneralKeys.Country.bv]: 'Đảo Bouvet',
  [GeneralKeys.Country.bw]: 'Botswana',
  [GeneralKeys.Country.cf]: 'Cộng hòa Trung Phi',
  [GeneralKeys.Country.cc]: 'Quần đảo Cocos (Keeling)',
  [GeneralKeys.Country.cl]: 'Chile',
  [GeneralKeys.Country.ci]: 'Bờ Biển Ngà',
  [GeneralKeys.Country.cm]: 'Cameroon',
  [GeneralKeys.Country.cd]: 'Congo (Cộng hòa Dân chủ Congo)',
  [GeneralKeys.Country.cg]: 'Congo',
  [GeneralKeys.Country.ck]: 'Quần đảo Cook',
  [GeneralKeys.Country.co]: 'Colombia',
  [GeneralKeys.Country.km]: 'Comoros',
  [GeneralKeys.Country.cv]: 'Cabo Verde',
  [GeneralKeys.Country.cr]: 'Costa Rica',
  [GeneralKeys.Country.cu]: 'Cuba',
  [GeneralKeys.Country.cw]: 'Curaçao',
  [GeneralKeys.Country.cx]: 'Đảo Giáng Sinh',
  [GeneralKeys.Country.ky]: 'Quần đảo Cayman',
  [GeneralKeys.Country.cy]: 'Síp',
  [GeneralKeys.Country.cz]: 'Cộng hòa Séc',
  [GeneralKeys.Country.dj]: 'Djibouti',
  [GeneralKeys.Country.dm]: 'Dominica',
  [GeneralKeys.Country.do]: 'Cộng hòa Dominica',
  [GeneralKeys.Country.dz]: 'Algeria',
  [GeneralKeys.Country.ec]: 'Ecuador',
  [GeneralKeys.Country.eg]: 'Ai Cập',
  [GeneralKeys.Country.er]: 'Eritrea',
  [GeneralKeys.Country.eh]: 'Tây Sahara',
  [GeneralKeys.Country.ee]: 'Estonia',
  [GeneralKeys.Country.et]: 'Ethiopia',
  [GeneralKeys.Country.fj]: 'Fiji',
  [GeneralKeys.Country.fk]: 'Quần đảo Falkland (Malvinas)',
  [GeneralKeys.Country.fo]: 'Quần đảo Faroe',
  [GeneralKeys.Country.fm]: 'Micronesia (Các tiểu bang liên bang)',
  [GeneralKeys.Country.ga]: 'Gabon',
  [GeneralKeys.Country.ge]: 'Georgia',
  [GeneralKeys.Country.gg]: 'Guernsey',
  [GeneralKeys.Country.gh]: 'Ghana',
  [GeneralKeys.Country.gi]: 'Gibraltar',
  [GeneralKeys.Country.gn]: 'Guinea',
  [GeneralKeys.Country.gp]: 'Guadeloupe',
  [GeneralKeys.Country.gm]: 'Gambia',
  [GeneralKeys.Country.gw]: 'Guinea-Bissau',
  [GeneralKeys.Country.gq]: 'Guinea Xích Đạo',
  [GeneralKeys.Country.gr]: 'Hy Lạp',
  [GeneralKeys.Country.gd]: 'Grenada',
  [GeneralKeys.Country.gl]: 'Greenland',
  [GeneralKeys.Country.gt]: 'Guatemala',
  [GeneralKeys.Country.gu]: 'Guam',
  [GeneralKeys.Country.gy]: 'Guyana',
  [GeneralKeys.Country.hm]: 'Đảo Heard và Quần đảo McDonald',
  [GeneralKeys.Country.hn]: 'Honduras',
  [GeneralKeys.Country.hr]: 'Croatia',
  [GeneralKeys.Country.ht]: 'Haiti',
  [GeneralKeys.Country.hu]: 'Hungary',
  [GeneralKeys.Country.im]: 'Đảo Man',
  [GeneralKeys.Country.io]: 'Lãnh thổ Đại Tây Dương thuộc Anh',
  [GeneralKeys.Country.ie]: 'Ireland',
  [GeneralKeys.Country.iq]: 'Iraq',
  [GeneralKeys.Country.is]: 'Iceland',
  [GeneralKeys.Country.jm]: 'Jamaica',
  [GeneralKeys.Country.je]: 'Jersey',
  [GeneralKeys.Country.jo]: 'Jordan',
  [GeneralKeys.Country.kz]: 'Kazakhstan',
  [GeneralKeys.Country.ke]: 'Kenya',
  [GeneralKeys.Country.kg]: 'Kyrgyzstan',
  [GeneralKeys.Country.kh]: 'Campuchia',
  [GeneralKeys.Country.ki]: 'Kiribati',
  [GeneralKeys.Country.kn]: 'Saint Kitts và Nevis',
  [GeneralKeys.Country.kw]: 'Kuwait',
  [GeneralKeys.Country.la]: 'Cộng hòa Dân chủ Nhân dân Lào',
  [GeneralKeys.Country.lb]: 'Lebanon',
  [GeneralKeys.Country.lr]: 'Liberia',
  [GeneralKeys.Country.ly]: 'Libya',
  [GeneralKeys.Country.lc]: 'Saint Lucia',
  [GeneralKeys.Country.li]: 'Liechtenstein',
  [GeneralKeys.Country.lk]: 'Sri Lanka',
  [GeneralKeys.Country.ls]: 'Lesotho',
  [GeneralKeys.Country.lt]: 'Lithuania',
  [GeneralKeys.Country.lu]: 'Luxembourg',
  [GeneralKeys.Country.lv]: 'Latvia',
  [GeneralKeys.Country.mo]: 'Macao',
  [GeneralKeys.Country.mf]: 'Saint Martin (bộ phận thuộc Pháp)',
  [GeneralKeys.Country.ma]: 'Morocco',
  [GeneralKeys.Country.mc]: 'Monaco',
  [GeneralKeys.Country.md]: 'Moldova (Cộng hòa)',
  [GeneralKeys.Country.mg]: 'Madagascar',
  [GeneralKeys.Country.mv]: 'Maldives',
  [GeneralKeys.Country.mh]: 'Quần đảo Marshall',
  [GeneralKeys.Country.mk]: 'Bắc Macedonia (Cộng hòa)',
  [GeneralKeys.Country.ml]: 'Mali',
  [GeneralKeys.Country.mt]: 'Malta',
  [GeneralKeys.Country.mm]: 'Myanmar',
  [GeneralKeys.Country.me]: 'Montenegro',
  [GeneralKeys.Country.mn]: 'Mongolia',
  [GeneralKeys.Country.mp]: 'Quần đảo Mariana Bắc',
  [GeneralKeys.Country.mz]: 'Mozambique',
  [GeneralKeys.Country.mr]: 'Mauritania',
  [GeneralKeys.Country.ms]: 'Montserrat',
  [GeneralKeys.Country.mq]: 'Martinique',
  [GeneralKeys.Country.mu]: 'Mauritius',
  [GeneralKeys.Country.mw]: 'Malawi',
  [GeneralKeys.Country.yt]: 'Mayotte',
  [GeneralKeys.Country.na]: 'Namibia',
  [GeneralKeys.Country.nc]: 'New Caledonia',
  [GeneralKeys.Country.ne]: 'Niger',
  [GeneralKeys.Country.nf]: 'Đảo Norfolk',
  [GeneralKeys.Country.ng]: 'Nigeria',
  [GeneralKeys.Country.ni]: 'Nicaragua',
  [GeneralKeys.Country.nu]: 'Niue',
  [GeneralKeys.Country.np]: 'Nepal',
  [GeneralKeys.Country.nr]: 'Nauru',
  [GeneralKeys.Country.om]: 'Oman',
  [GeneralKeys.Country.pa]: 'Panama',
  [GeneralKeys.Country.pn]: 'Quần đảo Pitcairn',
  [GeneralKeys.Country.pe]: 'Peru',
  [GeneralKeys.Country.pw]: 'Palau',
  [GeneralKeys.Country.pg]: 'Papua New Guinea',
  [GeneralKeys.Country.pr]: 'Puerto Rico',
  [GeneralKeys.Country.kp]: 'Triều Tiên (Cộng hòa Dân chủ Nhân dân)',
  [GeneralKeys.Country.py]: 'Paraguay',
  [GeneralKeys.Country.ps]: 'Palestine, Tiểu vương quốc Ả Rập',
  [GeneralKeys.Country.qa]: 'Qatar',
  [GeneralKeys.Country.re]: 'Réunion',
  [GeneralKeys.Country.ro]: 'Romania',
  [GeneralKeys.Country.ru]: 'Nga',
  [GeneralKeys.Country.rw]: 'Rwanda',
  [GeneralKeys.Country.sd]: 'Sudan',
  [GeneralKeys.Country.sn]: 'Senegal',
  [GeneralKeys.Country.gs]: 'Quần đảo Nam Georgia và Nam Sandwich',
  [GeneralKeys.Country.sh]: 'Saint Helena, Ascension và Tristan da Cunha',
  [GeneralKeys.Country.sj]: 'Svalbard và Jan Mayen',
  [GeneralKeys.Country.sb]: 'Quần đảo Solomon',
  [GeneralKeys.Country.sl]: 'Sierra Leone',
  [GeneralKeys.Country.sv]: 'El Salvador',
  [GeneralKeys.Country.sm]: 'San Marino',
  [GeneralKeys.Country.so]: 'Somalia',
  [GeneralKeys.Country.pm]: 'Saint Pierre và Miquelon',
  [GeneralKeys.Country.rs]: 'Serbia',
  [GeneralKeys.Country.ss]: 'Nam Sudan',
  [GeneralKeys.Country.st]: 'São Tomé và Príncipe',
  [GeneralKeys.Country.sr]: 'Suriname',
  [GeneralKeys.Country.sk]: 'Slovakia',
  [GeneralKeys.Country.si]: 'Slovenia',
  [GeneralKeys.Country.sz]: 'Eswatini',
  [GeneralKeys.Country.sx]: 'Sint Maarten',
  [GeneralKeys.Country.sc]: 'Seychelles',
  [GeneralKeys.Country.sy]: 'Cộng hòa Arab Syria',
  [GeneralKeys.Country.tc]: 'Quần đảo Turks và Caicos',
  [GeneralKeys.Country.td]: 'Chad',
  [GeneralKeys.Country.tg]: 'Togo',
  [GeneralKeys.Country.tj]: 'Tajikistan',
  [GeneralKeys.Country.tk]: 'Tokelau',
  [GeneralKeys.Country.tm]: 'Turkmenistan',
  [GeneralKeys.Country.tl]: 'Timor-Leste',
  [GeneralKeys.Country.to]: 'Tonga',
  [GeneralKeys.Country.tt]: 'Trinidad và Tobago',
  [GeneralKeys.Country.tn]: 'Tunisia',
  [GeneralKeys.Country.tv]: 'Tuvalu',
  [GeneralKeys.Country.tz]: 'Tanzania, Liên bang Tanzania',
  [GeneralKeys.Country.ug]: 'Uganda',
  [GeneralKeys.Country.ua]: 'Ukraine',
  [GeneralKeys.Country.um]: 'Quần đảo nhỏ xa của Hoa Kỳ',
  [GeneralKeys.Country.uy]: 'Uruguay',
  [GeneralKeys.Country.uz]: 'Uzbekistan',
  [GeneralKeys.Country.va]: 'Vatican',
  [GeneralKeys.Country.vc]: 'Saint Vincent và Grenadines',
  [GeneralKeys.Country.ve]: 'Venezuela (Cộng hòa Bolivar)',
  [GeneralKeys.Country.vg]: 'Quần đảo Virgin (Anh)',
  [GeneralKeys.Country.vi]: 'Quần đảo Virgin (Hoa Kỳ)',
  [GeneralKeys.Country.vu]: 'Vanuatu',
  [GeneralKeys.Country.wf]: 'Wallis và Futuna',
  [GeneralKeys.Country.ws]: 'Samoa',
  [GeneralKeys.Country.ye]: 'Yemen',
  [GeneralKeys.Country.za]: 'Nam Phi',
  [GeneralKeys.Country.zm]: 'Zambia',
  [GeneralKeys.Country.zw]: 'Zimbabwe',
};
