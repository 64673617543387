const GeneralKeys = {
  continue: 'GeneralKeys.continue',
  submit: 'GeneralKeys.submit',
  cancel: 'GeneralKeys.cancel',
  close: 'GeneralKeys.close',
  return: 'GeneralKeys.return',
  account: 'GeneralKeys.account',
  coinbase: 'GeneralKeys.coinBase',
  password: 'GeneralKeys.password',
  update: 'GeneralKeys.update',
  coming_soon: 'GeneralKeys.coming_soon',
  all: 'GeneralKeys.all',
  filter: 'GeneralKeys.filter',
  contents: 'GeneralKeys.contents',
  myspace: 'GeneralKeys.myspace',
  myproposal: 'GeneralKeys.myproposal',
  proposals: 'GeneralKeys.proposals',
  billing: 'GeneralKeys.billing',
  settings: 'GeneralKeys.settings',
  profile: 'GeneralKeys.profile',
  my_orders: 'GeneralKeys.my_orders',
  orders: 'GeneralKeys.orders',
  message: 'GeneralKeys.message',
  support: 'GeneralKeys.support',
  help_center: 'GeneralKeys.help_center',
  creator_faq: 'GeneralKeys.creator_faq',
  company_faq: 'GeneralKeys.company_faq',
  myCart: 'GeneralKeys.myCart',
  platform: 'GeneralKeys.platform',
  sort_by: 'GeneralKeys.sort_by',
  country: 'GeneralKeys.country',
  categories: 'GeneralKeys.categories',
  price: 'GeneralKeys.price',
  subscribers: 'GeneralKeys.subscribers',
  avg_views: 'GeneralKeys.avg_views',
  audience_gender: 'GeneralKeys.audience_gender',
  audience_age: 'GeneralKeys.audience_age',
  youtube: 'GeneralKeys.youtube',
  twitter: 'GeneralKeys.twitter',
  instagram: 'GeneralKeys.instagram',
  tiktok: 'GeneralKeys.tiktok',
  delivery: 'GeneralKeys.delivery',
  type_length: 'GeneralKeys.type_length',
  day_singular: 'GeneralKeys.day_singular',
  day_plural: 'GeneralKeys.day_plural',
  loading: 'GeneralKeys.loading',
  company: 'GeneralKeys.company',
  bio: 'GeneralKeys.bio',
  subs: 'GeneralKeys.subs',
  followers: 'GeneralKeys.followers',
  videos: 'GeneralKeys.videos',
  x_posts: 'GeneralKeys.x_posts',
  posts_reels: 'GeneralKeys.posts_reels',
  tiktok_videos: 'GeneralKeys.tiktok_videos',
  since: 'GeneralKeys.since',
  countries: 'GeneralKeys.countries',
  reviews: 'reviews',
  demographics: 'GeneralKeys.demographics',
  others: 'GeneralKeys.others',
  check_plan: 'GeneralKeys.check_plan',
  login: 'GeneralKeys.login',
  login_upper_case: 'GeneralKeys.login_upper_case',
  total: 'GeneralKeys.total',
  likes: 'GeneralKeys.likes',
  comments: 'GeneralKeys.comments',
  reposts: 'GeneralKeys.reposts',
  search: 'GeneralKeys.search',
  contact: 'GeneralKeys.contact',
  price_sign: 'GeneralKeys.price_sign',
  unlocking: 'GeneralKeys.unlocking',
  click_to_unlock: 'GeneralKeys.click_to_unlock',
  add_to_cart: 'GeneralKeys.add_to_cart',
  show_less: 'GeneralKeys.show_less',
  show_more: 'GeneralKeys.show_more',
  go_back: 'GeneralKeys.go_back',
  load_more: 'GeneralKeys.load_more',
  metamask: 'GeneralKeys.metamask',
  coinbase_wallet: 'GeneralKeys.coinbase_wallet',
  send: 'GeneralKeys.send',
  email: 'GeneralKeys.email',
  or_upper_case: 'GeneralKeys.or_upper_case',
  agree: 'GeneralKeys.agree',
  client: 'GeneralKeys.client',
  influencer: 'GeneralKeys.influencer',
  marketing: 'GeneralKeys.marketing',
  creator_marketing: 'GeneralKeys.creator_marketing',
  partnerships: 'GeneralKeys.partnerships',
  evo_tokens: 'GeneralKeys.evo_tokens',
  information: 'GeneralKeys.information',
  website_credits: 'GeneralKeys.website_credits',
  home: 'GeneralKeys.home',
  about: 'GeneralKeys.about',
  pricing: 'GeneralKeys.pricing',
  proposal: 'GeneralKeys.proposal',
  logout: 'GeneralKeys.logout',
  view_more: 'GeneralKeys.view_more',
  view_less: 'GeneralKeys.view_less',
  title: 'GeneralKeys.title',
  preview: 'GeneralKeys.preview',
  upload_a_file: 'GeneralKeys.upload_a_file',
  description: 'GeneralKeys.description',
  type: 'GeneralKeys.type',
  category: 'GeneralKeys.category',
  select: 'GeneralKeys.select',
  unlimited_revisions: 'GeneralKeys.unlimited_revisions',
  unlimited: 'GeneralKeys.unlimited',
  revision: 'GeneralKeys.revision',
  revisions: 'GeneralKeys.revisions',
  number_revision: 'GeneralKeys.number_revision',
  optional: 'GeneralKeys.optional',
  username: 'GeneralKeys.username',
  social_network: 'GeneralKeys.social_network',
  unlink: 'GeneralKeys.unlink',
  unlink_platform: 'GeneralKeys.unlink_platform',
  language: 'GeneralKeys.language',
  languages: 'GeneralKeys.languages',
  package: 'GeneralKeys.package',
  save: 'GeneralKeys.save',
  creator: 'GeneralKeys.creator',
  basic: 'GeneralKeys.basic',
  standard: 'standard',
  premium: 'premium',
  per_day: 'GeneralKeys.per_day',
  per_month: 'GeneralKeys.per_month',
  summary: 'GeneralKeys.summary',
  general: 'GeneralKeys.general',
  register: 'GeneralKeys.register',
  click: 'GeneralKeys.click',
  connect: 'GeneralKeys.connect',
  reconnect: 'GeneralKeys.reconnect',
  connecting: 'GeneralKeys.connecting',
  notifications: 'GeneralKeys.notifications',
  messages: 'GeneralKeys.messages',
  reminders: 'GeneralKeys.reminders',
  newsletters: 'GeneralKeys.newsletters',
  packages: 'packages',
  real_time: 'GeneralKeys.real_time',
  unsubscribe: 'GeneralKeys.unsubscribe',
  referral_code: 'GeneralKeys.referral_code',
  total_invites: 'GeneralKeys.total_invites',
  submitted: 'GeneralKeys.submitted',
  here: 'GeneralKeys.here',
  confirm: 'GeneralKeys.confirm',
  deactivate: 'GeneralKeys.deactivate',
  delete: 'GeneralKeys.delete',
  wallet: 'GeneralKeys.wallet',
  yes: 'GeneralKeys.yes',
  privacy_policy: 'GeneralKeys.privacy_policy',
  payment_policy: 'GeneralKeys.payment_policy',
  reactivate: 'GeneralKeys.reactivate',
  analytics: 'analytics',
  user_info: 'user_info',
  enterprise: 'enterprise',
  details: 'GeneralKeys.details',
  claim: 'GeneralKeys.claim',
  in_progress: 'GeneralKeys.in_progress',
  free: 'GeneralKeys.free',
  report: 'GeneralKeys.report',
  link_copied: 'GeneralKeys.link_copied',
  contents_lowercase: 'GeneralKeys.contents_lowercase',
  channel_lowercase: 'GeneralKeys.channel_lowercase',
  audience_lowercase: 'GeneralKeys.audience_lowercase',
  my_url: 'GeneralKeys.my_url',
  username_lowercase: 'GeneralKeys.username_lowercase',
  shorts: 'GeneralKeys.shorts',
  x_post: 'GeneralKeys.x_post',
  repost: 'GeneralKeys.repost',
  website: 'GeneralKeys.website',
  banner: 'GeneralKeys.banner',
  profile_lowercase: 'GeneralKeys.profile_lowercase',
  congratulations: 'GeneralKeys.congratulations',
  skip: 'GeneralKeys.skip',
  back: 'GeneralKeys.back',
  shares: 'GeneralKeys.shares',
  posts: 'GeneralKeys.posts',
  client_company: 'GeneralKeys.client_company',
  contents_in: 'contents_in',
  videos_lowercase: 'GeneralKeys.videos_lowercase',
  tweets_lowercase: 'GeneralKeys.tweets_lowercase',
  posts_lowercase: 'GeneralKeys.posts_lowercase',
  rating: 'GeneralKeys.rating',
  users: 'GeneralKeys.users',
  browse_files: 'GeneralKeys.browse_files',
  upload_failed: 'GeneralKeys.upload_failed',
  downloading: 'GeneralKeys.downloading',
  package_summary: 'GeneralKeys.package_summary',
  contents_plural: 'GeneralKeys.contents_plural',
  contents_singular: 'GeneralKeys.contents_singular',
  creator_brand_help_center: 'GeneralKeys.creator_brand_help_center',
  main: 'GeneralKeys.main',
  date: 'GeneralKeys.date',
  service: 'GeneralKeys.service',
  order_id: 'GeneralKeys.order_id',
  transaction: 'GeneralKeys.transaction',
  pdf: 'GeneralKeys.pdf',
  no_result_to_display: 'GeneralKeys.no_result_to_display',
  oldest: 'GeneralKeys.oldest',
  rows_10: 'GeneralKeys.rows_10',
  rows_50: 'GeneralKeys.rows_50',
  rows_100: 'GeneralKeys.rows_100',
  rows_500: 'GeneralKeys.rows_500',
  rows_1000: 'GeneralKeys.rows_1000',
  refresh: 'GeneralKeys.refresh',

  Category: {
    digital: 'Digital',
    entertainment: 'Entertainment',
    society: 'Society',
    life_style: 'Life style',

    tech_science: 'tech_science',
    video_games: 'video_games',
    metaverse: 'metaverse',
    crypto_nft: 'crypto_nft',
    finance: 'finance',
    movies: 'movies',
    anime_cartoon: 'anime_cartoon',
    music: 'music',
    dance: 'dance',
    sports: 'sports',
    comedy: 'comedy',
    politics_society: 'politics_society',
    people_blogs: 'people_blogs',
    fashion: 'fashion',
    beauty: 'beauty',
    food_cooking: 'food_cooking',
    youth_infant: 'youth_infant',
    animals_pets: 'animals_pets',
    travel_event: 'travel_event',
    auto_vehicles: 'auto_vehicles',
    unboxing: 'unboxing',
    education: 'education',
    art_crafts: 'art_crafts',
    diy_home: 'diy_home',
    virtual_vtuber: 'virtual_vtuber',
    ai_automation: 'ai_automation',
    gym_fitness: 'gym_fitness',

    tech: 'GeneralKeys.Category.tech',
    gaming: 'GeneralKeys.Category.gaming',
    humor: 'GeneralKeys.Category.humor',
    anime: 'GeneralKeys.Category.anime',
    travel: 'GeneralKeys.Category.travel',
    culture: 'GeneralKeys.Category.culture',
    unboxing2: 'GeneralKeys.Category.unboxing2',
    beauty2: 'GeneralKeys.Category.beauty2',
    fashion2: 'GeneralKeys.Category.fashion2',
    sports2: 'GeneralKeys.Category.sports2',
    food2: 'GeneralKeys.Category.food2',
    cooking2: 'GeneralKeys.Category.cooking2',
    diy2: 'GeneralKeys.Category.diy2',
    home2: 'GeneralKeys.Category.home2',
    dance2: 'GeneralKeys.Category.dance2',
    music2: 'GeneralKeys.Category.music2',
  },

  Country: {
    are: 'are',
    arg: 'arg',
    aus: 'aus',
    aut: 'aut',
    bel: 'bel',
    bra: 'bra',
    can: 'can',
    che: 'che',
    chn: 'chn',
    deu: 'deu',
    dnk: 'dnk',
    eeu: 'eeu',
    esp: 'esp',
    fin: 'fin',
    fra: 'fra',
    gbr: 'gbr',
    glb: 'glb',
    hkg: 'hkg',
    idn: 'idn',
    ind: 'ind',
    irn: 'irn',
    isr: 'isr',
    ita: 'ita',
    jpn: 'jpn',
    kor: 'kor',
    mex: 'mex',
    mys: 'mys',
    nld: 'nld',
    nor: 'nor',
    nzl: 'nzl',
    pak: 'pak',
    phl: 'phl',
    pol: 'pol',
    prt: 'prt',
    sau: 'sau',
    sgp: 'sgp',
    swe: 'swe',
    tha: 'tha',
    tur: 'tur',
    twn: 'twn',
    usa: 'usa',
    vnm: 'vnm',

    // Client
    abw: 'abw',
    afg: 'afg',
    ago: 'ago',
    aia: 'aia',
    ala: 'ala',
    alb: 'alb',
    and: 'and',
    arm: 'arm',
    asm: 'asm',
    ata: 'ata',
    atf: 'atf',
    atg: 'atg',
    aze: 'aze',
    bdi: 'bdi',
    ben: 'ben',
    bes: 'bes',
    bfa: 'bfa',
    bgd: 'bgd',
    bgr: 'bgr',
    bhr: 'bhr',
    bhs: 'bhs',
    bih: 'bih',
    blm: 'blm',
    blr: 'blr',
    blz: 'blz',
    bmu: 'bmu',
    bol: 'bol',
    brb: 'brb',
    brn: 'brn',
    btn: 'btn',
    bvt: 'bvt',
    bwa: 'bwa',
    caf: 'caf',
    cck: 'cck',
    chl: 'chl',
    civ: 'civ',
    cmr: 'cmr',
    cod: 'cod',
    cog: 'cog',
    cok: 'cok',
    col: 'col',
    com: 'com',
    cpv: 'cpv',
    cri: 'cri',
    cub: 'cub',
    cuw: 'cuw',
    cxr: 'cxr',
    cym: 'cym',
    cyp: 'cyp',
    cze: 'cze',
    dji: 'dji',
    dma: 'dma',
    dom: 'dom',
    dza: 'dza',
    ecu: 'ecu',
    egy: 'egy',
    eri: 'eri',
    esh: 'esh',
    est: 'est',
    eth: 'eth',
    fji: 'fji',
    flk: 'flk',
    fro: 'fro',
    fsm: 'fsm',
    gab: 'gab',
    geo: 'geo',
    ggy: 'ggy',
    gha: 'gha',
    gib: 'gib',
    gin: 'gin',
    glp: 'glp',
    gmb: 'gmb',
    gnb: 'gnb',
    gnq: 'gnq',
    grc: 'grc',
    grd: 'grd',
    grl: 'grl',
    gtm: 'gtm',
    guf: 'guf',
    gum: 'gum',
    guy: 'guy',
    hmd: 'hmd',
    hnd: 'hnd',
    hrv: 'hrv',
    hti: 'hti',
    hun: 'hun',
    imn: 'imn',
    iot: 'iot',
    irl: 'irl',
    irq: 'irq',
    isl: 'isl',
    jam: 'jam',
    jey: 'jey',
    jor: 'jor',
    kaz: 'kaz',
    ken: 'ken',
    kgz: 'kgz',
    khm: 'khm',
    kir: 'kir',
    kna: 'kna',
    kwt: 'kwt',
    lao: 'lao',
    lbn: 'lbn',
    lbr: 'lbr',
    lby: 'lby',
    lca: 'lca',
    lie: 'lie',
    lka: 'lka',
    lso: 'lso',
    ltu: 'ltu',
    lux: 'lux',
    lva: 'lva',
    mac: 'mac',
    maf: 'maf',
    mar: 'mar',
    mco: 'mco',
    mda: 'mda',
    mdg: 'mdg',
    mdv: 'mdv',
    mhl: 'mhl',
    mkd: 'mkd',
    mli: 'mli',
    mlt: 'mlt',
    mmr: 'mmr',
    mne: 'mne',
    mng: 'mng',
    mnp: 'mnp',
    moz: 'moz',
    mrt: 'mrt',
    msr: 'msr',
    mtq: 'mtq',
    mus: 'mus',
    mwi: 'mwi',
    myt: 'myt',
    nam: 'nam',
    ncl: 'ncl',
    ner: 'ner',
    nfk: 'nfk',
    nga: 'nga',
    nic: 'nic',
    niu: 'niu',
    npl: 'npl',
    nru: 'nru',
    omn: 'omn',
    pan: 'pan',
    pcn: 'pcn',
    per: 'per',
    plw: 'plw',
    png: 'png',
    pri: 'pri',
    prk: 'prk',
    pry: 'pry',
    pse: 'pse',
    pyf: 'pyf',
    qat: 'qat',
    reu: 'reu',
    rou: 'rou',
    rus: 'rus',
    rwa: 'rwa',
    sdn: 'sdn',
    sen: 'sen',
    sgs: 'sgs',
    shn: 'shn',
    sjm: 'sjm',
    slb: 'slb',
    sle: 'sle',
    slv: 'slv',
    smr: 'smr',
    som: 'som',
    spm: 'spm',
    srb: 'srb',
    ssd: 'ssd',
    stp: 'stp',
    sur: 'sur',
    svk: 'svk',
    svn: 'svn',
    swz: 'swz',
    sxm: 'sxm',
    syc: 'syc',
    syr: 'syr',
    tca: 'tca',
    tcd: 'tcd',
    tgo: 'tgo',
    tjk: 'tjk',
    tkl: 'tkl',
    tkm: 'tkm',
    tls: 'tls',
    ton: 'ton',
    tto: 'tto',
    tun: 'tun',
    tuv: 'tuv',
    tza: 'tza',
    uga: 'uga',
    ukr: 'ukr',
    umi: 'umi',
    ury: 'ury',
    uzb: 'uzb',
    vat: 'vat',
    vct: 'vct',
    ven: 'ven',
    vgb: 'vgb',
    vir: 'vir',
    vut: 'vut',
    wlf: 'wlf',
    wsm: 'wsm',
    yem: 'yem',
    zaf: 'zaf',
    zmb: 'zmb',
    zwe: 'zwe',

    // 2-Char
    ae: 'ae',
    ar: 'ar',
    au: 'au',
    at: 'at',
    be: 'be',
    br: 'br',
    ca: 'ca',
    ch: 'ch',
    cn: 'cn',
    de: 'de',
    dk: 'dk',
    es: 'es',
    fi: 'fi',
    fr: 'fr',
    gb: 'gb',
    hk: 'hk',
    id: 'id',
    in: 'in',
    ir: 'ir',
    il: 'il',
    it: 'it',
    jp: 'jp',
    kr: 'kr',
    mx: 'mx',
    my: 'my',
    nl: 'nl',
    no: 'no',
    nz: 'nz',
    pk: 'pk',
    ph: 'ph',
    pl: 'pl',
    pt: 'pt',
    sa: 'sa',
    sg: 'sg',
    se: 'se',
    th: 'th',
    tr: 'tr',
    tw: 'tw',
    us: 'us',
    vn: 'vn',

    // Client
    aw: 'aw',
    af: 'af',
    ao: 'ao',
    ai: 'ai',
    ax: 'ax',
    al: 'al',
    ad: 'ad',
    am: 'am',
    as: 'as',
    aq: 'aq',
    tf: 'tf',
    ag: 'ag',
    az: 'az',
    bi: 'bi',
    bj: 'bj',
    bq: 'bq',
    bf: 'bf',
    bd: 'bd',
    bg: 'bg',
    bh: 'bh',
    bs: 'bs',
    ba: 'ba',
    bl: 'bl',
    by: 'by',
    bz: 'bz',
    bm: 'bm',
    bo: 'bo',
    bb: 'bb',
    bn: 'bn',
    bt: 'bt',
    bv: 'bv',
    bw: 'bw',
    cf: 'cf',
    cc: 'cc',
    cl: 'cl',
    ci: 'ci',
    cm: 'cm',
    cd: 'cd',
    cg: 'cg',
    ck: 'ck',
    co: 'co',
    km: 'km',
    cv: 'cv',
    cr: 'cr',
    cu: 'cu',
    cw: 'cw',
    cx: 'cx',
    ky: 'ky',
    cy: 'cy',
    cz: 'cz',
    dj: 'dj',
    dm: 'dm',
    do: 'do',
    dz: 'dz',
    ec: 'ec',
    eg: 'eg',
    er: 'er',
    eh: 'eh',
    ee: 'ee',
    et: 'et',
    fj: 'fj',
    fk: 'fk',
    fo: 'fo',
    fm: 'fm',
    ga: 'ga',
    ge: 'ge',
    gg: 'gg',
    gh: 'gh',
    gi: 'gi',
    gn: 'gn',
    gp: 'gp',
    gm: 'gm',
    gw: 'gw',
    gq: 'gq',
    gr: 'gr',
    gd: 'gd',
    gl: 'gl',
    gt: 'gt',
    gf: 'gf',
    gu: 'gu',
    gy: 'gy',
    hm: 'hm',
    hn: 'hn',
    hr: 'hr',
    ht: 'ht',
    hu: 'hu',
    im: 'im',
    io: 'io',
    ie: 'ie',
    iq: 'iq',
    is: 'is',
    jm: 'jm',
    je: 'je',
    jo: 'jo',
    kz: 'kz',
    ke: 'ke',
    kg: 'kg',
    kh: 'kh',
    ki: 'ki',
    kn: 'kn',
    kw: 'kw',
    la: 'la',
    lb: 'lb',
    lr: 'lr',
    ly: 'ly',
    lc: 'lc',
    li: 'li',
    lk: 'lk',
    ls: 'ls',
    lt: 'lt',
    lu: 'lu',
    lv: 'lv',
    mo: 'mo',
    mf: 'mf',
    ma: 'ma',
    mc: 'mc',
    md: 'md',
    mg: 'mg',
    mv: 'mv',
    mh: 'mh',
    mk: 'mk',
    ml: 'ml',
    mt: 'mt',
    mm: 'mm',
    me: 'me',
    mn: 'mn',
    mp: 'mp',
    mz: 'mz',
    mr: 'mr',
    ms: 'ms',
    mq: 'mq',
    mu: 'mu',
    mw: 'mw',
    yt: 'yt',
    na: 'na',
    nc: 'nc',
    ne: 'ne',
    nf: 'nf',
    ng: 'ng',
    ni: 'ni',
    nu: 'nu',
    np: 'np',
    nr: 'nr',
    om: 'om',
    pa: 'pa',
    pn: 'pn',
    pe: 'pe',
    pw: 'pw',
    pg: 'pg',
    pr: 'pr',
    kp: 'kp',
    py: 'py',
    ps: 'ps',
    pf: 'pf',
    qa: 'qa',
    re: 're',
    ro: 'ro',
    ru: 'ru',
    rw: 'rw',
    sd: 'sd',
    sn: 'sn',
    gs: 'gs',
    sh: 'sh',
    sj: 'sj',
    sb: 'sb',
    sl: 'sl',
    sv: 'sv',
    sm: 'sm',
    so: 'so',
    pm: 'pm',
    rs: 'rs',
    ss: 'ss',
    st: 'st',
    sr: 'sr',
    sk: 'sk',
    si: 'si',
    sz: 'sz',
    sx: 'sx',
    sc: 'sc',
    sy: 'sy',
    tc: 'tc',
    td: 'td',
    tg: 'tg',
    tj: 'tj',
    tk: 'tk',
    tm: 'tm',
    tl: 'tl',
    to: 'to',
    tt: 'tt',
    tn: 'tn',
    tv: 'tv',
    tz: 'tz',
    ug: 'ug',
    ua: 'ua',
    um: 'um',
    uy: 'uy',
    uz: 'uz',
    va: 'va',
    vc: 'vc',
    ve: 've',
    vg: 'vg',
    vi: 'vi',
    vu: 'vu',
    wf: 'wf',
    ws: 'ws',
    ye: 'ye',
    za: 'za',
    zm: 'zm',
    zw: 'zw',
  },
};

export default GeneralKeys;
