import CreatorRegistrationKeys from '../translationKeys/CreatorRegistrationPageKeys';

export const CreatorRegistrations = {
  // Complete Registration
  [CreatorRegistrationKeys.complete_registration]: '完成注册',
  [CreatorRegistrationKeys.success_registration]: '注册成功！',
  [CreatorRegistrationKeys.evo_collected]: '已收集EVO！',
  [CreatorRegistrationKeys.click_me]: '点击我！',
  [CreatorRegistrationKeys.touch_me]: '触摸我',
  [CreatorRegistrationKeys._10_times]: '10次！',

  // Topbar
  [CreatorRegistrationKeys.Topbar.title]: '注册成为创作者',
  [CreatorRegistrationKeys.Topbar.time]: '预计1分钟',
  [CreatorRegistrationKeys.Topbar.explain]: '请为每个部分选择一个或多个选项',

  // Social Network
  [CreatorRegistrationKeys.Social_Network.tooltip]: `
  请连接至少 <br>
  500个订阅者的账户 <br>
  以注册成为创作者。`,

  [CreatorRegistrationKeys.Social_Network.popup_error]:
    '请先在浏览器中允许弹出窗口以连接平台',
  [CreatorRegistrationKeys.Social_Network.server_error_tooltip]:
    '由于 Facebook 服务器错误，<br/>连接暂时不可用。请稍后再试。',

  // Error
  [CreatorRegistrationKeys.Error.social_network]: '请连接社交账户',

  [CreatorRegistrationKeys.Error.handle_char_limit]: '输入3~30个字符',
  [CreatorRegistrationKeys.Error.handle_invalid]: '检测到无效符号或空格',
  [CreatorRegistrationKeys.Error.handle_taken]: 'URL已被占用',
  [CreatorRegistrationKeys.Error.handle_required]: '请输入网址',

  [CreatorRegistrationKeys.Error.category]: '请选择一个类别',
  [CreatorRegistrationKeys.Error.invalid_category]: '无效的类别',

  [CreatorRegistrationKeys.Error.target_country]: '请选择一个国家',
  [CreatorRegistrationKeys.Error.invalid_country]: '无效的国家',

  [CreatorRegistrationKeys.Error.language]: '请选择语言',
  [CreatorRegistrationKeys.Error.invalid_language]: '无效的语言',

  // Modal
  // Already Creator
  [CreatorRegistrationKeys.Modal.already_creator.title]: '您已经注册为创作者',
  [CreatorRegistrationKeys.Modal.already_creator.description]: `
  您只能注册一次成为创作者。 <br>
  请联系支持获取更多信息。`,

  [CreatorRegistrationKeys.Modal.already_company.title]: '您已注册为品牌或公司',
  [CreatorRegistrationKeys.Modal.already_company.description]:
    '您只能作为创作者或品牌进行验证。如果您希望以创作者身份注册，请使用新账户。',

  // Registration Fail
  [CreatorRegistrationKeys.Modal.Registration_Fail.title]: '注册失败',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l1]:
    '以下账户的粉丝或订阅者数少于500。',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description.minimum_subs_l2]:
    '您连接的账户需要至少有500个粉丝或订阅者才能继续。',

  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l1]: '以下账户已经被其他创作者验证并使用。',
  [CreatorRegistrationKeys.Modal.Registration_Fail.Description
    .already_verified_l2]: '请取消关联该账户并重新尝试。',

  // Verification Fail
  [CreatorRegistrationKeys.Modal.Verification_Fail.title]: '验证失败',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .need_public_posts]: `
      该账号至少需要5篇公开帖子才能进行验证。 请在上传更多帖子后再次尝试。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.no_connected]: `
    此账户没有连接到YouTube频道。 请连接或创建一个YouTube频道并重新尝试。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_to_use]:
    '为了使用我们的服务，您必须接受所有的Facebook访问选项（范围）。这些选项对我们平台的核心服务至关重要，未接受将导致功能受限和访问受限。请通过接受所有选项重新连接您的账号。',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .to_use_our_service]:
    '要使用我们的服务，您必须接受所有YouTube/Instagram/TikTok/X权限。这些权限对于我们平台的核心服务至关重要，不接受它们将导致功能受限和服务访问受限。请重新连接您的账户并接受所有权限。',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.insta_different]:
    '您选择了与当前连接的Instagram账户不同的账户。请尝试重新选择相同的账户。如果您希望连接新账户，请取消连接当前账户，然后再次尝试连接。',

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .verify_social_media]: `
    您每天最多可以验证社交媒体账户十次。 请稍后再试。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description
    .already_verified]: `
    此账户已经被其他创作者验证并使用。 请尝试使用其他账户。`,

  [CreatorRegistrationKeys.Modal.Verification_Fail.Description.minimum_subs]:
    '此账户需要至少有500个粉丝或订阅者才能进行验证。',

  // Instagram Notice
  [CreatorRegistrationKeys.Modal.Instagram_Notice.update_connection]:
    '更新连接',
  [CreatorRegistrationKeys.Modal.Instagram_Notice.upgrade_connection]:
    '升级连接',

  [CreatorRegistrationKeys.Modal.Instagram_Facebook.title]:
    '通过 Facebook 页面更新连接',

  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point1]: `
    添加一个Facebook页面来更新您的Instagram连接。 <br>
    此更新<b>需要接受平台上与Instagram相关的任何订单</b>。`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point2]: `
    此更新需要一个<b>Instagram创作者或商业帐户</b>。`,
  [CreatorRegistrationKeys.Modal.Instagram_Facebook.point3]: `
    <b><u>单击此处以获取更多信息。</u></b>`,
  [CreatorRegistrationKeys.Modal.loading_timeout.title]:
    '请刷新并重试 - 安全挑战',
  [CreatorRegistrationKeys.Modal.loading_timeout.description]:
    '请关闭任何其他 MegaEvolution 网站的标签页并重试。由于 Google、YouTube 和 Instagram 的安全政策，如果您在其他标签页中使用 MegaEvolution 网站，帐户注册将无法成功。',
};
